import { List, Popover, Spin, notification } from "antd";
import {
  selectCurrentItem,
  useGetParentProjectInfoQuery,
  setProjectIdValue,
  setItem,
  setSelectedProjectTypeValue,
  selectOpenParentProjectInfoDrawerValue,
  selectOpenChildProjectInfoDrawerValue,
  setOpenParentProjectInfoDrawerValue,
  setOpenChildProjectInfoDrawerValue,
  selectIsGridViewOpenValue,
  useGetAllParentProjectsQuery,
  selectShowingTotalValue,
  setShowProjectsTypeValue,
  setShowParcelsTypeValue,
  setCoordsPaginationValue,
  setParcelIdValue,
  setSelectedItemDetailsOnMap,
  setParentProjectListDataValue,
  setShowLoaderOnMap,
  setMapCenterPointValue,
  setZoomValue,
  selectShowParcelsTypeValue
} from "app/services";
import { Color, MatchedItemPopover } from "components";
import { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import noImageAvailable from 'styles/images/noImageAvailable.png';
import { useLocation } from "react-router-dom";
// import { Waypoint } from "react-waypoint";
import { ProjectInterface } from "types/projects";
import { createMetaData } from "utils/metaDataHelpers";
import { ViewParentProjectsPopup } from "components/ViewProjectsPopup/ViewParentProjectsPopup";
import { ViewChildProjectsPopup } from "components/ViewProjectsPopup/ViewChildProjectsPopup";
import { ParentProjectCard } from "components/MetaData/ParentProjectCard";
import { useResetFilters } from "utils/resetFilters";

export interface ParentProjectResultBoxProps {
  onOpenSelectedFilters: () => void;
  handleOnViewAllClick: (item: { id: string; type: string }) => void;
  closeInfoWindowOpen: () => void;
  isSelectedResultDrawerOpen?: boolean;
  paginatedQuerySearch: string;
  parentProjectsPagination: any;
  setParentActivePage: (pageNumber: number) => void;
  setIsLoadingData: (selectedType: string) => void;
}

const ResultsList = ({
  dataSource,
  handleOnViewAllClick,
  closeInfoWindowOpen,
  onWaypointEnter,
  hasMore,
  isSelectedResultDrawerOpen
}: {
  dataSource:
  | { type: string; item: ProjectInterface }[]
  | undefined;
  handleOnViewAllClick: (item: any) => void;
  closeInfoWindowOpen: () => void;
  onWaypointEnter: () => void;
  hasMore: boolean;
  activeTab: any;
  isSelectedResultDrawerOpen?: boolean
}) => {
  const selectedItem = useSelector(selectCurrentItem);
  const dispatch = useDispatch();
  const { resetFiltersForViewOnMap } = useResetFilters()
  const [isProjectParcelLoading, setIsProjectParcelLoading] = useState(false)

  const skipSingleProjectQuery = useMemo(() => {
    return !(selectedItem && selectedItem.type === 'Project');
  }, [selectedItem]);

  const {
    data: selectedProject,
    isFetching: isFetchingProject,
    // isSuccess: isSuccessProject,
  } = useGetParentProjectInfoQuery(
    {
      id: (selectedItem && selectedItem.id) || '',
      isChild: selectedItem?.parent_project_id ? true : false,
      surveyTaker: localStorage.getItem('surveyEmail'),
    },
    {
      refetchOnReconnect: true,
      skip: skipSingleProjectQuery,
    },
  );
  const data: any = useMemo(() => {
    if (selectedItem?.type === 'Project')
      return selectedProject
        ? {
          information: {
            title: selectedItem?.type || '',
            titleColor: Color.green,
            image: noImageAvailable,
            alldata: selectedProject.allData || selectedProject,
            id: selectedItem?.id || '',
            existsInBookmarks: selectedProject?.existsInBookmarks || '',
            parcels: selectedProject?.parcels || '',
            data: createMetaData({
              item: {
                ...selectedProject,
              } as unknown as ProjectInterface,
              type: 'Project',
            }),
          },
          // matches: Object.entries(selectedProject?.matches).map(
          //   ([property, { count }]) => ({
          //     property,
          //     total: count,
          //   }),
          // ),
        }
        : {};
  }, [selectedItem, selectedProject]);

  const showParcelsType = useSelector(selectShowParcelsTypeValue)

  const onListItemClick = (thisItem: any) => {
    if (document.getElementsByClassName('drawer-menu-1') && document.getElementsByClassName('drawer-menu-1').length) {
      let element: HTMLElement = document.getElementsByClassName('drawer-menu-1')[0] as HTMLElement;
      element.click();
    }
    localStorage.removeItem('showingChildProjectOnMap')
    window.dispatchEvent(new Event("showingChildProjectOnMap"));
    localStorage.removeItem('parentProjectsData')
    window.dispatchEvent(new Event("viewChildProject"));
    dispatch(setProjectIdValue(''));
    dispatch(
      setItem({
        id: thisItem.item.id,
        type: thisItem.type,
        parcelId: thisItem.item.parcelId,
        formattedParcelId: thisItem.item.formattedParcelId,
        id_serial: thisItem.item.id_serial,
        parent_project_id: thisItem.item.parent_project_id,
        project_name_processed: thisItem.item.project_name_processed,
      }),
    );
    if (thisItem?.item?.geomCenter?.coordinates?.length) {
      let lat = thisItem?.item?.geomCenter?.coordinates[1]
      let lng = thisItem?.item?.geomCenter?.coordinates[0]
      dispatch(setMapCenterPointValue({ lat, lng }))
      dispatch(setZoomValue(18))
    }
    if (thisItem?.item?.parent_project_id && thisItem?.type === 'Project') {
      dispatch(setSelectedProjectTypeValue('child'))
    } else if (thisItem?.type === 'Project') {
      dispatch(setSelectedProjectTypeValue('parent'))
    } else {
      dispatch(setSelectedProjectTypeValue(''))
    }
    dispatch(setShowProjectsTypeValue(null))

    dispatch(setShowParcelsTypeValue(null))
    dispatch(setCoordsPaginationValue({ page: 1, limit: 400 }))
    dispatch(setParcelIdValue(''))
    closeInfoWindowOpen();

    dispatch(setSelectedItemDetailsOnMap(null))

  }

  const onOpenChange = (e: any) => {
    if (e === false && !isSelectedResultDrawerOpen && !showParcelsType) {
      dispatch(
        setItem(null)
      );
    }
  }

  useEffect(() => {
    window.addEventListener('isProjectParcelLoadingTrue', () => {
      setIsProjectParcelLoading(true)
    })
    window.addEventListener('isProjectParcelLoadingFalse', () => {
      setIsProjectParcelLoading(false)
    });
  }, [])

  const openParentProjectInfoDrawer = useSelector(selectOpenParentProjectInfoDrawerValue)
  const openChildProjectInfoDrawer = useSelector(selectOpenChildProjectInfoDrawerValue)
  const [selectedParentChildItem,
    setSelectedParentChildItem] = useState<any>(null)
  const onParenChildBadgeClick = (item: any, type: any) => {
    setSelectedParentChildItem(item)
    if (type === 'parent') {
      dispatch(setOpenParentProjectInfoDrawerValue(true))
    }
    if (type === 'child') {
      dispatch(setOpenChildProjectInfoDrawerValue(true))
    }
  }

  return (
    <>
      {openParentProjectInfoDrawer &&
        <ViewParentProjectsPopup
          data={selectedParentChildItem}
          isOpen={openParentProjectInfoDrawer}
          closeDrawer={(e: any) => {
            if (e) {
              e.stopPropagation()
            }
            resetFiltersForViewOnMap()
            dispatch(setOpenParentProjectInfoDrawerValue(false))
          }
          } />}
      {openChildProjectInfoDrawer &&
        <ViewChildProjectsPopup
          data={selectedParentChildItem}
          isOpen={openChildProjectInfoDrawer}
          closeDrawer={(e: any) => {
            if (e) {
              e.stopPropagation()
            }
            resetFiltersForViewOnMap()
            dispatch(setOpenChildProjectInfoDrawerValue(false))
          }
          } />}
      <List
        size="large"
        dataSource={dataSource}
        renderItem={(thisItem, i) => (
          <Popover
            key={thisItem.item.id}
            overlayClassName={isSelectedResultDrawerOpen ? "selected-item-popover zindex-background" : "selected-item-popover"}
            destroyTooltipOnHide
            onOpenChange={(e) => { onOpenChange(e); if (!showParcelsType) onListItemClick(thisItem) }}
            content={
              <Spin size='large'
                spinning={isFetchingProject
                  || isProjectParcelLoading}
              // indicator={
              //   <Loading3QuartersOutlined style={{ fontSize: 50 }} spin />
              // }
              >
                <MatchedItemPopover
                  onViewAllClick={() =>
                    handleOnViewAllClick({
                      id: thisItem.item.id,
                      type: thisItem.type,
                      parcelId: thisItem.item.parcelId,
                      formattedParcelId: thisItem.item.formattedParcelId,
                      parent_project_id: thisItem.item?.parent_project_id,
                      id_serial: thisItem.item.id_serial,
                      ownershipName: thisItem.item.ownershipName,
                      project_name_processed: thisItem.item.project_name_processed,
                    })
                  }
                  information={data && data.information}
                  allData={data?.information?.alldata || {}}
                />
              </Spin>
            }
            trigger="click"
            placement="right"
          >
            <List.Item key={thisItem.item.id} className="font-small">
              <div
                onClick={() => {
                  onListItemClick(thisItem)
                }}
                style={{ width: '100%' }}
              >
                <div style={{ display: 'flex' }}>
                  <ParentProjectCard
                    onParenChildBadgeClick={onParenChildBadgeClick}
                    title={thisItem?.type}
                    id_serial={thisItem?.item?.id_serial || ''}
                    item={thisItem}
                    titleColor={
                      thisItem?.type === 'Parcel' ? Color.navy : Color.green
                    }
                    data={createMetaData(thisItem)}
                    allData={data && data.information?.alldata}
                    showChildProjectsLink={true}
                  />
                  {selectedItem && selectedItem?.id === thisItem?.item?.id ?
                    <img className="current-img" title='Selected' src='green-circle-icon.svg' alt='Selected' /> : null}
                </div>
                {/* {dataSource && hasMore && i === dataSource?.length - 1 && (
                  <Waypoint
                    onEnter={(args) => {
                      onWaypointEnter();
                    }}
                  />
                )} */}
              </div>
            </List.Item>
          </Popover>
        )}
      />
    </>
  );
};

export const ParentProjectResultBox = ({
  onOpenSelectedFilters,
  handleOnViewAllClick,
  closeInfoWindowOpen,
  isSelectedResultDrawerOpen,
  parentProjectsPagination,
  paginatedQuerySearch,
  setParentActivePage,
  setIsLoadingData
}: ParentProjectResultBoxProps) => {
  const showingTotal: number | undefined = useSelector(selectShowingTotalValue);
  const { search } = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const isGridViewOpen: any = useSelector(selectIsGridViewOpenValue)

  const [showingItems, setShowingItems] = useState<
    { type: string; item: ProjectInterface }[]
  >([]);

  const {
    data: projectsData,
    isFetching: isFetchingProjects,
    isSuccess: isSuccessProjects,
  } = useGetAllParentProjectsQuery(paginatedQuerySearch, {
    refetchOnReconnect: true,
    skip: isGridViewOpen
  });

  const dispatch = useDispatch()

  useEffect(() => {
    setParentActivePage(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  const listData = useMemo(() => {
    return projectsData?.map((item: any) => ({ item, type: 'Project' }));
  }, [projectsData]);

  useEffect(() => {
    if (projectsData) {
      dispatch(setParentProjectListDataValue(projectsData))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsData])

  useEffect(() => {
    dispatch(setShowLoaderOnMap(isFetchingProjects))
    if (isGridViewOpen) return;
    setIsLoadingData(isFetchingProjects ? 'parentProjects' : '');

    const parentProjectMountCount = parseInt(localStorage.getItem("parentProjectMountCount") || '0') + 1;
    localStorage.setItem("parentProjectMountCount", parentProjectMountCount.toString());

    // Check for memory leak and avoid multiple executions
    if (parentProjectMountCount > 1 && !isFetchingProjects) {
      localStorage.removeItem("parentProjectMountCount")
      return;
    }

    if (parentProjectMountCount === 1) {
      let notificationMessage = '';
      let notificationType = 'warn';

      if (!isFetchingProjects && !listData?.length) {
        notificationMessage = search ? 'No project matches available for the applied filters' : 'No projects available';
      } else if (!isFetchingProjects && projectsData) {
        notificationType = 'success';
        notificationMessage = 'Parent Projects retrieved successfully!';
      }

      if (notificationMessage) {
        const notificationFunc = notificationType === 'success' ? notification.success : notification.warning;

        notificationFunc({
          placement: 'topRight',
          message: notificationMessage,
        });

        if (search) {
          onOpenSelectedFilters();
        }
      }

      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingProjects])

  useEffect(() => {
    !isFetchingProjects && isSuccessProjects &&
      setShowingItems((pv) =>
        currentPage === 1
          ? [...new Set([...[], ...(listData || [])])]
          : [...new Set([...pv, ...(listData || [])])],
      );
  }, [
    isFetchingProjects,
    isSuccessProjects,
    listData,
    currentPage,
  ]);

  return (
    <div>
      <ResultsList
        isSelectedResultDrawerOpen={isSelectedResultDrawerOpen}
        dataSource={showingItems}
        handleOnViewAllClick={handleOnViewAllClick}
        closeInfoWindowOpen={closeInfoWindowOpen}
        onWaypointEnter={() => (!isFetchingProjects &&
          showingItems?.length <= 990) || showingTotal ?
          setCurrentPage((page) => page + 1) : null}
        hasMore={currentPage < parentProjectsPagination?.totalPages}
        activeTab={undefined}
      // hasMore={true}
      />
      {isFetchingProjects && currentPage !== 1 && (
        <Spin
          spinning={true}
          // indicator={
          //   <Loading3QuartersOutlined style={{ fontSize: 20 }} spin />
          // }
          tip="Loading..."
          className="cust-spin"
        />
      )}
    </div>
  )
}