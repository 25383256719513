import { createApi } from '@reduxjs/toolkit/query/react';
import { PARCELSEXTRAINFOROUTES } from 'app/apiConstants';
import { baseQuery } from 'app/baseQuery';

/**
 * Interface representing user information response.
 */
export const parcelsExtraInfoApi = createApi({
  baseQuery,
  tagTypes: ['ParcelExtraInfoRefetch'],
  reducerPath: 'parcelsExtraInfoApi',
  endpoints: (builder) => ({
    getParcelExtraInfoById: builder.query<any, any>({
      query: (parcelId) => ({
        url: `${PARCELSEXTRAINFOROUTES.base}/${parcelId}`,
      }),
      providesTags: ['ParcelExtraInfoRefetch']
    }),
    updateParcelExtraInfo: builder.mutation<any, any>({
      query: (info) => ({
        url: `${PARCELSEXTRAINFOROUTES.base}/${info.parcelId}`,
        method: 'PUT',
        body: info,
      }),
      invalidatesTags: ['ParcelExtraInfoRefetch']
    }),
  }),
});

export const { useGetParcelExtraInfoByIdQuery, useUpdateParcelExtraInfoMutation } =
  parcelsExtraInfoApi;
