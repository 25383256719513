import { Spin, Button, Empty, Badge } from 'antd';
import { setProjectIdValue, useGetAllSingleParentProjectMatchedDataQuery } from 'app/services'
import { ParentProjectCard } from 'components/MetaData/ParentProjectCard';
import TooltipComponent from 'components/Tooltip';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

interface ParentProjectPopoverProps {
  information?: any;
  allData?: any;
  onViewAllClick: () => void;
}

export const ParentProjectPopover = ({
  information,
  allData,
  onViewAllClick
}: ParentProjectPopoverProps) => {
  const dispatch = useDispatch()

  // Project matches
  const [projectsMatchingData, setProjectsMatchingData] = useState<any>()
  const [projectsMatchesLoading, setProjectsMatchesLoading] = useState(true)

  const { data: projectToParcelMatches, isSuccess: isProjectToParcelSuccess,
    isFetching: isFetchingProjectToParcel,
  } =
    /**
     * Fetches parent project matched data for parcels.
     * Retrieves data based on the provided project ID (`information?.id`).
     */
    useGetAllSingleParentProjectMatchedDataQuery(
      {
        id: information?.id || '',
        matchingType: 'parcels',
        isChild: false
      },
      {
        refetchOnReconnect: true,
        skip: !information?.id
      },
    );

  const { data: projectToBuilderMatches, isSuccess: isProjectToBuilderSuccess,
    isFetching: isFetchingProjectToBuilder,
  } =
    /**
     * Fetches parent project matched data for builders.
     * Retrieves data based on the provided project ID (`information?.id`).
     */
    useGetAllSingleParentProjectMatchedDataQuery(
      {
        id: information?.id || '',
        matchingType: 'builders',
        isChild: false
      },
      {
        refetchOnReconnect: true,
        skip: !information?.id
      },
    );

  const { data: projectToInvestorMatches, isSuccess: isProjectToInvestorSuccess,
    isFetching: isFetchingProjectToInvestor,
  } =
    /**
     * Fetches parent project matched data for investors.
     * Retrieves data based on the provided project ID (`information?.id`).
     */
    useGetAllSingleParentProjectMatchedDataQuery(
      {
        id: information?.id || '',
        matchingType: 'investors',
        isChild: false
      },
      {
        refetchOnReconnect: true,
        skip: !information?.id
      },
    );

  const { data: projectToDeveloperMatches,
    isSuccess: isProjectToDeveloperSuccess,
    isFetching: isFetchingProjectToDeveloper,
  } =
    /**
     * Fetches parent project matched data for developers.
     * Retrieves data based on the provided project ID (`information?.id`).
     */
    useGetAllSingleParentProjectMatchedDataQuery(
      {
        id: information?.id || '',
        matchingType: 'developers',
        isChild: false
      },
      {
        refetchOnReconnect: true,
        skip: !information?.id
      },
    );

  const { data: projectToRealEstateAgentMatches,
    isSuccess: isProjectToRealEstateAgentSuccess,
    isFetching: isFetchingProjectToRealEstateAgent,
  } =
    /**
     * Fetches parent project matched data for real estate agents.
     * Retrieves data based on the provided project ID (`information?.id`).
     */
    useGetAllSingleParentProjectMatchedDataQuery(
      {
        id: information?.id || '',
        matchingType: 'realEstateAgents',
        isChild: false
      },
      {
        refetchOnReconnect: true,
        skip: !information?.id
      },
    );

  const { data: projectToNewBuildHomebuyerMatches,
    isSuccess: isProjectToNewBuildHomebuyerSuccess,
    isFetching: isFetchingProjectToNewBuildHomebuyer,
  } =
    /**
     * Fetches parent project matched data for new build homebuyers.
     * Retrieves data based on the provided project ID (`information?.id`).
     */
    useGetAllSingleParentProjectMatchedDataQuery(
      {
        id: information?.id || '',
        matchingType: 'newBuildHomebuyers',
        isChild: false
      },
      {
        refetchOnReconnect: true,
        skip: !information?.id
      },
    );

  const { data: projectToWaterRightsMatches,
    isSuccess: isProjectToWaterRightsSuccess,
    isFetching: isFetchingProjectToWaterRights,
  } =
    /**
     * Fetches parent project matched data for water rights.
     * Retrieves data based on the provided project ID (`information?.id`).
     */
    useGetAllSingleParentProjectMatchedDataQuery(
      {
        id: information?.id || '',
        matchingType: 'waterRights',
        isChild: false
      },
      {
        refetchOnReconnect: true,
        skip: !information?.id
      },
    );

  const { data: projectToProjectMatches,
    isSuccess: isProjectToProjectSuccess,
    isFetching: isFetchingProjectToProject,
  } =
    /**
     * Fetches parent project matched data for projects.
     * Retrieves data based on the provided project ID (`information?.id`).
     */
    useGetAllSingleParentProjectMatchedDataQuery(
      {
        id: information?.id || '',
        matchingType: 'projects',
        isChild: false
      },
      {
        refetchOnReconnect: true,
        skip: !information?.id
      },
    );
  // Update loading state based on fetching status of various project matches
  useEffect(() => {
    if (isFetchingProjectToParcel || isFetchingProjectToBuilder ||
      isFetchingProjectToInvestor || isFetchingProjectToDeveloper ||
      isFetchingProjectToRealEstateAgent || isFetchingProjectToNewBuildHomebuyer ||
      isFetchingProjectToWaterRights || isFetchingProjectToProject) {
      setProjectsMatchesLoading(true)
    } else {
      setProjectsMatchesLoading(false)
    }
    const data = { ...projectsMatchingData }
    if (!isFetchingProjectToParcel && projectToParcelMatches?.Parcels) {
      data.Parcels = projectToParcelMatches?.Parcels
    } else {
      delete data.Parcels;
    }
    if (!isFetchingProjectToBuilder && projectToBuilderMatches?.Builder) {
      data.Builder = projectToBuilderMatches?.Builder
    } else {
      delete data.Builder;
    }
    if (!isFetchingProjectToInvestor && projectToInvestorMatches?.Investors) {
      data.Investors = projectToInvestorMatches?.Investors
    } else {
      delete data.Investors;
    }
    if (!isFetchingProjectToDeveloper &&
      projectToDeveloperMatches?.Developer) {
      data.Developer = projectToDeveloperMatches?.Developer
    } else {
      delete data.Developer;
    }
    if (!isFetchingProjectToRealEstateAgent &&
      projectToRealEstateAgentMatches?.['Real Estate Agent']) {
      data['Real Estate Agent'] = projectToRealEstateAgentMatches?.['Real Estate Agent']
    } else {
      delete data['Real Estate Agent']
    }
    if (!isFetchingProjectToNewBuildHomebuyer &&
      projectToNewBuildHomebuyerMatches?.['New Build Homebuyer']) {
      data['New Build Homebuyer'] = projectToNewBuildHomebuyerMatches?.['New Build Homebuyer']
    } else {
      delete data['New Build Homebuyer']
    }
    if (!isFetchingProjectToWaterRights &&
      projectToWaterRightsMatches?.['Water Rights']) {
      data['Water Rights'] = projectToWaterRightsMatches?.['Water Rights']
    } else {
      delete data['Water Rights']
    }
    if (!isFetchingProjectToProject &&
      projectToProjectMatches?.Projects) {
      data.Projects = projectToProjectMatches?.Projects
    } else {
      delete data.Projects;
    }
    setProjectsMatchingData(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingProjectToParcel, isFetchingProjectToBuilder,
    isFetchingProjectToInvestor, isFetchingProjectToDeveloper,
    isFetchingProjectToRealEstateAgent, isFetchingProjectToNewBuildHomebuyer,
    isFetchingProjectToWaterRights, isFetchingProjectToProject, information
  ])
  // Check if all project match requests have successfully completed
  useEffect(() => {
    if (!isFetchingProjectToParcel && isProjectToParcelSuccess
      && !isFetchingProjectToBuilder && isProjectToBuilderSuccess
      && !isFetchingProjectToInvestor && isProjectToInvestorSuccess
      && !isFetchingProjectToDeveloper && isProjectToDeveloperSuccess
      && !isFetchingProjectToRealEstateAgent &&
      isProjectToRealEstateAgentSuccess
      && !isFetchingProjectToNewBuildHomebuyer &&
      isProjectToNewBuildHomebuyerSuccess
      && !isFetchingProjectToWaterRights &&
      isProjectToWaterRightsSuccess
      && !isFetchingProjectToProject &&
      isProjectToProjectSuccess
    ) {
      setProjectsMatchesLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProjectToParcelSuccess, isProjectToBuilderSuccess,
    isProjectToInvestorSuccess, isProjectToDeveloperSuccess,
    isProjectToRealEstateAgentSuccess, isProjectToNewBuildHomebuyerSuccess,
    isProjectToWaterRightsSuccess, isProjectToProjectSuccess
  ])
  /**
   * Render method for displaying a popover containing project details and matched data.
   * If `information.title` is 'Project', it renders a list of matched items with their counts,
   * along with a button to view all matches. Displays loading spinners as necessary.
   */
  return (
    <div>
      <div className="matched-item-popover-main font-small">
        {information && (
          <ParentProjectCard
            setProjectId={(projectIdVal) => {
              dispatch(setProjectIdValue(projectIdVal))
            }}
            title={information?.title}
            titleColor={information?.titleColor}
            data={information?.data}
            information={information}
            allData={allData}
            showMore={true}
            showParcelsLink={true}
            id_serial={information?.id_serial}
          />
        )}
      </div>

      {information?.title === 'Project' ?
        projectsMatchingData &&
          Object.keys(projectsMatchingData).length ? (
          <>
            <div className="matched-item-popover-list font-small">
              <ul>
                {Object.entries(projectsMatchingData).map(([key, value]) => (
                  <li key={key}>
                    <p>{key}</p>
                    {/* <p>{value}</p> */}
                    <Badge count={value} color='#1C7A92' overflowCount={100000} showZero />
                  </li>
                ))}
              </ul>
            </div>
            {projectsMatchesLoading ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: 25 }}>
              <Spin
                spinning={projectsMatchesLoading}
                tip='Loading' size="large"
              ></Spin>
            </div> : null}
            <div className="matched-item-popover-btn font-normal">
              <TooltipComponent
                placement='top'
                title='View Matches'
                body={<Button onClick={onViewAllClick} type="primary" disabled={projectsMatchesLoading}>
                  View Matches
                </Button>}
              />
            </div>
          </>
        ) : !projectsMatchesLoading ?
          <Empty
            className="matched-item-popover-no-results"
            description="No Matched Results"
          />
          : null : null}

      {(projectsMatchesLoading && (projectsMatchingData && !Object.keys(projectsMatchingData).length)) ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: 25 }}>
        <Spin
          spinning={projectsMatchesLoading}
          tip='Loading' size="large"
        ></Spin>
      </div> : null}
    </div>
  )
}