import { Spin, Row, Col, Typography, Modal, notification } from "antd";
import { setIsGridViewOpenValue, setIsSelectedResultDrawerOpenValue, setMapCenterPointValue, setOpenChildProjectInfoDrawerValue, setOpenParentProjectInfoDrawerValue, setProjectIdValue, setSelectedProjectTypeValue, setShowParcelsTypeValue, setZoomValue, useCreateProjectBookmarkMutation, useDeleteProjectBookmarkByProjectIdMutation, useGetParentProjectInfoAllDataQuery } from "app/services";
import BookmarkPopover from "components/BookmarkPopover";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
const { Title } = Typography;

export interface ParentProjectMoreInfoPopupProps {
  id_serial?: string;
  allData: any;
  /**
   * Sets the visibility of the modal popup.
   * @param flag - Boolean flag indicating whether the modal should be visible or not.
   */
  setIsModalVisible: (flag: boolean) => void;
  /**
   * Sets whether the project exists in bookmarks.
   * @param flag - Boolean flag indicating whether the project exists in bookmarks.
   */
  setProjectExistsInBookmarks: (flag: boolean) => void
}

export const ParentProjectMoreInfoPopup = ({ id_serial, allData, setIsModalVisible, setProjectExistsInBookmarks }: ParentProjectMoreInfoPopupProps) => {
  const dispatch = useDispatch()
  const [ownerNameProcessedLimit, setOwnerNameProcessedLimit] = useState(15);
  const [showAllProjectIds, setShowAllProjectIds] = useState(false)
  const [createProjectBookmark] = useCreateProjectBookmarkMutation();
  const [deleteProjectBookmark] = useDeleteProjectBookmarkByProjectIdMutation();

  const {
    // The fetched data of the selected parent project.
    data: selectedParentProject,
    isFetching: isFetchingProject
  } = useGetParentProjectInfoAllDataQuery(
    // Query variables object passed as the first argument.
    {
      id: allData?.id,
      isChild: false,
      surveyTaker: localStorage.getItem('surveyEmail')
    },
    {
      refetchOnReconnect: true,
      skip: !allData || !allData?.id
    },
  );

  useEffect(() => {
    if (!isFetchingProject && selectedParentProject) {
      setProjectExistsInBookmarks(selectedParentProject?.existsInBookmarks)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParentProject, isFetchingProject]);

  /**
   * Handles the action when OK button is clicked on the modal.
   * It sets the modal visibility to false.
   */
  const handleOk = () => {
    setIsModalVisible(false);
  };

  /**
   * Handles the action when Cancel button is clicked on the modal.
   * It sets the modal visibility to false.
   */
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  /**
   * Creates a bookmark for the project and shows a success notification.
   */
  const createBookmark = () => {
    createProjectBookmark({ project_id: allData?.id, type: 'parent', from: 'moreInfoPopup', survey_taker: localStorage.getItem('surveyEmail') }).finally(() => {
      notification.success({
        placement: 'topRight',
        message: `Project bookmarked successfully!`,
      });
    })
  }

  /**
   * Deletes the bookmark for the project and shows a success notification.
   */
  const deleteBookmark = () => {
    deleteProjectBookmark({ id: allData?.id, from: 'moreInfoPopup' }).finally(() => {
      notification.success({
        placement: 'topRight',
        message: `Bookmark deleted successfully!`,
      });
    })
  }

  return (
    <Modal
      title={<> More Information view of Project : {selectedParentProject?.project_name_processed} <>
        {selectedParentProject?.existsInBookmarks
          ?
          <BookmarkPopover
            okText='Yes'
            cancelText='No'
            title='Remove Bookmark'
            onConfirm={deleteBookmark}
            body={
              <img title='Remove Bookmark' src='bookmark_filled.svg' alt='bookmark-filled' style={{ marginLeft: 15, cursor: 'pointer', height: 15, width: 15 }} />
            }
          />
          :
          <BookmarkPopover
            okText='Yes'
            cancelText='No'
            title='Add Bookmark'
            onConfirm={createBookmark}
            body={
              <img title='Add Bookmark' src='bookmark.svg' alt='bookmark' style={{ marginLeft: 15, cursor: 'pointer', height: 15, width: 15 }} />
            }
          />
        }</>
      </>}

      open={true}
      onOk={handleOk}
      onCancel={handleCancel}
      width={800}
      bodyStyle={{ maxHeight: 700, overflowY: 'scroll' }}
      className="naxxa-meta-detail-modal"
      afterClose={() => {
        setOwnerNameProcessedLimit(15)
        // setSimilarProjectsParcelsLimit(15)
      }}
    >
      {isFetchingProject ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: 25 }}>
        <Spin
          spinning={isFetchingProject}
          tip='Loading' size="large"
        ></Spin>
      </div> : selectedParentProject ?
        <>
          <div className="meta-detail" style={{ marginBottom: 10 }}>
            <span className="meta-detail-name">Project Name: </span>
            <span className="meta-detail-value">{selectedParentProject?.project_name_processed}</span>
          </div>
          {/* <div className="meta-detail" style={{ marginBottom: 10 }}>
              <span className="meta-detail-name">Project Owner Name: </span>
              <span className="meta-detail-value">{selectedParentProject?.ownerName || selectedParentProject?.owner_name}</span>
            </div> */}

          {/* <Title level={5}>Similar Projects:</Title> */}
          {/* <div>
                <span className="meta-detail-name">Parcels: </span>
                {selectedParentProject?.similarProjectsParcels?.length ?
                  <span className="meta-detail-value">{selectedParentProject?.similarProjectsParcels?.slice(0, similarProjectsParcelsLimit).join(', ')}
                    {selectedParentProject?.similarProjectsParcels?.length > similarProjectsParcelsLimit && <span className="meta-detail-show-more" onClick={() => setSimilarProjectsParcelsLimit((e) => e + 15)}>Show more</span>}
                  </span>
                  : null
                }
              </div> */}
          <div>
            <span className="meta-detail-name">Project Owners: </span>
            {selectedParentProject?.owner_name_processed?.length ?
              <span className="meta-detail-value">{selectedParentProject?.owner_name_processed?.slice(0, ownerNameProcessedLimit).join(', ')}
                {selectedParentProject?.owner_name_processed?.length > ownerNameProcessedLimit && <span className="meta-detail-show-more" onClick={() => setOwnerNameProcessedLimit((e) => e + 15)}>Show more</span>}
              </span>
              : null
            }
            {/* {selectedParentProject?.owner_name_processed?.length ?
              <span className="meta-detail-value">{selectedParentProject?.owner_name_processed?.slice(0).join(', ').replace(/,/g, ', ')}
                {selectedParentProject?.owner_name_processed?.length > ownerNameProcessedLimit && <span className="meta-detail-show-more" onClick={() => setOwnerNameProcessedLimit((e) => e + 15)}>Show more</span>}
              </span>
              : null
            } */}
          </div>
          <Title level={5}>Project Address info:</Title>
          <Row style={{ marginBottom: '10px' }}>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name">State: </span>
                <span className="meta-detail-value">{selectedParentProject?.state}</span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name">Street: </span>
                <span className="meta-detail-value">{selectedParentProject?.address}</span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name">City: </span>
                <span className="meta-detail-value">{selectedParentProject?.city}</span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name">County: </span>
                <span className="meta-detail-value">{selectedParentProject?.county}</span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name">Zip: </span>
                <span className="meta-detail-value">{selectedParentProject?.postal_code}</span>
              </div>
            </Col>
          </Row>
          {selectedParentProject?.BuildableNumberAndStyleOfLots?.length ?
            <div className="meta-detail">
              <span className="meta-detail-name">
                Buildable number and style of lots:{' '}
              </span>
              <span className="meta-detail-value">
                {Array.isArray(selectedParentProject?.BuildableNumberAndStyleOfLots) ? selectedParentProject?.BuildableNumberAndStyleOfLots.join(', ') : selectedParentProject?.BuildableNumberAndStyleOfLots}
              </span>
            </div> :
            selectedParentProject?.desirednumberandstyleoflots?.length ?
              <div className="meta-detail">
                <span className="meta-detail-name">
                  Desired Number and Style of Lots:{' '}
                </span>
                <span className="meta-detail-value">
                  {Array.isArray(selectedParentProject?.desirednumberandstyleoflots) ? selectedParentProject?.desirednumberandstyleoflots.join(', ') : selectedParentProject?.desirednumberandstyleoflots}
                </span>
              </div> :
              <div className="meta-detail">
                <span className="meta-detail-name">
                  Buildable number and style of lots:{' '}
                </span>
              </div>}
          <div className="meta-detail">
            <span className="meta-detail-name">Special Product Type: </span>
            <span className="meta-detail-value">
              {selectedParentProject?.specialproducttypes?.length ? [...new Set(selectedParentProject?.specialproducttypes)]?.join(', ') : ''}
            </span>
          </div>
          <div className="meta-detail">
            <span className="meta-detail-name">Lot Sizes: </span>
            <span className="meta-detail-value">{selectedParentProject?.lot_sizes?.join(', ')}</span>
          </div>
          <div className="meta-detail">
            <span className="meta-detail-name">Project Status: </span>
            <span className="meta-detail-value">{selectedParentProject?.project_status?.length ? selectedParentProject?.project_status?.join(', ') : ''}</span>
          </div>
          <div className="meta-detail">
            <span className="meta-detail-name">House Style: </span>
            <span className="meta-detail-value">
              {selectedParentProject?.housestyle?.length ? selectedParentProject?.housestyle?.join(', ') : selectedParentProject?.housestyle}</span>
          </div>
          <div className="meta-detail">
            <span className="meta-detail-name">House Square Footage: </span>
            <span className="meta-detail-value">
              {selectedParentProject?.house_square_footage?.length ? selectedParentProject?.house_square_footage?.join(', ') : selectedParentProject?.house_square_footage}
            </span>
          </div>
          <div className="meta-detail">
            <span className="meta-detail-name">Bedrooms: </span>
            <span className="meta-detail-value">
              {selectedParentProject?.bedrooms?.length ? selectedParentProject?.bedrooms?.join(', ') : selectedParentProject?.bedrooms}
            </span>
          </div>
          <div className="meta-detail">
            <span className="meta-detail-name">Bathrooms: </span>
            <span className="meta-detail-value">
              {selectedParentProject?.bathrooms?.length ? selectedParentProject?.bathrooms?.join(', ') : selectedParentProject?.bathrooms}
            </span>
          </div>
          <div className="meta-detail">
            <span className="meta-detail-name">Garage Size: </span>
            <span className="meta-detail-value">
              {selectedParentProject?.garages?.length ? selectedParentProject?.garages?.join(', ') : selectedParentProject?.garages}

            </span>
          </div>
          <div className="meta-detail">
            <span className="meta-detail-name">Zoning: </span>
            <span className="meta-detail-value">
              {selectedParentProject?.zoning?.length ? selectedParentProject?.zoning.join(', ') : selectedParentProject?.zoning}
            </span>
          </div>
          <div className="meta-detail">
            <span className="meta-detail-name">Project Parcel ID's: </span>
            {
              (selectedParentProject?.buildableAndNonBuildableParcels?.length <= 15 ||
                showAllProjectIds) ?
                <span className="meta-detail-value">{selectedParentProject?.buildableAndNonBuildableParcels?.length ? selectedParentProject?.buildableAndNonBuildableParcels?.join(', ') : selectedParentProject?.buildableAndNonBuildableParcels}</span> :
                <>
                  <span className="meta-detail-value">{selectedParentProject?.buildableAndNonBuildableParcels?.length ? selectedParentProject?.buildableAndNonBuildableParcels?.slice(0, 15).join(', ') : selectedParentProject?.buildableAndNonBuildableParcels}</span>
                  {selectedParentProject?.buildableAndNonBuildableParcels?.length > 15 && <span className="meta-detail-show-more" onClick={() => setShowAllProjectIds(true)}>Show more</span>}
                </>
            }
            {
              selectedParentProject?.buildableAndNonBuildableParcels?.length ?
                <span className="meta-detail-show-more" style={{ marginLeft: 5 }} onClick={() => {
                  localStorage.removeItem('projectPolygonCenter')
                  dispatch(setIsGridViewOpenValue(false))
                  if (selectedParentProject?.lat && selectedParentProject?.lng) {
                    dispatch(setMapCenterPointValue({
                      lat: selectedParentProject.lat,
                      lng: selectedParentProject.lng,
                    }))
                  }
                  dispatch(setShowParcelsTypeValue('all'))
                  dispatch(setZoomValue(20))
                  dispatch(setProjectIdValue(allData?.id));
                  if (allData?.parent_project_id) {
                    dispatch(setSelectedProjectTypeValue('child'))
                  } else {
                    dispatch(setSelectedProjectTypeValue('parent'))
                  }
                  dispatch(setIsSelectedResultDrawerOpenValue(false))
                  handleCancel()
                  dispatch(setOpenParentProjectInfoDrawerValue(false))
                  dispatch(setOpenChildProjectInfoDrawerValue(false))
                }}>View parcels on map</span> : null
            }
          </div>
          <div className="meta-detail">
            <span className="meta-detail-name">Distressed: </span>
            <span className="meta-detail-value"></span>
          </div>
          <div className="meta-detail">
            <span className="meta-detail-name">Price Point: </span>
            <span className="meta-detail-value">{selectedParentProject?.pricePoint?.join(', ') || selectedParentProject?.price_point?.join(', ')}</span>
          </div>

          <Title level={5}>MLS Listings:</Title>
          <Row style={{ marginBottom: '10px' }}>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name">MLS Entry: </span>
                <span className="meta-detail-value">{selectedParentProject?.mls_entry}</span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name">MLS Status: </span>
                <span className="meta-detail-value">
                  {selectedParentProject?.mlsStatus}
                </span>
              </div>
            </Col>
          </Row>
          <div className="meta-detail">
            <span className="meta-detail-name">Company Matches: </span>
            <span className="meta-detail-value">
              {selectedParentProject?.companyMatches}
            </span>
          </div>
          <div className="meta-detail">
            <span className="meta-detail-name">
              Retire or Display Projects:{' '}
            </span>
            <span className="meta-detail-value">
              {selectedParentProject?.retire_or_display}
            </span>
          </div>
        </> : null
      }
    </Modal>
  )
}