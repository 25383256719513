import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import {
  authApi,
  authReducer,
  bookmarkApi,
  companyApi,
  parcelApi,
  baseStoreReducer,
  projectApi,
  citiesApi,
  countiesApi,
  surveyApi,
  contactApi,
  userApi,
  zoningApi,
  parentProjectApi,
  rolesApi,
  parcelsExtraInfoApi,
  autoCompleteStoreReducer,
  commonHelperStoreReducer
} from './services';

const isDevelopment = process.env.NODE_ENV === 'development';
/**
 * Redux store configuration for the application.
 * Configures reducers and middleware using Redux Toolkit's `configureStore`.
 * Includes authentication, base store, autocomplete, and helper reducers,
 * along with reducers from multiple RTK Query API slices.
 * @remarks
 * Middleware includes error handling and async logic provided by RTK Query's middleware.
 */ 
export const store = configureStore({
  reducer: {
    auth: authReducer,
    baseStore: baseStoreReducer,
    autoCompleteStore: autoCompleteStoreReducer,
    commonHelperStore: commonHelperStoreReducer,
    [authApi.reducerPath]: authApi.reducer,
    [parcelApi.reducerPath]: parcelApi.reducer,
    [companyApi.reducerPath]: companyApi.reducer,
    [bookmarkApi.reducerPath]: bookmarkApi.reducer,
    [projectApi.reducerPath]: projectApi.reducer,
    [citiesApi.reducerPath]: citiesApi.reducer,
    [countiesApi.reducerPath]: countiesApi.reducer,
    [surveyApi.reducerPath]: surveyApi.reducer,
    [contactApi.reducerPath]: contactApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [zoningApi.reducerPath]: zoningApi.reducer,
    [parentProjectApi.reducerPath]: parentProjectApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    [parcelsExtraInfoApi.reducerPath]: parcelsExtraInfoApi.reducer,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ serializableCheck: false }),
    authApi.middleware,
    parcelApi.middleware,
    projectApi.middleware,
    companyApi.middleware,
    bookmarkApi.middleware,
    citiesApi.middleware,
    countiesApi.middleware,
    surveyApi.middleware,
    contactApi.middleware,
    userApi.middleware,
    zoningApi.middleware,
    parentProjectApi.middleware,
    rolesApi.middleware,
    parcelsExtraInfoApi.middleware,
  ],

  devTools: isDevelopment,
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
