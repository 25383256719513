import { Spin, Row, Col, Typography, Modal, notification } from "antd";
import { useCreateParcelBookmarkMutation, useDeleteParcelBookmarkByParcelIdMutation, useGetParcelInfoAllDataQuery } from "app/services";
import BookmarkPopover from "components/BookmarkPopover";
import { useState } from "react";
import { ParcelQualificationSheet } from "./ParcelQualificationSheet";
const { Title } = Typography;

export interface ParcelMoreInfoPopupProps {
  id_serial?: string;
  setIsModalVisible: (flag: boolean) => void;
}

export const ParcelMoreInfoPopup = ({ id_serial, setIsModalVisible }: ParcelMoreInfoPopupProps) => {
  const [createParcelBookmark] = useCreateParcelBookmarkMutation();
  const [deleteParcelBookmark] = useDeleteParcelBookmarkByParcelIdMutation();
  const [isQualificationSheetVisible, setIsQualificationSheetVisible] = useState<any>(false)

  const {
    data: selectedParcel,
    isFetching: isFetchingParcel
  } = useGetParcelInfoAllDataQuery(
    {
      id: id_serial,
      surveyTaker: localStorage.getItem('surveyEmail')
    },
    {
      refetchOnReconnect: true,
      skip: !id_serial
    },
  );
  /**
   * Handles the 'OK' action in the modal by hiding it.
   */
  const handleOk = () => {
    setIsModalVisible(false);
  };
  /**
   * Handles the 'Cancel' action in the modal by hiding it.
   */
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  /**
   * Creates a bookmark for the current parcel using `createParcelBookmark` API call.
   * Displays a success notification upon successful bookmark creation.
   */
  const createBookmark = () => {
    createParcelBookmark({ parcel_id: id_serial, survey_taker: localStorage.getItem('surveyEmail') }).finally(() => {
      notification.success({
        placement: 'topRight',
        message: 'Parcel bookmarked successfully!',
      });
    })
  }
  /**
   * Deletes the bookmark for the current parcel using `deleteParcelBookmark` API call.
   * Displays a success notification upon successful bookmark deletion.
   */
  const deleteBookmark = () => {
    deleteParcelBookmark({ id: id_serial }).finally(() => {
      notification.success({
        placement: 'topRight',
        message: `Bookmark deleted successfully!`,
      });
    })
  }

  return (
    <Modal
      title={<>Parcel : {selectedParcel?.ownershipName}
        <>
          {selectedParcel?.existsInBookmarks
            ?
            <BookmarkPopover
              okText='Yes'
              cancelText='No'
              title='Remove Bookmark'
              onConfirm={deleteBookmark}
              body={
                <img title='Remove Bookmark' src='bookmark_filled.svg' alt='bookmark-filled' style={{ marginLeft: 15, cursor: 'pointer', height: 15, width: 15 }} />
              }
            />
            :
            <BookmarkPopover
              okText='Yes'
              cancelText='No'
              title='Add Bookmark'
              onConfirm={createBookmark}
              body={
                <img title='Add Bookmark' src='bookmark.svg' alt='bookmark' style={{ marginLeft: 15, cursor: 'pointer', height: 15, width: 15 }} />
              }
            />
          }</>
      </>}

      open={true}
      onOk={handleOk}
      onCancel={handleCancel}
      width={800}
      bodyStyle={{ maxHeight: 700, overflowY: 'scroll' }}
      className="naxxa-meta-detail-modal"
    >
      {isFetchingParcel ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: 25 }}>
        <Spin
          spinning={isFetchingParcel}
          tip='Loading' size="large"
        ></Spin>
      </div> :
        <>
          <div className="meta-detail">
            <span className="meta-detail-name">Ownership Name: </span>
            <span className="meta-detail-value">
              {selectedParcel?.ownershipName}
            </span>
          </div>
          <Title level={5}>Parcel IDs</Title>
          <Row>
            <Col span={24}>
              <div className="meta-detail">
                <span className="meta-detail-name">
                  {selectedParcel?.formattedParcelId}
                </span>
              </div>
            </Col>
            <Col span={24}>
              <div className="meta-detail">
                <span className="meta-detail-name">
                  {selectedParcel?.parcelId}
                </span>
              </div>
            </Col>
          </Row>

          {/* <div className="meta-detail">
          <span className="meta-detail-name">Parcel IDs: </span>
          <span className="meta-detail-value">
            {selectedParcel?.formattedParcelId}, {selectedParcel?.parcelId}
          </span>
        </div> */}
          <Title level={5}>Mortgage Info:</Title>
          <Row>
            {/* <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name">
                  Length of ownership:{' '}
                </span>
                <span className="meta-detail-value">
                  {selectedParcel?.mortgageLenghtOfOwnership}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name">Mortgage or Not: </span>
                <span className="meta-detail-value">
                  {selectedParcel?.mortgageOrNotStatus?.toString()}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name">Lien: </span>
                <span className="meta-detail-value">
                  {selectedParcel?.mortgageLien?.toString()}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name">NOD: </span>
                <span className="meta-detail-value">
                  {selectedParcel?.mortgageNod?.toString()}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name">Estimated Payment: </span>
                <span className="meta-detail-value">
                  {selectedParcel?.mortgageEstimatedPayment}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name">
                  Estimated Interest Rate:
                </span>
                <span className="meta-detail-value">
                  {selectedParcel?.mortgageEstimatedInterestRate}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name">Estimated Equity: </span>
                <span className="meta-detail-value">
                  {selectedParcel?.mortgageEstimatedEquity}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name">
                  Estimate Loan Balance:{' '}
                </span>
                <span className="meta-detail-value">
                  {selectedParcel?.mortgageEstimatedLoanBalance}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name">
                  How long delinquent:{' '}
                </span>
                <span className="meta-detail-value">
                  {selectedParcel?.mortgageHowLongDelinquent}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name">
                  Delinquent on other property:
                </span>
                <span className="meta-detail-value">
                  {selectedParcel?.
                    mortgageDelinquentOnOtherProperty?.toString()}
                </span>
              </div>
            </Col> */}
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name">Lender: </span>
                <span className="meta-detail-value">
                  {selectedParcel?.mortgageWhoIsTheLender}
                </span>
              </div>
            </Col>
          </Row>
          <Title level={5}>Ownership info:</Title>
          <Row>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name">
                  Absentee or Out of State Owner:{' '}
                </span>
                <span className="meta-detail-value">
                  {selectedParcel?.ownershipInfoAbsentee}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name"> Mailing address: </span>
                <span className="meta-detail-value">
                  {selectedParcel?.ownershipInfoMailingAddress}
                  {selectedParcel?.ownershipInfoMailingCity &&
                    ', ' + selectedParcel?.ownershipInfoMailingCity}
                  {selectedParcel?.ownershipInfoMailingZip &&
                    ', ' + selectedParcel?.ownershipInfoMailingZip}
                  {selectedParcel?.ownershipInfoMailingState &&
                    ', ' + selectedParcel?.ownershipInfoMailingState}
                </span>
              </div>
            </Col>
            {/* <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name">Property status: </span>
                <span className="meta-detail-value">
                  {selectedParcel?.propertyStatus && selectedParcel.propertyStatus?.join(', ')}
                </span>
              </div>
            </Col> */}
          </Row>
          <Title level={5}>Property Address info:</Title>
          <Row>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name">State: </span>
                <span className="meta-detail-value">
                  {selectedParcel?.state}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name"> Street: </span>
                <span className="meta-detail-value">
                  {selectedParcel?.address}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name"> City: </span>
                <span className="meta-detail-value">
                  {selectedParcel?.city_by_geometry || selectedParcel?.city}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name"> County: </span>
                <span className="meta-detail-value">
                  {selectedParcel?.county_by_geometry ||
                    selectedParcel?.county}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name"> Zip: </span>
                <span className="meta-detail-value">
                  {selectedParcel?.postalCode}
                </span>
              </div>
            </Col>
          </Row>
          <Title level={5}>Hypotheticals:</Title>
          <Row>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name">Zoning: </span>
                <span className="meta-detail-value">
                  {selectedParcel?.possibleNumberAndStyleOfLotsZoning &&
                    selectedParcel?.possibleNumberAndStyleOfLotsZoning?.join(', ')}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name"> Neighbor: </span>
                <span className="meta-detail-value">
                  {selectedParcel?.possibleNumberAndStyleOfLotsNeighbors &&
                    selectedParcel?.
                      possibleNumberAndStyleOfLotsNeighbors?.join(
                        ', ',
                      )}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name"> Proxy: </span>
                <span className="meta-detail-value">
                  {selectedParcel?.possibleNumberAndStyleOfLotsProxy &&
                    selectedParcel?.possibleNumberAndStyleOfLotsProxy?.join(', ')}
                </span>
              </div>
            </Col>
          </Row>
          <div className="meta-detail" style={{ marginTop: '10px' }}>
            <span className="meta-detail-name">Acreage: </span>
            <span className="meta-detail-value">{selectedParcel?.acreage?.toFixed(2)}</span>
          </div>
          <Title level={5}>MLS Listing info:</Title>
          <Row>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name">Status: </span>
                <span className="meta-detail-value">
                  {selectedParcel?.mlsInfoStatus}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name"> Agent: </span>
                <span className="meta-detail-value">
                  {selectedParcel?.mlsInfoAgent}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name">
                  {' '}
                  Agent Contact info:{' '}
                </span>
                <span className="meta-detail-value">
                  {selectedParcel?.mlsInfoAgentContactInfo}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name"> Brokerage Name: </span>
                <span className="meta-detail-value">
                  {selectedParcel?.mlsInfoBrokerage}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name">
                  Brokerage Contact Info:{' '}
                </span>
                <span className="meta-detail-value">
                  {selectedParcel?.mlsInfoBrokerageContactInfo}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name"> Listing Number: </span>
                <span className="meta-detail-value">
                  {selectedParcel?.mlsInfoListingNumber}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name"> DOM: </span>
                <span className="meta-detail-value">
                  {selectedParcel?.mlsInfoDom}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name"> Listing Price: </span>
                <span className="meta-detail-value">
                  {selectedParcel?.mlsInfoListingPrice}
                </span>
              </div>
            </Col>
          </Row>
          {/* <div className="meta-detail" style={{ marginTop: '10px' }}>
          <span className="meta-detail-name">SCR Entry Numbers: </span>
          <span className="meta-detail-value">
            {selectedParcel?.scrEntryNumbers}
          </span>
        </div> */}
          <Title level={5}>MLS /Sold info:</Title>
          <Row>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name">
                  Date of last sold date:
                </span>
                <span className="meta-detail-value">
                  {selectedParcel?.mlsInfoLastSoldDate}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name"> Sold Price: </span>
                <span className="meta-detail-value">
                  {selectedParcel?.mlsInfoLastSoldPrice}
                </span>
              </div>
            </Col>
          </Row>
          <Title level={5}>Zoning :</Title>
          <Row>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name">Actual Zoning: </span>
                <span className="meta-detail-value">
                  {selectedParcel?.actualZoning}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name"> Zoning Code Link: </span>
                <span className="meta-detail-value">
                  {selectedParcel?.zoningCodeLink ?
                    <a rel="noreferrer" href={selectedParcel?.zoningCodeLink} target="_blank">
                      {selectedParcel?.zoningCodeLink}</a> : null}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="meta-detail">
                <span className="meta-detail-name"> Overlay Link: </span>
                <span className="meta-detail-value">
                  {selectedParcel?.zoningOverlayLink ?
                    <a rel="noreferrer" href={selectedParcel?.zoningOverlayLink} target="_blank">
                      {selectedParcel?.zoningOverlayLink}</a> : null}
                </span>
              </div>
            </Col>
          </Row>
          <div className="meta-detail" style={{ marginTop: '10px' }}>
            <span className="meta-detail-name">Area Tags: </span>
            <span className="meta-detail-value">{Array.isArray(selectedParcel?.areaTags) && selectedParcel?.areaTags?.join(', ')}</span>
          </div>
          <div className="meta-detail">
            <span className="meta-detail-name">Tax ID: </span>
            <span className="meta-detail-value">{selectedParcel?.taxId}</span>
          </div>
          {/* <div className="meta-detail">
            <span className="meta-detail-name">Road Traffic Count: </span>
            <span className="meta-detail-value">
              {selectedParcel?.roadTrafficCount}
            </span>
          </div>
          <div className="meta-detail">
            <span className="meta-detail-name">Disposition Score: </span>
            <span className="meta-detail-value">
              {selectedParcel?.dispositionScore}
            </span>
          </div>
          <div className="meta-detail">
            <span className="meta-detail-name">
              Ownership Workability Score:{' '}
            </span>
            <span className="meta-detail-value">
              {selectedParcel?.ownershipWorkabilityScore}
            </span>
          </div>
          <div className="meta-detail">
            <span className="meta-detail-name">
              Ownership Reasonablity Score:{' '}
            </span>
            <span className="meta-detail-value">
              {selectedParcel?.ownershipReasonabilityScore}
            </span>
          </div>
          <div className="meta-detail">
            <span className="meta-detail-name">Road Score: </span>
            <span className="meta-detail-value">{selectedParcel?.roadScore}</span>
          </div>
          <div className="meta-detail">
            <span className="meta-detail-name">
              Previous Failed Project:{' '}
            </span>
            <span className="meta-detail-value">
              {selectedParcel?.previousDeniedProject}
            </span>
          </div>
          <div className="meta-detail">
            <span className="meta-detail-name">Company Matches: </span>
            <span className="meta-detail-value">
              {selectedParcel?.companyMatches}
            </span>
          </div> */}
          <div className="meta-detail">
            <span className="meta-detail-name">
              Unincorporated Parcel or Not:{' '}
            </span>
            <span className="meta-detail-value">
              {selectedParcel?.unincorporatedParcelStatus}
            </span>
          </div>
          <div className="meta-detail">
            <span className="meta-detail-name">
              Building on the Property:{' '}
            </span>
            <span className="meta-detail-value">
              {selectedParcel?.propertyInfoBuilding?.toString()}
            </span>
          </div>
          {/* <div className="meta-detail">
            <span className="meta-detail-name">Year Built: </span>
            <span className="meta-detail-value">
              {selectedParcel?.propertyInfoYearBuilt}
            </span>
          </div> */}
          <div className="meta-detail">
            <span className="meta-detail-name">
              Part of a Current Project:{' '}
            </span>
            <span className="meta-detail-value">{selectedParcel?.isPartOfACurrentProject && selectedParcel?.project_names?.join(', ') || 'false'}</span>
          </div>
          <div className="meta-detail" style={{ marginTop: '10px' }}>
            <span className="navy" style={{ cursor: 'pointer' }} onClick={() => setIsQualificationSheetVisible(true)}>
              Qualification Sheet
            </span>
          </div>
        </>}
        {isQualificationSheetVisible && <ParcelQualificationSheet parcel_id={selectedParcel?.parcelId} setIsModalVisible={setIsQualificationSheetVisible}/>}
    </Modal>
  )
}