import { createApi } from '@reduxjs/toolkit/query/react';
import {
  PROJECTROUTES,
  PARENTPROJECTROUTES,
  BOOKMARKROUTES,
} from 'app/apiConstants';
import { baseQuery } from 'app/baseQuery';
import { CreateProjectBookmarkArgs, ProjectsBookmarkInterface } from 'types/bookmark';
import { ContactsDataResponse } from 'types/contacts';
import { Pagination } from 'types/parcels';
import {
  ProjectsDataResponse,
  ProjectSingleMatchedData,
  ProjectsGeoDataResponse,
} from 'types/projects';
import { parentProjectApi } from './parentprojects.api';

export const projectApi = createApi({
  baseQuery,
  reducerPath: 'projectApi',
  tagTypes: ['ProjectsBookmark', 'ParentProjectsData', 'GetAllProjects'],
  endpoints: (builder) => ({
    /**
     * Retrieves geographic data for a specific project.
     * 
     * Fetches geographic data related to a project from the API endpoint specified by PROJECTROUTES.geoData,
     * using the provided search parameter.
     * 
     * @param {string} search - Search parameter to append to the URL query.
     */    
    getProjectGeoData: builder.query<any, string>({
      query: (search) => ({
        url: `${PROJECTROUTES.geoData}${search}`,
      }),
      keepUnusedDataFor: 1,
    }),
    /**
     * Retrieves geographic data for child projects of a parent project.
     * 
     * Fetches geographic data related to child projects of a specific parent project from the API endpoint
     * specified by PROJECTROUTES.childGeoData, using parentId, page, and limit parameters
     * @param {number} [params.page=1] - Page number for pagination (default is 1).
     * @param {number} [params.limit=20] - Number of items to fetch per page (default is 20).
     */    
    getChildProjectGeoData: builder.query<ProjectsGeoDataResponse, any>({
      query: ({ parentId, page = 1, limit = 20 }) => ({
        url: `${PROJECTROUTES.childGeoData}?parentId=${parentId}&page=${page}&limit=${limit}`,
      }),
      keepUnusedDataFor: 1,
    }),
    /**
     * Retrieves the center point of a specific project.
     * 
     * Fetches the center point coordinates related to a project from the API endpoint specified by PROJECTROUTES.centerPoint,
     * using the provided search parameter.
     * 
     * @param {string} search - Search parameter to append to the URL query.
     */    
    getProjectCenterPoint: builder.query<ProjectsGeoDataResponse, string>({
      query: (search) => ({
        url: `${PROJECTROUTES.centerPoint}${search}`,
      }),
    }),
    /**
     * Retrieves all projects based on a query string.
     * 
     * Fetches all projects from the API endpoint specified by PROJECTROUTES.base,
     * using the provided search parameters.
     * 
     * @param {string} search - Search parameters to append to the URL query.
     */    
    getAllProjects: builder.query<ProjectsDataResponse, string>({
      query: (search) => ({
        url: `${PROJECTROUTES.base}${search}`,
      }),
      transformResponse: (response: any, meta, args) => {
        let transformData: any = {};
        transformData.data = response.data.map((data: any) => {
          data.dataArgs = args;
          return data;
        });
        return transformData;
      },
      providesTags: ['GetAllProjects']
    }),
    /**
     * Retrieves the count of projects based on a query string.
     * 
     * Fetches the count of projects from the API endpoint specified by PROJECTROUTES.count,
     * using the provided search parameters.
     * 
     * @param {string} search - Search parameters to append to the URL query.
     */    
    getProjectsCount: builder.query<Pagination, string>({
      query: (search) => ({
        url: `${PROJECTROUTES.count}${search}`,
      }),
    }),
    /**
     * Retrieves detailed information about a specific project.
     * 
     * Fetches detailed information related to a project from the API endpoint specified by PROJECTROUTES.info,
     * using the provided project ID.
     * 
     * @param {object} params - Parameters object containing id.
     * @param {string} params.id - The ID of the project.
     */    
    getProjectInfo: builder.query<ProjectSingleMatchedData, { id: string, surveyTaker: any }>({
      query: ({ id, surveyTaker }) => ({
        url: `${PROJECTROUTES.info}/${id}?surveyTaker=${surveyTaker}`,
      }),
      providesTags: ['ProjectsBookmark'],
    }),
    getSingleProjectMatchedData: builder.query<
      ProjectSingleMatchedData,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${PROJECTROUTES.matchesCount}/${id}`,
      }),
    }),
    getAllSingleProjectMatchedData: builder.query<
      any,
      { id: string; matchingType?: any; parentName?: any }
    >({
      query: ({ id, matchingType = null, parentName = '' }) => ({
        url: `${PROJECTROUTES.allMatchesCount}/${id}?matchingType=${matchingType}&parentName=${parentName}`,
      }),
    }),
    /**
     * Retrieves all data related to a specific project.
     * 
     * Fetches all data related to a project from the API endpoint specified by PROJECTROUTES.allData,
     * using the provided project ID.
     * 
     * @param {object} params - Parameters object containing id.
     * @param {string} params.id - The ID of the project.
     */    
    getProjectInfoAllData: builder.query<any, { id: any }>({
      query: ({ id }) => ({
        url: `${PROJECTROUTES.allData}/${id}`,
      }),
    }),
    /**
     * Deletes a bookmark associated with a child project by its ID.
     * 
     * Deletes the bookmark for a child project specified by its ID from the API endpoint
     * specified by BOOKMARKROUTES.projects, using the DELETE HTTP method.
     * 
     * @param {object} params - Parameters object containing id.
     * @param {string} params.id - The ID of the project to delete the bookmark for.
     */    
    deleteChildProjectBookmarkByProjectId: builder.mutation<unknown, any>({
      query: ({ id }) => ({
        url: `${BOOKMARKROUTES.projects}/project/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ id, from }, { queryFulfilled, dispatch, getState }) {
        try {
          await queryFulfilled;
          if(from === 'moreInfoPopup') {
            for (const { endpointName, originalArgs } of projectApi.util.selectInvalidatedBy(getState(), [{ type: "GetAllProjects" }])) { 
              // we only want to update `getPosts` here
              if ( endpointName !== 'getAllProjects') continue;
              dispatch(
                projectApi.util.updateQueryData(endpointName, originalArgs, (draft: any) => {
                   // find in list & update
                   const dataIndex = draft?.data?.findIndex((x: any) => x.id === id)
                    if(dataIndex >= 0){
                      draft.data[dataIndex] = { ...draft.data[dataIndex], existsInBookmarks: false }
                    }
                })
              )
            }
            dispatch(parentProjectApi.util.invalidateTags(["ProjectsBookmark", "AllParentProjectsMatching"]));
          }
        } catch (error) {
          console.log('create bookmark api error', error)
        }
      },
    }),
    createChildProjectBookmark: builder.mutation<
      ProjectsBookmarkInterface,
      CreateProjectBookmarkArgs
    >({
      query: (body) => ({
        url: BOOKMARKROUTES.projects,
        method: 'POST',
        body,
      }),
      /**
       * Handles actions when a project creation query is initiated.
       * 
       * This function executes when a query to create a project is initiated. It updates
       * relevant query data and invalidates specific cache tags based on the context from which
       * the query was triggered.
       * @param {string} params.type - Type of the project ('child' or other).
       * @param {string} params.from - Source context ('moreInfoPopup' or other).
       * @param {object} api - API-related utilities such as queryFulfilled, dispatch, and getState.
       */      
      async onQueryStarted({ dataArgs, project_id, type, from }, { queryFulfilled, dispatch, getState }) {
        try {
          await queryFulfilled;
          if(from === 'moreInfoPopup') {
            if(type === 'child') {
              for (const { endpointName, originalArgs } of projectApi.util.selectInvalidatedBy(getState(), [{ type: "GetAllProjects" }])) { 
                // we only want to update `getPosts` here
                if ( endpointName !== 'getAllProjects') continue;
                dispatch(
                  projectApi.util.updateQueryData(endpointName, originalArgs, (draft: any) => {
                     // find in list & update
                     const dataIndex = draft?.data?.findIndex((x: any) => x.id === project_id)
                      if(dataIndex >= 0){
                        draft.data[dataIndex] = { ...draft.data[dataIndex], existsInBookmarks: true }
                      }
                  })
                )
              }
            }
            dispatch(parentProjectApi.util.invalidateTags(["ProjectsBookmark", "AllParentProjectsMatching"]));
          }
        } catch (error) {
          console.log('create bookmark api error', error)
        }
      },
    }),
    /**
     * Retrieves all matching data related to projects.
     * 
     * Fetches matching data related to projects from the API endpoint specified by PROJECTROUTES.allMatchingData,
     * using the provided search parameters.
     * 
     * @param {object} params - Parameters object containing search parameters.
     * @param {string} params.search - Additional search parameters for filtering the query.
     */
    getAllMatchingData: builder.query<ContactsDataResponse, any>({
      query: (search) => ({
        url: `${PROJECTROUTES.allMatchingData}${search}`,
      }),
    }),
    /**
     * Retrieves all company matching data related to projects.
     * 
     * Fetches company matching data related to projects from the API endpoint specified by PROJECTROUTES.companyMatchedData,
     * using the provided search parameters.
     * 
     * @param {object} params - Parameters object containing search parameters.
     * @param {string} params.search - Additional search parameters for filtering the query.
     */
    getAllCompanyMatchingData: builder.query<ContactsDataResponse, any>({
      query: (search) => ({
        url: `${PROJECTROUTES.companyMatchedData}${search}`,
      }),
    }),
    /**
     * Retrieves project queries based on serial ID and matching type.
     * 
     * Fetches project queries from the API endpoint specified by PROJECTROUTES.matchingQueries,
     * using the provided serial ID and matching type.
     * 
     * @param {object} params - Parameters object containing serialId and matchingType.
     * @param {string} params.serialId - The serial ID of the project.
     * @param {string} params.matchingType - The type of matching to perform.
     */
    getProjectQueries: builder.query<any, any>({
      query: ({ serialId, matchingType }) => ({
        url: `${PROJECTROUTES.matchingQueries}/${serialId}?matchingType=${matchingType}`,
        method: 'GET',
      }),
    }),
    /**
     * Retrieves distinct project names based on search value.
     * 
     * Fetches distinct project names from the API endpoint specified by PROJECTROUTES.names,
     * using the provided search value.
     * 
     * @param {object} params - Parameters object containing searchValue.
     * @param {string} params.searchValue - The value to search for among project names.
     */
    getDistinctProjectNames: builder.query<any, any>({
      query: ({ searchValue }) => ({
        url: `${PROJECTROUTES.names}?name=${searchValue}`,
        method: 'GET',
      }),
    }),
    /**
     * Retrieves geo data for all parent projects related to a child project.
     * 
     * Fetches geo data for all parent projects associated with a child project from the API endpoint
     * specified by PARENTPROJECTROUTES.parentGeoData, using the provided child ID.
     * 
     * @param {object} params - Parameters object containing childId.
     * @param {string} params.childId - The ID of the child project.
     */    
    getAllParentProjectGeoData: builder.query<ProjectsGeoDataResponse, string>({
      query: (childId) => ({
        url: `${PARENTPROJECTROUTES.parentGeoData}?childId=${childId}`,
      }),
    }),
    /**
     * Retrieves the center point geo data for a parent project related to a child project.
     * 
     * Fetches the center point geo data for a parent project associated with a child project from the API endpoint
     * specified by PROJECTROUTES.parentCenter, using the provided child ID.
     * 
     * @param {object} params - Parameters object containing childId.
     * @param {string} params.childId - The ID of the child project.
     */    
    getParentProjectCenter: builder.query<ProjectsGeoDataResponse, string>({
      query: (childId) => ({
        url: `${PROJECTROUTES.parentCenter}?childId=${childId}`,
      }),
    }),
    /**
     * Retrieves geo data for all project-to-project matchings related to a project.
     * 
     * Fetches geo data for all project-to-project matchings associated with a project from the API endpoint
     * specified by PARENTPROJECTROUTES.matchingGeoData, using the provided project ID, type of relationship,
     * @param {object} params - Parameters object containing projectId, isChild, page, limit, and showProjectsType.
     * @param {string} params.projectId - The ID of the project.
     * @param {string} params.showProjectsType - The type of projects to show in the matching.
     */    
    getAllProjectToProjectsMatchingsGeoData: builder.query<any, any>({
      query: ({ projectId, isChild, page, limit, showProjectsType }) => ({
        url: `${PARENTPROJECTROUTES.matchingGeoData}/${projectId}?isChild=${isChild}&page=${page}&limit=${limit}&matchingToProjectType=${showProjectsType}`,
      }),
    }),
    /**
     * Retrieves projects based on search criteria.
     * 
     * Fetches projects from the API endpoint specified by PROJECTROUTES.getQuery, using the provided search criteria.
     * 
     * @param {object} params - Parameters object containing search criteria.
     * @param {string} params.search - Additional search parameters for filtering the query.
     */    
    getProjectsQuery: builder.query<any, any>({
      query: (search) => ({
        url: `${PROJECTROUTES.getQuery}${search}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetProjectGeoDataQuery,
  useGetChildProjectGeoDataQuery,
  useGetProjectCenterPointQuery,
  useGetAllProjectsQuery,
  useGetProjectsCountQuery,
  useGetProjectInfoQuery,
  useGetSingleProjectMatchedDataQuery,
  useGetAllSingleProjectMatchedDataQuery,
  useGetProjectInfoAllDataQuery,
  useGetAllMatchingDataQuery,
  useGetAllCompanyMatchingDataQuery,
  useGetProjectQueriesQuery,
  useGetDistinctProjectNamesQuery,
  useGetAllParentProjectGeoDataQuery,
  useGetParentProjectCenterQuery,
  useGetAllProjectToProjectsMatchingsGeoDataQuery,
  useGetProjectsQueryQuery,
  useCreateChildProjectBookmarkMutation,
  useDeleteChildProjectBookmarkByProjectIdMutation,
} = projectApi;
