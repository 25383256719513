/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useMemo } from 'react';
import { Button, Card, Checkbox, Col, Drawer, Empty, Input, notification, Radio, Row, Select, Spin, Tabs } from 'antd';
import SiteLayout from 'components/SiteLayout';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  useGetSurveyByIdQuery,
  useGetSurveyContactsQuery,
  useGetSurveyQueriesQuery,
  useGetSurveyResutsCountsQuery,
  useUpdateRecalculateResponseMutation,
  useUpdateSurveyResponseMutation
} from 'app/services';
import { skipToken } from '@reduxjs/toolkit/query';
import CountUp from 'react-countup';
import Item from './Item';
import ItemListModal from './ItemListModal';
import Navbar from 'components/Navbar';
import qs from 'query-string';
import { CopyBlock, dracula } from "react-code-blocks";
import { SurveyResponseMapping } from 'utils/surveyResponseMapping';
import TabPane from 'antd/lib/tabs/TabPane';
import { getSurveyCriteria } from 'services/surveys.service';
import { LeftOutlined } from '@ant-design/icons';
import TooltipComponent from 'components/Tooltip';
const Network = () => {


  const { search } = useLocation();

  const [surveyid, setSurveyid] = useState('');
  const [matchingtype, setMatchingtype] = useState('');
  const [visibleModel, setVisibleModel] = useState(false);
  const [title, setTitle] = useState('');
  const [color, setColor] = useState('');
  const [selectedType, setSelectedType] = useState<any>({});
  const [initialLoader, setInitialLoader] = useState(false);
  const [surveyTakerFromSurvey, setSurveyTakerFromSurvey] = useState('')
  const [showSurveyCriteriaDrawer, setShowSurveyCriteriaDrawer]
    = useState<any>(false)
  const [formattedRespone, setFormattedResponse] = useState<any>([])
  const [getQueries, setGetQueries] = useState(false)
  const [showSurveyQueries, setShowSurveyQueries] = useState(false)
  const [queries, setQueries] = useState<any>({})
  const [surveyCriterias, setSurveyCriteriaData] = useState<any>([])
  const [failedQueries, setFailedQueries] = useState<any>([])
  const [isRecalculating, setIsRecalculating] = useState(false)
  const [selectedCountCard, setSelectedCountCard] = useState<any>(null)
  const [canUpdate, setCanUpdate] = useState<any>(false)

  // const {
  //   data: surveyResult,
  //   isSuccess: isSurvey,
  //   isBuilderCountFetching,
  // } = useGetSurveyResultsQuery(surveyid ? surveyid : skipToken, {
  //   skip: surveyid === '',
  // });

  // let { data: surveyCriterias, refetch: refetchCriterias } =
  //   useGetSurveyCriteriasBySurveyIdQuery({
  //     surveyid: surveyid ? surveyid : skipToken
  //   }, {
  //     skip: !surveyid
  //   }
  //   );


  const getCriteriaDataFromSurvey = async () => {
    if (surveyid) {
      const res = await getSurveyCriteria(surveyid)
      setSurveyCriteriaData(res.data)
    }
  }

  useEffect(() => {
    if (surveyid) {
      localStorage.setItem('surveyId', surveyid)
    }
    getCriteriaDataFromSurvey()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyid])

  const { data: surveyContacts } =
    useGetSurveyContactsQuery(surveyTakerFromSurvey, {
      skip: !surveyTakerFromSurvey,
    });

  const { data: surveyDetails, isLoading: isSurveyDetailsLoading } =
    useGetSurveyByIdQuery({ id: surveyid }, {
      skip: !surveyid,
    });

  const { data: surveyQueries, isLoading: isSurveyQueriesLoading,
    refetch: refetchQueries } =
    useGetSurveyQueriesQuery
      ({ id: formattedRespone[0]?.questionProID, isRecalculating }, {
        skip: !getQueries || !formattedRespone.length
          || !formattedRespone[0].questionProID,
      });

  const [updateSurveyResponseMutation] = useUpdateSurveyResponseMutation();

  const [updateRecalculateResponseMutation] =
    useUpdateRecalculateResponseMutation();

  // count start

  let {
    data: builderCount,
    isSuccess: isBuilderCount,
    isFetching: isBuilderCountFetching,
    refetch: refetchBuilderCountResults
  } = useGetSurveyResutsCountsQuery(
    {
      surveyid: surveyid ? surveyid : skipToken,
      page: 1,
      limit: 10,
      matchingtype: 'builders',
      isRecalculating
    },
    { skip: !surveyid },
  );

  let {
    data: investorCount,
    isSuccess: isInvestorCount,
    isFetching: isInvestorCountFetching,
    refetch: refetchInvestorCountResults
  } = useGetSurveyResutsCountsQuery(
    {
      surveyid: surveyid ? surveyid : skipToken,
      page: 1,
      limit: 10,
      matchingtype: 'investors',
      isRecalculating
    },
    { skip: !surveyid },
  );

  let {
    data: developerCount,
    isSuccess: isDeveloperCount,
    isFetching: isDeveloperCountFetching,
    refetch: refetchDeveloperCountResults
  } = useGetSurveyResutsCountsQuery(
    {
      surveyid: surveyid ? surveyid : skipToken,
      page: 1,
      limit: 10,
      matchingtype: 'developers',
      isRecalculating
    },
    { skip: !surveyid },
  );

  let {
    data: realEstateAgentCount,
    isSuccess: isRealEstateAgentCount,
    isFetching: isRealEstateAgentCountFetching,
    refetch: refetchRealEstateAgentCountResults
  } = useGetSurveyResutsCountsQuery(
    {
      surveyid: surveyid ? surveyid : skipToken,
      page: 1,
      limit: 10,
      matchingtype: 'realEstateAgents',
      isRecalculating
    },
    { skip: !surveyid },
  );

  let {
    data: newBuildHomebuyerCount,
    isSuccess: isNewBuildHomebuyerCount,
    isFetching: isNewBuildHomebuyerCountFetching,
    refetch: refetchNewBuildHomebuyerCountResults
  } = useGetSurveyResutsCountsQuery(
    {
      surveyid: surveyid ? surveyid : skipToken,
      page: 1,
      limit: 10,
      matchingtype: 'newBuildHomebuyers',
      isRecalculating
    },
    { skip: !surveyid },
  );

  let {
    data: waterRightsCount,
    isSuccess: isWaterRightsCount,
    isFetching: isWaterRightsCountFetching,
    refetch: refetchWaterRightsCountResults
  } = useGetSurveyResutsCountsQuery(
    {
      surveyid: surveyid ? surveyid : skipToken,
      page: 1,
      limit: 10,
      matchingtype: 'waterRights',
      isRecalculating
    },
    { skip: !surveyid },
  );

  // let {
  //   data: projectsCount,
  //   isSuccess: isProjectsCount,
  //   isFetching: isProjectsCountFetching,
  //   refetch: refetchProjectsCountResults
  // } = useGetSurveyResutsCountsQuery(
  //   {
  //     surveyid: surveyid ? surveyid : skipToken,
  //     page: 1,
  //     limit: 10,
  //     matchingtype: 'projects',
  //     isRecalculating
  //   },
  //   { skip: !surveyid },
  // );

  //count end

  const getColumnNamesFromQuery = (sql: any) => {
    const columnRegex = /c\.(".*?")/g;
    const columnMatches = [...sql.matchAll(columnRegex)];
    const columnNames = columnMatches.map(match => match[1].replace(/"/g, ''));
    return [...new Set(columnNames)]
  }

  // const getColumnNamesFromQueryForProjects = (sql: any) => {
  //   const columnNames = sql.match(/"([^"]+)"/g);
  //   const cleanColumnNames = columnNames.map((elem: any) => elem.replace(/"/g, ''));
  //   return [...new Set(cleanColumnNames.filter((elem: any) => elem !== 'Project' && elem !== 'projects_new'))]
  // }

  useEffect(() => {
    if (surveyQueries) {
      const waterRights = surveyQueries?.waterRights?.trim()?.replace('* FROM', 'COUNT(*) FROM')
      // const projects = surveyQueries?.projects

      // Investors
      const investorsContactsArr: any = []
      const investorsCompaniesArr: any = []
      surveyQueries?.contacts?.forEach((elem: any) => {
        if (elem.includes("SELECT *, 'Investors'")) {
          investorsContactsArr.push(elem.replace(`*, 'Investors' as "role"`, 'COUNT(*)')?.trim())
        }
      })
      surveyQueries?.companies?.forEach((elem: any) => {
        if (elem.includes("SELECT *, 'Investors'")) {
          investorsCompaniesArr.push(elem.replace(`*, 'Investors' as "role"`, 'COUNT(*)')?.trim())
        }
      })
      const investorContacts = investorsContactsArr?.join(' UNION ')
      const investorCompanies = investorsCompaniesArr?.join(' UNION ')
      // Investors

      // Builders
      const buildersContactsArr: any = []
      const buildersCompaniesArr: any = []
      surveyQueries?.contacts?.forEach((elem: any) => {
        if (elem.includes("SELECT *, 'Builder'")) {
          buildersContactsArr.push(elem.replace(`*, 'Builder' as "role"`, 'COUNT(*)')?.trim())
        }
      })
      surveyQueries?.companies?.forEach((elem: any) => {
        if (elem.includes("SELECT *, 'Builder'")) {
          buildersCompaniesArr.push(elem.replace(`*, 'Builder' as "role"`, 'COUNT(*)')?.trim())
        }
      })
      const builderContacts = buildersContactsArr?.join(' UNION ')
      const builderCompanies = buildersCompaniesArr?.join(' UNION ')
      // Builders

      // Developers
      const developersContactsArr: any = []
      const developersCompaniesArr: any = []
      surveyQueries?.contacts?.forEach((elem: any) => {
        if (elem.includes("SELECT *, 'Developer'")) {
          developersContactsArr.push(elem.replace(`*, 'Developer' as "role"`, 'COUNT(*)')?.trim())
        }
      })
      surveyQueries?.companies?.forEach((elem: any) => {
        if (elem.includes("SELECT *, 'Developer'")) {
          developersCompaniesArr.push(elem.replace(`*, 'Developer' as "role"`, 'COUNT(*)')?.trim())
        }
      })
      const developerContacts = developersContactsArr?.join(' UNION ')
      const developerCompanies = developersCompaniesArr?.join(' UNION ')
      // Developers

      // Homebuyers
      const homebuyersContactsArr: any = []
      const homebuyersCompaniesArr: any = []
      surveyQueries?.contacts?.forEach((elem: any) => {
        if (elem.includes("SELECT *, 'New Build Homebuyer'")) {
          homebuyersContactsArr.push(elem.replace(`*, 'New Build Homebuyer' as "role"`, 'COUNT(*)')?.trim())
        }
      })
      surveyQueries?.companies?.forEach((elem: any) => {
        if (elem.includes("SELECT *, 'New Build Homebuyer'")) {
          homebuyersCompaniesArr.push(elem.replace(`*, 'New Build Homebuyer' as "role"`, 'COUNT(*)')?.trim())
        }
      })
      const homebuyerContacts = homebuyersContactsArr?.join(' UNION ')
      const homebuyerCompanies = homebuyersCompaniesArr?.join(' UNION ')
      // Homebuyers

      // RealEstateAgents
      const realEstateAgentContactsArr: any = []
      const realEstateAgentCompaniessArr: any = []
      surveyQueries?.contacts?.forEach((elem: any) => {
        if (elem.includes("SELECT *, 'Real Estate Agent'")) {
          realEstateAgentContactsArr.push(elem.replace(`*, 'Real Estate Agent' as "role"`, 'COUNT(*)')?.trim())
        }
      })
      surveyQueries?.companies?.forEach((elem: any) => {
        if (elem.includes("SELECT *, 'Real Estate Agent'")) {
          realEstateAgentCompaniessArr.push(elem.replace(`*, 'Real Estate Agent' as "role"`, 'COUNT(*)')?.trim())
        }
      })
      const realEstateAgentContacts = realEstateAgentContactsArr?.join(' UNION ')
      const realEstateAgentCompanies = realEstateAgentCompaniessArr?.join(' UNION ')
      // RealEstateAgents

      const finalQueries: any = {}
      if (builderContacts || builderCompanies) {
        finalQueries.Builders = []
        if (builderContacts) {
          finalQueries.Builders.push({
            title: 'Contacts',
            query: builderContacts,
            count: isBuilderCountFetching ? 'Loading..' : builderCount ? builderCount?.contactsCount : 0,
            totalCount: isBuilderCountFetching ? 'Loading..' : builderCount ? +builderCount?.contactsCount + +builderCount?.companiesCount : 0,
            columns: builderContacts ?
              getColumnNamesFromQuery(builderContacts) : []
          })
        }
        if (builderCompanies) {
          finalQueries.Builders.push({
            title: 'Companies',
            query: builderCompanies,
            count: isBuilderCountFetching ? 'Loading..' : builderCount ? builderCount?.companiesCount : 0,
            totalCount: isBuilderCountFetching ? 'Loading..' : builderCount ? +builderCount?.contactsCount + +builderCount?.companiesCount : 0,
            columns: builderCompanies ?
              getColumnNamesFromQuery(builderCompanies) : []
          })
        }
      }
      if (investorContacts || investorCompanies) {
        finalQueries.Investors = []
        if (investorContacts) {
          finalQueries.Investors.push({
            title: 'Contacts',
            query: investorContacts,
            count: isInvestorCountFetching ? 'Loading..' : investorCount ? investorCount?.contactsCount : 0,
            totalCount: isInvestorCountFetching ? 'Loading..' : investorCount ? +investorCount?.contactsCount +
              +investorCount?.companiesCount : 0,
            columns: investorContacts ?
              getColumnNamesFromQuery(investorContacts) : []
          })
        }
        if (investorCompanies) {
          finalQueries.Investors.push({
            title: 'Companies',
            query: investorCompanies,
            count: isInvestorCountFetching ? 'Loading..' : investorCount ? investorCount?.companiesCount : 0,
            totalCount: isInvestorCountFetching ? 'Loading..' : investorCount ? +investorCount?.contactsCount +
              +investorCount?.companiesCount : 0,
            columns: investorCompanies ?
              getColumnNamesFromQuery(investorCompanies) : []
          })
        }
      }

      if (developerContacts || developerCompanies) {
        finalQueries.Developers = []
        if (developerContacts) {
          finalQueries.Developers.push({
            title: 'Contacts',
            query: developerContacts,
            count: isDeveloperCountFetching ? 'Loading..' : developerCount ? developerCount?.contactsCount : 0,
            totalCount: isDeveloperCountFetching ? 'Loading..' : developerCount ? +developerCount?.contactsCount +
              +developerCount?.companiesCount : 0,
            columns: developerContacts ?
              getColumnNamesFromQuery(developerContacts) : []
          })
        }
        if (developerCompanies) {
          finalQueries.Developers.push({
            title: 'Companies',
            query: developerCompanies,
            count: isDeveloperCountFetching ? 'Loading..' : developerCount ? developerCount?.companiesCount : 0,
            totalCount: isDeveloperCountFetching ? 'Loading..' : developerCount ? +developerCount?.contactsCount +
              +developerCount?.companiesCount : 0,
            columns: developerCompanies ?
              getColumnNamesFromQuery(developerCompanies) : []
          })
        }
      }

      if (homebuyerContacts || homebuyerCompanies) {
        finalQueries['New Build Homebuyer'] = []
        if (homebuyerContacts) {
          finalQueries['New Build Homebuyer'].push({
            title: 'Contacts',
            query: homebuyerContacts,
            count: isNewBuildHomebuyerCountFetching ? 'Loading..' : newBuildHomebuyerCount ? newBuildHomebuyerCount?.contactsCount : 0,
            totalCount: isNewBuildHomebuyerCountFetching ? 'Loading..' : newBuildHomebuyerCount ? +newBuildHomebuyerCount?.contactsCount +
              +newBuildHomebuyerCount?.companiesCount : 0,
            columns: homebuyerContacts ?
              getColumnNamesFromQuery(homebuyerContacts) : []
          })
        }
        if (homebuyerCompanies) {
          finalQueries['New Build Homebuyer'].push({
            title: 'Companies',
            query: homebuyerCompanies,
            count: isNewBuildHomebuyerCountFetching ? 'Loading..' : newBuildHomebuyerCount ? newBuildHomebuyerCount?.companiesCount : 0,
            totalCount: isNewBuildHomebuyerCountFetching ? 'Loading..' : newBuildHomebuyerCount ? +newBuildHomebuyerCount?.contactsCount +
              +newBuildHomebuyerCount?.companiesCount : 0,
            columns: homebuyerCompanies ?
              getColumnNamesFromQuery(homebuyerCompanies) : []
          })
        }
      }

      if (realEstateAgentContacts || realEstateAgentCompanies) {
        finalQueries['Real Estate Agents'] = []
        if (realEstateAgentContacts) {
          finalQueries['Real Estate Agents'].push({
            title: 'Contacts',
            query: realEstateAgentContacts,
            count: isRealEstateAgentCountFetching ? 'Loading..' : realEstateAgentCount ? realEstateAgentCount?.contactsCount : 0,
            totalCount: isRealEstateAgentCountFetching ? 'Loading..' : realEstateAgentCount ? +realEstateAgentCount?.contactsCount +
              +realEstateAgentCount?.companiesCount : 0,
            columns: realEstateAgentContacts ?
              getColumnNamesFromQuery(realEstateAgentContacts) : []
          })
        }
        if (realEstateAgentCompanies) {
          finalQueries['Real Estate Agents'].push({
            title: 'Companies',
            query: realEstateAgentCompanies,
            count: isRealEstateAgentCountFetching ? 'Loading..' : realEstateAgentCount ? realEstateAgentCount?.companiesCount : 0,
            totalCount: isRealEstateAgentCountFetching ? 'Loading..' : realEstateAgentCount ? +realEstateAgentCount?.contactsCount +
              +realEstateAgentCount?.companiesCount : 0,
            columns: realEstateAgentCompanies ?
              getColumnNamesFromQuery(realEstateAgentCompanies) : []
          })
        }
      }

      if (waterRights) {
        finalQueries['Water Rights'] = []
        finalQueries['Water Rights'].push({
          title: 'Water Rights',
          query: waterRights,
          count: isWaterRightsCountFetching ? 'Loading..' : waterRightsCount ? waterRightsCount?.waterRightsCount : 0,
          totalCount: isWaterRightsCountFetching ? 'Loading..' : waterRightsCount ? waterRightsCount?.waterRightsCount : 0,
          columns: waterRights ? ['WRNUM'] : []
        })
      }

      // if (projects) {
      //   finalQueries.Projects = []
      //   finalQueries.Projects.push({
      //     title: 'Projects',
      //     query: projects,
      //     count: isProjectsCountFetching ? 'Loading..' : projectsCount ? projectsCount?.projectsCount : 0,
      //     totalCount: isProjectsCountFetching ? 'Loading..' : projectsCount ? projectsCount?.projectsCount : 0,
      //     columns: projects ? getColumnNamesFromQueryForProjects(projects) : []
      //   })
      // }

      setQueries(finalQueries)

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyQueries, isBuilderCountFetching, isInvestorCountFetching,
    isRealEstateAgentCountFetching, isNewBuildHomebuyerCountFetching,
    isRealEstateAgentCountFetching, isWaterRightsCountFetching,
    isDeveloperCountFetching])

  useEffect(() => {
    let formatted: any
    if(surveyDetails?.name) {
      localStorage.setItem('surveyName', surveyDetails?.name)
    }
    if (surveyDetails) {
      formatted = SurveyResponseMapping(surveyDetails)
      if (formatted)
        setFormattedResponse(formatted)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyDetails])


  const showOrHideHandler = (value: any, allResponse: any, index: any) => {
    let minSingleFamilyIndex = allResponse.findIndex((elem: any) => elem.label === 'Minimum amount of Single Family Lots')
    let maxSingleFamilyIndex = allResponse.findIndex((elem: any) => elem.label === 'Maximum amount of Single Family Lots')
    let minApartmentOrCondoIndex = allResponse.findIndex((elem: any) => elem.label === 'Minimum amount of Apartment/Condo units')
    let maxApartmentOrCondoIndex = allResponse.findIndex((elem: any) => elem.label === 'Maximum amount of Apartment/Condo units')
    let minTownhomesIndex = allResponse.findIndex((elem: any) => elem.label === 'Minimum amount of Townhome pads')
    let maxTownhomesIndex = allResponse.findIndex((elem: any) => elem.label === 'Maximum amount of Townhome pads')
    let customBuilder = allResponse.findIndex((elem: any) => elem.label === 'Are you a custom builder?') || allResponse.findIndex((elem: any) => elem.label === 'Is your client a custom builder?')
    let howManySingleFamilyLots = allResponse.findIndex((elem: any) => elem.label === 'How many single family lots?')
    let howManyTownHomePads = allResponse.findIndex((elem: any) => elem.label === 'How many townhome pads?')
    let howManyCondos = allResponse.findIndex((elem: any) => elem.label === 'How many apartments/condos?')

    if (allResponse[index]?.criteria === 'whichOfTheseProductsDoYouBuild' || allResponse[index]?.criteria === 'whichOfTheseProductsDoesYourClientBuild' || allResponse[index]?.criteria === 'whatRealEstateProductDoYouDevelop' || allResponse[index]?.criteria === 'whatRealEstateProductDoesYourClientDevelop') {
      // Single Family
      if (value?.includes('Single family') || value?.includes('Single Family')) {
        allResponse[minSingleFamilyIndex].hide = false
        allResponse[maxSingleFamilyIndex].hide = false
        if(customBuilder && customBuilder > 0) {
          allResponse[customBuilder].hide = false
        }
      } else {
        allResponse[minSingleFamilyIndex].hide = true
        allResponse[maxSingleFamilyIndex].hide = true
        if(customBuilder && customBuilder > 0) {
          allResponse[customBuilder].hide = true
        }
      }
      // Single Family

      // Apartment/Condo
      if (value?.includes('Condos/Apartments') || value?.includes('Apartments/Condos')) {
        allResponse[minApartmentOrCondoIndex].hide = false
        allResponse[maxApartmentOrCondoIndex].hide = false
      } else {
        allResponse[minApartmentOrCondoIndex].hide = true
        allResponse[maxApartmentOrCondoIndex].hide = true
      }
      // Apartment/Condo

      // Townhomes
      if (value?.includes('Townhomes')) {
        allResponse[minTownhomesIndex].hide = false
        allResponse[maxTownhomesIndex].hide = false
      } else {
        allResponse[minTownhomesIndex].hide = true
        allResponse[maxTownhomesIndex].hide = true
      }
      // Townhomes
    }

    if (allResponse[index]?.criteria === 'tempTypeOfProject') {
      // Single Family
      if (value?.includes('Single Family Residential')) {
        allResponse[howManySingleFamilyLots].hide = false
      } else {
        allResponse[howManySingleFamilyLots].hide = true
      }
      // Single Family

      // Apartment/Condo
      if (value?.includes('Apartments/Condos')) {
        allResponse[howManyCondos].hide = false
      } else {
        allResponse[howManyCondos].hide = true
      }
      // Apartment/Condo

      // Townhomes
      if (value?.includes('Town Homes')) {
        allResponse[howManyTownHomePads].hide = false
      } else {
        allResponse[howManyTownHomePads].hide = true
      }
      // Townhomes
    }
    setFormattedResponse(allResponse)
  }

  const onChangeResponse = (value: any, index: any) => {
    setCanUpdate(false)
    const allResponse = [...formattedRespone]
    allResponse[index].value = value
    showOrHideHandler(value, allResponse, index)
  }

  const validateResponse = (surveyName: any, response: any) => {
    console.log('response', response)
    let canProceed = true
    let message
    if (surveyName === 'Builder Company Survey') {
      if (!response.utah_area_tags) {
        message = 'Utah area tags should not be empty'
      }
      if (!response.which_of_these_products_do_you_build_) {
        message = 'Please select the products you build'
      }
      if (response.which_of_these_products_do_you_build_?.includes('Single Family')) {
        let min = response.what_is_the_minimum_amount_of_single_family_lots_your_company_is_willing_to_purchase_per_subdivisio
        let max = response.what_is_the_maximum_amount_of_single_family_lots_your_company_is_willing_to_purchase_per_subdivisio

        if ((!min || !max)) {
          message = 'Please select the minimum and maximum amount of single family lots'
        } else if ((min !== "Don't Develop SFD" && max !== "Don't Develop SFD" && +min.split('-')[0] > +max.split('-')[0]) || (min === '65 or more' && max !== '65 or more')) {
          message = 'Maximum amount of single family lots should be greater than minimum amount of single family lots'
        }
      }
      if (response.which_of_these_products_do_you_build_?.includes('Townhomes')) {
        let min = response.what_is_the_minimum_amount_of_townhome_pads_your_company_is_willing_to_purchase_per_subdivision_at_
        let max = response.what_is_the_maximum_amount_of_townhome_pads_your_company_is_willing_to_purchase_per_subdivision_at_

        if ((!min || !max)) {
          message = 'Please select the minimum and maximum amount of townhome pads'
        } else if ((+min.split('-')[0] > +max.split('-')[0]) || (min === '80+' && max !== '80+')) {
          message = 'Maximum amount of townhome pads should be greater than minimum amount of townhome pads'
        }
      }

      if (response.which_of_these_products_do_you_build_?.includes('Condos/Apartments')) {
        let min = response.what_is_the_minimum_amount_of_apartment_condo_units_your_company_would_be_willing_to_purchase_per_s
        let max = response.what_is_the_maximum_amount_of_apartment_condo_units_your_company_would_be_willing_to_purchase_per_s

        if ((!min || !max)) {
          message = 'Please select the minimum and maximum amount of apartment/condo units'
        } else if ((+min.split('-')[0] > +max.split('-')[0]) || (min === '500 or more' && max !== '500 or more')) {
          message = 'Maximum amount of apartment/condo units should be greater than minimum amount of apartment/condo units'
        }
      }
    }
    if (surveyName === 'Developer Company Survey') {
      if (!response.utah_area_tags) {
        message = 'Utah area tags should not be empty'
      }
      if (!response.what_real_estate_product_do_you_develop_) {
        message = 'Please select the products you develop'
      }
      if (response.what_real_estate_product_do_you_develop_?.includes('Single family')) {
        let min = response.what_is_the_minimum_amount_of_single_family_lots_your_company_would_develop_per_subdivision_at_once
        let max = response.what_is_the_maximum_amount_of_single_family_lots_your_company_is_willing_to_develop_per_subdivision

        if ((!min || !max)) {
          message = 'Please select the minimum and maximum amount of single family lots'
        } else if ((min !== "Don't Develop SFD" && max !== "Don't Develop SFD" && +min.split('-')[0] > +max.split('-')[0]) || (min === '65 or more' && max !== '65 or more')) {
          console.log('min', min)
          console.log('max', max)
          message = 'Maximum amount of single family lots should be greater than minimum amount of single family lots'
        }
      }
      if (response.what_real_estate_product_do_you_develop_?.includes('Townhomes')) {
        let min = response.what_is_the_minimum_amount_of_townhome_pads_your_company_is_willing_to_develop_per_subdivision_at_o
        let max = response.what_is_the_maximum_amount_of_townhome_pads_your_company_is_willing_to_develop_per_subdivision_at_o

        if ((!min || !max)) {
          message = 'Please select the minimum and maximum amount of townhome pads'
        } else if ((+min.split('-')[0] > +max.split('-')[0]) || (min === '80 or more' && max !== '80 or more')) {
          message = 'Maximum amount of townhome pads should be greater than minimum amount of townhome pads'
        }
      }
      if (response.what_real_estate_product_do_you_develop_?.includes('Apartments/Condos')) {
        let min = response.what_is_the_minimum_amount_of_apartment_condo_units_your_company_would_be_willing_to_develop_per_su
        let max = response.what_is_the_maximum_amount_of_apartment_condo_units_your_company_would_be_willing_to_develop_per_su

        if ((!min || !max)) {
          message = 'Please select the minimum and maximum amount of apartment/condo units'
        } else if ((+min.split('-')[0] > +max.split('-')[0]) || (min === '500 or more' && max !== '500 or more')) {
          message = 'Maximum amount of apartment/condo units should be greater than minimum amount of apartment/condo units'
        }
      }
    }
    if (surveyName === 'Land Owner Survey') {
      if (!response.how_many_acres_is_your_land_) {
        message = 'Please enter the acres of your land'
      }
      if (!response.property_located_in_city) {
        message = 'Please enter the city name'
      }
      if (!response.landOwnerGeneralZoningQuestion?.length) {
        message = 'Please enter what your land currently zoned for'
      }
    }
    if (surveyName === 'New Build Home Buyer Survey') {
      if (!response.new_build_home_buyer_desired_cities) {
        message = 'Please enter the city name'
      }
      if (!response.type_style_of_home) {
        message = 'Please enter the type/style of home are you looking for'
      }
    }
    if (surveyName === 'I Have a Project Survey') {
      if (!response.temp_city) {
        message = 'Please enter the city name'
      }
      if (!response.temp_project_development_stage) {
        message = 'Please select the project status'
      }
      if (!response.temp_type_of_project) {
        message = 'Please select the project type'
      }
      if (response.temp_type_of_project?.includes('Single Family Residential') && !response.how_many_single_family_lots) {
        message = 'Please enter the number of single family lots'
      }
      if (response.temp_type_of_project?.includes('Town Homes') && !response.how_many_townhome_pads) {
        message = 'Please enter the number of townhome pads'
      }
      if (response.temp_type_of_project?.includes('Apartments/Condos') && !response.how_many_apartments_condos) {
        message = 'Please enter the number of apartments/condos'
      }
    }
    if (surveyName === 'Investor Survey') {
      if (!response.utah_area_investment_tags) {
        message = 'Utah area tags should not be empty'
      }
      if (!response.what_real_estate_product_does_your_company_invest_in_) {
        message = 'Please select the real estate product your company invest in'
      }
      if (!response.what_real_estate_product_does_your_company_invest_in_) {
        message = 'Please select the real estate product your company invest in'
      }
      if (!response.what_s_your_minimum_investment_amount_) {
        message = 'Please select your minimum investment/loan amount'
      }
      if (!response.what_real_estate_product_does_your_company_invest_in_) {
        message = 'Please select your maximum investment/loan amount'
      }
      if (!response.are_you_an_investor_or_a_lender_) {
        message = 'Please select whether you are an investor or a lender'
      }
    }
    if (surveyName === 'Real Estate Agent Survey') {
      if (!response.what_is_your_real_estate_specialty_) {
        message = 'Please select your real estate specialty'
      }
      if (!response.what_is_your_license_type_) {
        message = 'Please select your license type'
      }
      if (!response.utah_area_tags) {
        message = 'Utah area tags should not be empty'
      }
    }
    if (surveyName === 'Water Right Survey') {
      if (!response.temp_city_of_water_right) {
        message = 'Please enter the location of water right by city'
      }
    }
    if (surveyName === 'Real Estate Agent Client Survey') {
      if (response?.clientType === 'Property Seller') {
        if (!response.client_property_located_in_city) {
          message = "Please enter your client's city name"
        }
        if (!response.client_how_many_acres) {
          message = "Please enter the acres of your client's land"
        }
        if (!response.client_seller_current_land_zoning) {
          message = "Please enter what your client's land currently zoned for"
        }
        if (!response.client_utah_area_tags) {
          message = 'Utah area tags should not be empty'
        }
      }
      if (response?.clientType === 'Land Buyer') {
        if (!response.what_is_your_client_real_estate_specialty_) {
          message = "Please select your client's real estate specialty"
        }
        if (!response.what_is_your_client_license_type_) {
          message = "Please select your client's license type"
        }
        if (!response.client_utah_area_tags) {
          message = 'Utah area tags should not be empty'
        }
      }
      if (response?.clientType === 'Investor') {
        if (!response.client_utah_area_investment_tags) {
          message = 'Utah area tags should not be empty'
        }
        if (!response.what_real_estate_product_does_your_client_invest_in_) {
          message = 'Please select the real estate product your client invest in'
        }
        if (!response.client_minimum_investment_amount) {
          message = "Please select your client's minimum investment/loan amount"
        }
        if (!response.client_maximum_investment_amount) {
          message = "Please select your client's maximum investment/loan amount"
        }
      }
      if (response?.clientType === 'Builder') {

        if (!response.client_utah_area_tags) {
          message = 'Utah area tags should not be empty'
        }
        if (!response.which_of_these_products_does_your_client_build_) {
          message = 'Please select the products your client build'
        }
        if (response.which_of_these_products_does_your_client_build_?.includes('Single Family') && (!response.what_is_the_minimum_amount_of_single_family_lots_your_client_is_willing_to_purchase_per_subdivision || !response.what_is_the_maximum_amount_of_single_family_lots_your_client_is_willing_to_purchase_per_subdivision)) {
          message = 'Please select the minimum and maximum amount of single family lots'
        }
        if (response.which_of_these_products_do_you_build_?.includes('Townhomes') && (!response.what_is_the_minimum_amount_of_townhome_pads_your_client_is_willing_to_purchase_per_subdivision_at_o || !response.what_is_the_maximum_amount_of_townhome_pads_your_client_is_willing_to_purchase_per_subdivision_at_o)) {
          message = 'Please select the minimum and maximum amount of town home pads'
        }
        if (response.which_of_these_products_do_you_build_?.includes('Condos/Apartments') && (!response.what_is_the_maximum_amount_of_apartment_condo_units_your_client_would_be_willing_to_purchase_per_su || !response.what_is_the_minimum_amount_of_apartment_condo_units_your_client_would_be_willing_to_purchase_per_su)) {
          message = 'Please select the minimum and maximum amount of apartment/condo units'
        }

      }
      if (response?.clientType === 'Developer') {
        if (!response.client_utah_area_tags) {
          message = 'Utah area tags should not be empty'
        }
        if (!response.what_real_estate_product_does_your_client_develop_) {
          message = 'Please select the products your client develop'
        }
        if (response.what_real_estate_product_does_your_client_develop_?.includes('Single Family') && (!response.what_is_the_minimum_amount_of_single_family_lots_your_client_would_develop_per_subdivision_at_once_ || !response.what_is_the_maximum_amount_of_single_family_lots_your_client_is_willing_to_develop_per_subdivision_)) {
          message = 'Please select the minimum and maximum amount of single family lots'
        }
        if (response.what_real_estate_product_does_your_client_develop_?.includes('Townhomes') && (!response.what_is_the_maximum_amount_of_townhome_pads_your_client_is_willing_to_develop_per_subdivision_at_on || !response.what_is_the_minimum_amount_of_townhome_pads_your_client_is_willing_to_develop_per_subdivision_at_on)) {
          message = 'Please select the minimum and maximum amount of town home pads'
        }
        if (response.what_real_estate_product_does_your_client_develop_?.includes('Apartments/Condos') && (!response.what_is_the_maximum_amount_of_apartment_condo_units_your_client_would_be_willing_to_develop_per_sub || !response.what_is_the_minimum_amount_of_apartment_condo_units_your_client_would_be_willing_to_develop_per_sub)) {
          message = 'Please select the minimum and maximum amount of apartment/condo units'
        }
      }
    }
    if (message) {
      canProceed = false
      notification.warn({
        placement: 'topRight',
        message
      })
    }
    return canProceed
  }
  const [finalResponse, setFinalResponse] = useState<any>()
  const updateSurveyResponse = async () => {
    setIsRecalculating(false)
    const response: any = {
      email: formattedRespone[0].email,
      firstname: formattedRespone[0].firstname,
      lastname: formattedRespone[0].lastname,
      company: formattedRespone[0].company,
      clientCodeName: formattedRespone[0].clientCodeName,
      clientType: formattedRespone[0].clientType
    }
    const surveyData: any = {
      clientId: formattedRespone[0].clientId,
      contactId: formattedRespone[0].contactId,
      projectId: formattedRespone[0].projectId,
      id: formattedRespone[0].id,
      surveyName: formattedRespone[0].name,
    }
    formattedRespone.forEach((elem: any) => {
      const field = elem.questionProField
      response[field] = elem.joinFinalValue ? elem.value?.join(';') : elem.value
    })
    surveyData.response = response
    const canProceed =
      validateResponse(surveyData.surveyName, surveyData.response)
    if (!canProceed) { return }
    await updateSurveyResponseMutation(finalResponse).unwrap()
    setIsRecalculating(false)
    setShowSurveyCriteriaDrawer(false)
    refetchBuilderCountResults()
    refetchInvestorCountResults()
    refetchDeveloperCountResults()
    refetchNewBuildHomebuyerCountResults()
    refetchRealEstateAgentCountResults()
    refetchWaterRightsCountResults()
    if (surveyQueries) {
      refetchQueries()
    }
    // refetchCriterias()
    getCriteriaDataFromSurvey()
  }

  const updateRecalculateResponse = async () => {
    const response: any = {
      email: formattedRespone[0].email,
      firstname: formattedRespone[0].firstname,
      lastname: formattedRespone[0].lastname,
      company: formattedRespone[0].company,
      clientCodeName: formattedRespone[0].clientCodeName,
      clientType: formattedRespone[0].clientType
    }
    const surveyData: any = {
      clientId: formattedRespone[0].clientId,
      contactId: formattedRespone[0].contactId,
      projectId: formattedRespone[0].projectId,
      id: formattedRespone[0].id,
      surveyName: formattedRespone[0].name,
    }
    formattedRespone.forEach((elem: any) => {
      const field = elem.questionProField
      response[field] = elem.joinFinalValue ? elem.value?.join(';') : elem.value
    })
    surveyData.response = response
    const canProceed =
      validateResponse(surveyData.surveyName, surveyData.response)
    if (!canProceed) { return }
    setFinalResponse(surveyData)
    await updateRecalculateResponseMutation(surveyData).unwrap()
    setIsRecalculating(true)
    setShowSurveyCriteriaDrawer(false)

    refetchBuilderCountResults()
    refetchInvestorCountResults()
    refetchDeveloperCountResults()
    refetchNewBuildHomebuyerCountResults()
    refetchRealEstateAgentCountResults()
    refetchWaterRightsCountResults()
    if (surveyQueries) {
      refetchQueries()
    }
    // refetchCriterias()
    getCriteriaDataFromSurvey()
    setCanUpdate(true)
  }

  const emailFromUrl = useMemo(() =>
    qs.parse(search).surveyTaker as string, [search]);

  useEffect(() => {
    if (emailFromUrl) {
      setInitialLoader(true)
      setTimeout(() => {
        setSurveyTakerFromSurvey(emailFromUrl)
      }, 5000)
    }
  }, [emailFromUrl])



  const { push } = useHistory();

  const loginUser =
    localStorage.getItem('user') &&
    JSON.parse(localStorage.getItem('user') as string);

  useEffect(() => {
    loginUser.role === 'registered' && push({ pathname: '/home' });
  }, [loginUser.role, push]);

  useEffect(() => {
    if (
      builderCount ||
      investorCount ||
      developerCount ||
      realEstateAgentCount ||
      newBuildHomebuyerCount ||
      waterRightsCount
    ) {
      setInitialLoader(false);
    } else if (surveyid) {
      setInitialLoader(true);
    }
    if (!isBuilderCountFetching && !isInvestorCountFetching
      && !isDeveloperCountFetching
      && !isNewBuildHomebuyerCountFetching && !isRealEstateAgentCountFetching
      && !isWaterRightsCountFetching) {
      setInitialLoader(false);
    }
  }, [
    builderCount,
    investorCount,
    developerCount,
    realEstateAgentCount,
    newBuildHomebuyerCount,
    waterRightsCount,
    surveyid,
    isBuilderCountFetching,
    isInvestorCountFetching,
    isDeveloperCountFetching,
    isNewBuildHomebuyerCountFetching,
    isRealEstateAgentCountFetching,
    isWaterRightsCountFetching
  ]);

  useEffect(() => {
    // if (surveyid) {
    //   setInitialLoader(true);
    // }
  }, [surveyid]);

  // const getCount = (survey: any, type: string) => {
  //   if (survey && survey.length > 0) {
  //     const surveyData = survey.filter(
  //       (src: SurveysDataResponse) => src.matchingtype === type,
  //     );
  //     return surveyData.length > 0 ? surveyData[0].count : 0;
  //   } else {
  //     return 0;
  //   }
  // };
  // const getSurveyDetails = (survey: any, type: string) => {
  //   if (survey && survey.length > 0) {
  //     const surveyData = survey.filter(
  //       (src: SurveysDataResponse) => src.matchingtype === type,
  //     );
  //     return surveyData.length > 0 ? surveyData[0][type] : [];
  //   } else {
  //     return [];
  //   }
  // };

  const setSurveyCriterias = () => {
    if (surveyCriterias && surveyCriterias.length) {
      const surveyNameObj: any = surveyCriterias.find((elem: any) => elem.criteria === 'surveyname')
      let obj
      if (surveyNameObj?.value === 'Builder Company Survey') {
        let utahAreaTags = surveyCriterias.find((elem: any) => elem.criteria === 'utahAreaTags')?.value?.replace(/{|}|'|"/g, '')
        let numberAndStyleOfLotsPurchased = surveyCriterias.find((elem: any) => elem.criteria === 'numberAndStyleOfLotsPurchased')?.value?.replace(/{|}|'|"/g, '')
        obj = {
          numberAndStyleOfLotsPurchased: numberAndStyleOfLotsPurchased?.split(','),
          utahAreaTags: utahAreaTags?.split(',')
        }
      }
      if (surveyNameObj?.value === 'Investor Survey') {
        let utahAreaTags = surveyCriterias.find((elem: any) => elem.criteria === 'utahAreaInvestmentTags')?.value?.replace(/{|}|'|"/g, '')
        obj = {
          utahAreaTags: utahAreaTags?.split(',')
        }
      }
      if (surveyNameObj?.value === 'Developer Company Survey') {
        let utahAreaTags = surveyCriterias.find((elem: any) => elem.criteria === 'utahAreaTags')?.value?.replace(/{|}|'|"/g, '')
        let developerTags = surveyCriterias.find((elem: any) => elem.criteria === 'developerTags')?.value?.replace(/{|}|'|"/g, '')
        obj = {
          developerTags: developerTags?.split(','),
          utahAreaTags: utahAreaTags?.split(',')
        }
      }
      if (surveyNameObj?.value === 'Land Owner Survey') {
        let propertyLocatedInCity = surveyCriterias.find((elem: any) => elem.criteria === 'propertyLocatedInCity')?.value
        let utahAreaTags = surveyCriterias.find((elem: any) => elem.criteria === 'utahAreaTags')?.value?.replace(/{|}|'|"/g, '')
        obj = {
          propertyLocatedInCity: propertyLocatedInCity?.split(','),
          utahAreaTags: utahAreaTags?.split(',')
        }
      }
      if (surveyNameObj?.value === 'New Build Home Buyer Survey') {
        let newBuildHomeBuyerDesiredCities = surveyCriterias.find((elem: any) => elem.criteria === 'newBuildHomeBuyerDesiredCities')?.value || surveyCriterias.find((elem: any) => elem.criteria === 'new_build_home_buyer_desired_cities')?.value
        let utahAreaTags = surveyCriterias.find((elem: any) => elem.criteria === 'utahAreaTags')?.value?.replace(/{|}|'|"/g, '')
        obj = {
          newBuildHomeBuyerDesiredCities: newBuildHomeBuyerDesiredCities,
          utahAreaTags: utahAreaTags?.split(',') || []
        }
      }
      if (surveyNameObj?.value === 'I Have a Project Survey') {
        let projectStatus = surveyCriterias.find((elem: any) => elem.criteria === 'tempProjectDevelopmentStage')?.value
        let city = surveyCriterias.find((elem: any) => elem.criteria === 'tempCity')?.value
        let numberAndStyleOfLotsPurchased = surveyCriterias.find((elem: any) => elem.criteria === 'numberAndStyleOfLots')?.value || surveyCriterias.find((elem: any) => elem.criteria === 'desiredNumberAndStyleOfLots')?.value
        let utahAreaTags = surveyCriterias.find((elem: any) => elem.criteria === 'utahAreaTags')?.value?.replace(/{|}|'|"/g, '')
        let specialProductTypes = surveyCriterias.find((elem: any) => elem.criteria === 'specialProductTypes')?.value || surveyCriterias.find((elem: any) => elem.criteria === 'desiredSpecialProductTypes')?.value
        let lotSize = surveyCriterias.find((elem: any) => elem.criteria === 'lotSize')?.value || surveyCriterias.find((elem: any) => elem.criteria === 'desiredLotSizes')?.value

        obj = {
          projectStatus,
          city,
          numberAndStyleOfLotsPurchased,
          utahAreaTags,
          specialProductTypes,
          lotSize
        }
      }
      if (surveyNameObj?.value === 'Real Estate Agent Client Survey') {
        let utahAreaTags = surveyCriterias.find((elem: any) => elem.criteria === 'utahAreaTags')?.value?.split(';') || []
        let clientPropertyLocatedInCity = surveyCriterias.find((elem: any) => elem.criteria === 'clientPropertyLocatedInCity')?.value || surveyCriterias.find((elem: any) => elem.criteria === 'client_property_located_in_city')?.value
        let clientUtahAreaInvestmentTags = surveyCriterias.find((elem: any) => elem.criteria === 'clientUtahAreaInvestmentTags')?.value?.split(';') || []
        obj = {
          utahAreaTags: [...utahAreaTags, ...clientUtahAreaInvestmentTags],
          clientPropertyLocatedInCity
        }
      }
      if (surveyNameObj?.value === 'Builder Client Survey') {
        let city = surveyCriterias.find((elem: any) => elem.criteria === 'clientPurchaseCity')?.value
        obj = {
          city
        }
      }
      if (surveyNameObj?.value === 'Real Estate Agent Survey') {
        let utahAreaTags = surveyCriterias.find((elem: any) => elem.criteria === 'utahAreaTags')?.value?.replace(/{|}|'|"/g, '')
        obj = {
          utahAreaTags
        }
      }
      if (surveyNameObj?.value === 'Water Right Survey') {
        let tempWaterRightUtahAreaTags = surveyCriterias.find((elem: any) => elem.criteria === 'tempWaterRightUtahAreaTags')?.value
        let tempCityOfWaterRight = surveyCriterias.find((elem: any) => elem.criteria === 'tempCityOfWaterRight')?.value
        obj = {
          tempWaterRightUtahAreaTags,
          tempCityOfWaterRight
        }
      }
      localStorage.setItem('profileCriterias', JSON.stringify(obj))
      localStorage.setItem('isSwitchProfileOn', 'true')
      localStorage.setItem('surveyName', surveyNameObj.value)
      localStorage.setItem('surveyEmail', surveyNameObj.email)
      localStorage.setItem('surveyId', surveyNameObj.surveyid)
      localStorage.setItem('surveyMessage', `Current Profile - ${surveyNameObj.value}`)
      window.dispatchEvent(new Event("storage"));
    }
  }

  const addToFailedNotifications = (type: String) => {
    if (!failedQueries.includes(type)) {
      let failedQueriesArray = failedQueries;
      failedQueriesArray.push(type)

      notification.warn({
        placement: 'topRight',
        message: 'No matches found',
        description: `No matches found for ${failedQueriesArray.join(', ')}`,
        key: 'failedQueries'
      })
      setFailedQueries(failedQueriesArray);
    }
  }

  useEffect(() => {
    setSurveyCriterias()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyCriterias])

  useEffect(() => {
    if (
      typeof surveyContacts !== 'undefined' &&
      surveyContacts?.length > 0 &&
      typeof surveyContacts[0].surveyid !== 'undefined'
    ) {
      if (qs.parse(search).surveyId) {
        setSurveyid(qs.parse(search).surveyId as string)
        localStorage.setItem('surveyId', qs.parse(search).surveyId as string)
      } else if (surveyTakerFromSurvey) {
        let surveyTakerRecord = surveyContacts.find((elem: any) =>
          elem.value === surveyTakerFromSurvey)
        if (surveyTakerRecord) {
          setSurveyid(surveyTakerRecord?.surveyid)
        }
      } else {
        setSurveyid(surveyContacts[0].surveyid);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyContacts]);

  const onSetSurveyid = (id: any) => {
    localStorage.setItem('surveyId', id)
    setSurveyid(id);
  };

  useEffect(() => {
    if (localStorage.getItem('isSwitchProfileOn') === 'true' || localStorage.getItem('surveyId')) {
      const surveyId: any = localStorage.getItem('surveyId')
      setSurveyid(surveyId)
    } else if (!emailFromUrl) {
      notification.warning({
        placement: 'topRight',
        message: 'Select a survey',
        description: 'Please select a survey taker to view matches',
      });
    }
  }, [emailFromUrl])

  useEffect(() => {
    if (!isBuilderCountFetching && builderCount && !initialLoader) {
      if (builderCount?.contactsCount || builderCount.companiesCount) {
        notification.success({
          placement: 'topRight',
          message: 'Matches Retrieved',
          description: 'The Builder matches are retrieved successfully'
        })
      } else {
        addToFailedNotifications('builders')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [builderCount, isBuilderCountFetching, initialLoader])

  useEffect(() => {
    if (!isInvestorCountFetching && investorCount && !initialLoader) {
      if (investorCount?.contactsCount || investorCount.companiesCount) {
        notification.success({
          placement: 'topRight',
          message: 'Investor Retrieved',
          description: 'The Investor matches are retrieved successfully'
        })
      } else {
        addToFailedNotifications('investors')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investorCount, isInvestorCountFetching, initialLoader])

  useEffect(() => {
    if (!isDeveloperCountFetching && developerCount && !initialLoader) {
      if (developerCount?.contactsCount || developerCount.companiesCount) {
        notification.success({
          placement: 'topRight',
          message: 'Developer Retrieved',
          description: 'The Developer matches are retrieved successfully'
        })
      } else {
        addToFailedNotifications('developers')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [developerCount, isDeveloperCountFetching, initialLoader])

  useEffect(() => {
    if (!isRealEstateAgentCountFetching
      && realEstateAgentCount && !initialLoader) {
      if (realEstateAgentCount?.contactsCount
        || realEstateAgentCount.companiesCount) {
        notification.success({
          placement: 'topRight',
          message: 'Real estate agent Retrieved',
          description: 'The Real estate agent matches are retrieved successfully'
        })
      } else {
        addToFailedNotifications('real estate agents')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realEstateAgentCount, isRealEstateAgentCountFetching, initialLoader])

  useEffect(() => {
    if (!isNewBuildHomebuyerCountFetching && newBuildHomebuyerCount
      && !initialLoader) {
      if (newBuildHomebuyerCount?.contactsCount ||
        newBuildHomebuyerCount.companiesCount) {
        notification.success({
          placement: 'topRight',
          message: 'Home buyer retrieved',
          description: 'The Home buyer matches are retrieved successfully'
        })
      } else {
        addToFailedNotifications('home buyers')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newBuildHomebuyerCount, isNewBuildHomebuyerCountFetching, initialLoader])

  useEffect(() => {
    if (!isWaterRightsCountFetching && waterRightsCount && !initialLoader) {
      if (waterRightsCount?.waterRightsCount) {
        notification.success({
          placement: 'topRight',
          message: 'Water rights retrieved',
          description: 'The Water rights matches are retrieved successfully'
        })
      } else {
        addToFailedNotifications('water rights')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterRightsCount, isWaterRightsCountFetching, initialLoader])

  // useEffect(() => {
  //   if (!isProjectsCountFetching && projectsCount && !initialLoader) {
  //     if (projectsCount?.projectsCount) {
  //       notification.success({
  //         placement: 'topRight',
  //         message: 'Project matches retrieved successfully'
  //       })
  //     } else {
  //       addToFailedNotifications('projects')
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [projectsCount, isProjectsCountFetching, initialLoader])

  useEffect(() => {
    window.addEventListener('storage', () => {
      const surveyId: any = localStorage.getItem('surveyId')
      setSurveyid(surveyId)
    })
  }, [])

  useEffect(() => {
    if (!localStorage.getItem('isSwitchProfileOn')) {
      localStorage.removeItem('surveyId')
      // localStorage.removeItem('surveyEmail')
    }
  }, [])


  const Tab = ({ property }: any) => (
    <div className="tab-title">
      <div className="name">
        <span>{property}</span>
      </div>
    </div>
  );

  const numberWithCommas = (number: number) => {
    return new Intl.NumberFormat().format(number);
  };

  return (
    <SiteLayout menuShow={true} onSurveyTakerChange={(value) => {
      if (localStorage.getItem('surveyId')) {
        onSetSurveyid(localStorage.getItem('surveyId'))
      } else {
        value?.length && setSurveyTakerFromSurvey(value[0])
        value?.length && localStorage.setItem('surveyEmail', value)
        value?.length && localStorage.setItem('isSwitchProfileOn', 'true')
        window.dispatchEvent(new Event("storage"));
      }
    }}>
      <Navbar
        showCriterias={surveyid &&
          !showSurveyCriteriaDrawer ? true : false}
        onViewCriteriaClick={() => setShowSurveyCriteriaDrawer(true)} />
      {/* {surveyid && !showSurveyCriteriaDrawer &&
        <Button onClick={() => setShowSurveyCriteriaDrawer(true)} style={{ float: 'right', margin: 10, zIndex: 99 }}>
          View criterias</Button>} */}
      <Drawer
        placement="right"
        title={formattedRespone?.length ? formattedRespone[0].name : ''}
        size="large"
        width={'40%'}
        zIndex={4}
        open={showSurveyCriteriaDrawer}
        onClose={() => setShowSurveyCriteriaDrawer(false)}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spin
            spinning={isSurveyDetailsLoading}
            tip='Loading' size="large"
          ></Spin>
        </div>
        {formattedRespone?.length ?
          formattedRespone.map((elem: any, index: any) => (
            !elem.hide ?
              <div key={elem.criteria}
                style={{ marginBottom: 30 }}>
                <h3 style={{ fontWeight: 'bold' }}>{elem.label} {elem.required ? <span style={{ color: 'red' }}>*</span> : null}</h3>
                {elem?.type === 'multi_select' ? <Checkbox.Group onChange={(e) => onChangeResponse(e, index)} options={elem.options} defaultValue={elem.value} />
                  : null}
                {elem.type === 'select' ?
                  <>
                    {/* <p>{elem.value}</p>
                  <p>{elem.options[0]}</p>
                  <p>tr {(elem.options[0] == elem.value).toString()}</p> */}
                    <Radio.Group onChange={(e) =>
                      onChangeResponse(e.target.value, index)
                    }
                      defaultValue={elem.value}>
                      {elem.options?.map((item: any) => (
                        <Radio key={item} value={item}>{item}</Radio>
                      ))}
                    </Radio.Group>
                  </>
                  : null}
                {elem.type === 'input' ?
                  <Input defaultValue={elem.value}
                    onChange={(e) => onChangeResponse(e.target.value, index)} />
                  : null}

                {elem.type === 'search_select' ?
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) => (option?.label ?? '')?.toLowerCase().includes(input.toLowerCase())}
                    options={elem.options}
                    defaultValue={elem.value}
                    onChange={(e) => onChangeResponse(e, index)}
                  />
                  : null}
              </div> : null
          )) : null
        }
        {!isSurveyDetailsLoading &&
          <Button onClick={() => {
            setGetQueries(true)
            setShowSurveyQueries(true)
          }}>View </Button>
        }
        {!isSurveyDetailsLoading &&
          <Button style={{ marginLeft: '5px' }} onClick={() => {
            updateRecalculateResponse()
          }}>Recalculate Survey</Button>
        }
        {!isSurveyDetailsLoading &&
          <Button style={{ marginLeft: '5px' }} onClick={() => {
            updateSurveyResponse()
          }} disabled={!canUpdate}>Update Survey</Button>
        }


      </Drawer>

      <Drawer
        title={formattedRespone?.length ? formattedRespone[0].name : ''}
        placement="right"
        size="large"
        width={'80%'}
        zIndex={4}
        open={showSurveyQueries}
        onClose={() => setShowSurveyQueries(false)}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spin
            spinning={isSurveyQueriesLoading}
            tip='Loading' size="large"
          ></Spin>
        </div>
        {!isSurveyQueriesLoading &&
          <Tabs>
            {
              Object.entries(queries)?.map(([key, value]: any) => (
                <TabPane
                  tab={
                    <Tab
                      property={`${key} (${value[0]?.totalCount})`}
                    />
                  }
                  key={key}
                >
                  <Tabs type='card'>
                    {
                      value?.length && value?.map((elem: any) => (
                        <TabPane
                          tab={
                            <Tab
                              property={`${elem.title} (${elem?.count})`}
                            />
                          }
                          key={elem.title + elem.count}
                        >
                          <CopyBlock
                            language="sql"
                            text={elem?.query}
                            wrapLines
                            theme={dracula}
                          />
                          {elem?.columns?.length &&
                            <div style={{ marginTop: 25 }}>
                              <Card title="Columns used in query" style={{ width: 500 }}>
                                <ul>
                                  {elem.columns.map((item: any) =>
                                    <li style={{ marginBottom: 10 }}
                                      key={item}>{item}</li>)}
                                </ul>
                              </Card>
                            </div>
                          }
                        </TabPane>
                      ))
                    }
                  </Tabs>
                </TabPane>
              ))}
          </Tabs>
        }
      </Drawer>
      <Spin
        spinning={initialLoader}
        tip='Loading' size="large"
      >
        <div className="network-area">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            {
              isBuilderCountFetching || (!isBuilderCountFetching &&
                (builderCount?.contactsCount || builderCount?.companiesCount))
                || !surveyid ? (
                <Col className="gutter-row" span={8}>
                  <TooltipComponent
                    title='Builders'
                    placement='top'
                    body={<div
                      className="matching-type"
                      style={{ backgroundColor: 'rgb(202,178,159)' }}
                    >
                      <Link
                        to="#"
                        onClick={() => {
                          setVisibleModel(true);
                          setMatchingtype('builders');
                          setTitle(`Builders (${numberWithCommas(isBuilderCount ? Number(builderCount?.contactsCount) + Number(builderCount?.companiesCount) : 0)})`);
                          setColor('rgb(202,178,159)');
                          setSelectedType({
                            isSurvey: isBuilderCount,
                            surveyResult: builderCount,
                          });
                        }}
                        className="type-color"
                      >
                        Builders
                        ({numberWithCommas(isBuilderCount ?
                          Number(builderCount?.contactsCount) +
                          Number(builderCount?.companiesCount) : 0)})
                      </Link>
                    </div>}
                  />
                  {isBuilderCountFetching ?
                    <div className="skeleton-div">
                      <Skeleton height={42} count={1} />
                      <Skeleton height={160} count={2} />
                    </div> : isBuilderCount && (selectedCountCard !== 'builderContact' && selectedCountCard !== 'buiderCompany') ?
                      <>
                        {isBuilderCount &&
                          (builderCount?.contactsCount
                            || builderCount?.companiesCount) ?
                          <div
                            onClick={() => builderCount?.contactsCount && setSelectedCountCard('builderContact')}
                            className="matching-count-circle-div"
                            style={{ color: 'rgb(202,178,159)' }}
                          >
                            <div className='count-circle-one' style={{ border: '20px solid rgb(202,178,159)' }}>
                              <CountUp
                                end={builderCount?.contactsCount}
                              /> </div>
                            <div className='count-text'>
                              {builderCount?.contactsCount} contacts matched
                            </div>
                          </div> : null}
                        {isBuilderCount &&
                          (builderCount?.contactsCount
                            || builderCount?.companiesCount) ?
                          <div
                            onClick={() => builderCount?.companiesCount && setSelectedCountCard('buiderCompany')}
                            className="matching-count-circle-div"
                            style={{ color: 'rgb(202,178,159)' }}
                          >
                            <div className='count-circle-one' style={{ border: '20px solid rgb(202,178,159)' }}>
                              <CountUp
                                end={builderCount?.companiesCount}
                              /> </div>
                            <div className='count-text'>
                              {builderCount?.companiesCount} companies matched
                            </div>
                          </div> : null}
                      </> : selectedCountCard ?
                        <div>
                          {!isBuilderCountFetching && selectedCountCard === 'builderContact' &&
                            <div className="matching-count">
                              <div className='matching-close-button'>
                                <Button
                                  type="text"
                                  style={{ color: 'rgb(202,178,159)' }}
                                  icon={<LeftOutlined />}
                                  onClick={() => setSelectedCountCard(null)}
                                >
                                  Contacts
                                </Button>
                              </div>
                              <div>
                                <CountUp
                                  end={builderCount?.contactsCount}
                                />
                              </div>
                              <Item
                                isRecalculating={isRecalculating}
                                count={
                                  builderCount?.contactsCount
                                }
                                surveyid={surveyid}
                                matchingtype={'builders'}
                                selectedCountCard="contacts"
                              />
                            </div>}
                          {selectedCountCard === 'buiderCompany' &&
                            <div className="matching-count">
                              <div className='matching-close-button'>
                                <Button
                                  type="text"
                                  style={{ color: 'rgb(202,178,159)' }}
                                  icon={<LeftOutlined />}
                                  onClick={() => setSelectedCountCard(null)}
                                >
                                  Companies
                                </Button>
                              </div>
                              <div>
                                <CountUp
                                  end={builderCount?.companiesCount}
                                />
                              </div>
                              <Item
                                isRecalculating={isRecalculating}
                                count={
                                  builderCount?.companiesCount
                                }
                                surveyid={surveyid}
                                matchingtype={'builders'}
                                selectedCountCard="companies"
                              />
                            </div>}
                        </div> :
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  }
                </Col>
              ) : null
            }

            {
              isInvestorCountFetching || (!isInvestorCountFetching &&
                (investorCount?.contactsCount || investorCount?.companiesCount))
                || !surveyid ? (
                <Col className="gutter-row" span={8}>
                  <TooltipComponent
                    title='Investors'
                    placement='top'
                    body={<div
                      className="matching-type"
                      style={{ backgroundColor: 'rgb(141,196,148)' }}
                    >
                      <Link
                        to="#"
                        onClick={() => {
                          setVisibleModel(true);
                          setMatchingtype('investors');
                          setTitle(`Investors (${numberWithCommas(isInvestorCount ? Number(investorCount?.contactsCount) + Number(investorCount?.companiesCount) : 0)})`);
                          setColor('rgb(141,196,148)');
                          setSelectedType({
                            isSurvey: isInvestorCount,
                            surveyResult: investorCount,
                          });
                        }}
                        className="type-color"
                      >
                        Investors
                        ({numberWithCommas(isInvestorCount ?
                          Number(investorCount?.contactsCount) +
                          Number(investorCount?.companiesCount) : 0)})
                      </Link>
                    </div>}
                  />
                  {isInvestorCountFetching ?
                    <div className="skeleton-div">
                      <Skeleton height={42} count={1} />
                      <Skeleton height={160} count={2} />
                    </div> : isInvestorCount && (selectedCountCard !== 'investorContact' && selectedCountCard !== 'investorCompany') ?
                      <>

                        {isInvestorCount &&
                          (investorCount?.contactsCount
                            || investorCount?.companiesCount) ?
                          <div
                            onClick={() => investorCount?.contactsCount && setSelectedCountCard('investorContact')}
                            className="matching-count-circle-div"
                            style={{ color: 'rgb(141,196,148)' }}
                          >
                            <div className='count-circle-one' style={{ border: '20px solid rgb(141,196,148)' }}>
                              <CountUp
                                end={investorCount?.contactsCount}
                              />
                            </div>
                            <div className='count-text'>
                              {investorCount?.contactsCount} contacts matched
                            </div>
                          </div> : null}
                        {isInvestorCount &&
                          (investorCount?.contactsCount
                            || investorCount?.companiesCount) ?
                          <div
                            onClick={() => investorCount?.companiesCount && setSelectedCountCard('investorCompany')}
                            className="matching-count-circle-div"
                            style={{ color: 'rgb(141,196,148)' }}
                          >
                            <div className='count-circle-one' style={{ border: '20px solid rgb(141,196,148)' }}>
                              <CountUp
                                end={investorCount?.companiesCount}
                              />
                            </div>
                            <div className='count-text'>
                              {investorCount?.companiesCount} companies matched
                            </div>
                          </div> : null}
                      </> : selectedCountCard ?
                        <>

                          {!isInvestorCountFetching && selectedCountCard === 'investorContact' &&
                            <div className="matching-count">
                              <div className='matching-close-button'>
                                <Button
                                  type="text"
                                  style={{ color: 'rgb(141,196,148)' }}
                                  icon={<LeftOutlined />}
                                  onClick={() => setSelectedCountCard(null)}
                                >
                                  Contacts
                                </Button>
                              </div>
                              <div>
                                <CountUp
                                  end={investorCount?.contactsCount}
                                />
                              </div>
                              <Item
                                isRecalculating={isRecalculating}
                                count={
                                  investorCount?.contactsCount
                                }
                                surveyid={surveyid}
                                matchingtype={'investors'}
                                selectedCountCard="contacts"
                              />
                            </div>}
                          {selectedCountCard === 'investorCompany' &&
                            <div className="matching-count">
                              <div className='matching-close-button'>
                                <Button
                                  type="text"
                                  style={{ color: 'rgb(141,196,148)' }}
                                  icon={<LeftOutlined />}
                                  onClick={() => setSelectedCountCard(null)}
                                >
                                  Companies
                                </Button>
                              </div>
                              <div>
                                <CountUp
                                  end={investorCount?.companiesCount}
                                />
                              </div>
                              <Item
                                isRecalculating={isRecalculating}
                                count={
                                  investorCount?.companiesCount
                                }
                                surveyid={surveyid}
                                matchingtype={'investors'}
                                selectedCountCard="companies"
                              />
                            </div>}
                        </> :
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  }
                </Col>
              ) : null
            }

            {
              isDeveloperCountFetching || (!isDeveloperCountFetching &&
                (developerCount?.contactsCount
                  || developerCount?.companiesCount))
                || !surveyid ? (
                <Col className="gutter-row" span={8}>
                  <TooltipComponent
                    title='Developers'
                    placement='top'
                    body={<div
                      className="matching-type"
                      style={{ backgroundColor: 'rgb(232,186,69)' }}
                    >
                      <Link
                        to="#"
                        onClick={() => {
                          setVisibleModel(true);
                          setMatchingtype('developers');
                          setTitle(`Developers (${numberWithCommas(isDeveloperCount ? Number(developerCount?.contactsCount) + Number(developerCount?.companiesCount) : 0)})`);
                          setColor('rgb(232,186,69)');
                          setSelectedType({
                            isSurvey: isDeveloperCount,
                            surveyResult: developerCount,
                          });
                        }}
                        className="type-color"
                      >
                        Developers
                        ({numberWithCommas(isDeveloperCount ?
                          Number(developerCount?.contactsCount) +
                          Number(developerCount?.companiesCount) : 0)})
                      </Link>
                    </div>}
                  />
                  {isDeveloperCountFetching ?
                    <div className="skeleton-div">
                      <Skeleton height={42} count={1} />
                      <Skeleton height={160} count={2} />
                    </div> : isDeveloperCount && (selectedCountCard !== 'developerContact' && selectedCountCard !== 'developerCompany') ?
                      <>

                        {isDeveloperCount &&
                          (developerCount?.contactsCount
                            || developerCount?.companiesCount) ?
                          <div
                            onClick={() => developerCount?.contactsCount && setSelectedCountCard('developerContact')}
                            className="matching-count-circle-div"
                            style={{ color: 'rgb(232,186,69)' }}
                          >
                            <div className='count-circle-one' style={{ border: '20px solid rgb(232,186,69)' }}>
                              <CountUp
                                end={developerCount?.contactsCount}
                              />
                            </div>
                            <div className='count-text'>
                              {developerCount?.contactsCount} contacts matched
                            </div>
                          </div> : null}
                        {isDeveloperCount &&
                          (developerCount?.contactsCouunt
                            || developerCount?.companiesCount) ?
                          <div
                            onClick={() => developerCount?.companiesCount && setSelectedCountCard('developerCompany')}
                            className="matching-count-circle-div"
                            style={{ color: 'rgb(232,186,69)' }}
                          >
                            <div className='count-circle-one' style={{ border: '20px solid rgb(232,186,69)' }}>
                              <CountUp
                                end={developerCount?.companiesCount}
                              />
                            </div>
                            <div className='count-text'>
                              {developerCount?.companiesCount} companies matched
                            </div>
                          </div> : null}
                      </> : selectedCountCard ?
                        <>
                          {!isDeveloperCountFetching && selectedCountCard === 'developerContact' &&
                            <div className="matching-count">
                              <div className='matching-close-button'>
                                <Button
                                  type="text"
                                  style={{ color: 'rgb(232,186,69)' }}
                                  icon={<LeftOutlined />}
                                  onClick={() => setSelectedCountCard(null)}
                                >
                                  Contacts
                                </Button>
                              </div>
                              <div>
                                <CountUp
                                  end={developerCount?.contactsCount}
                                />
                              </div>
                              <Item
                                isRecalculating={isRecalculating}
                                count={
                                  developerCount?.contactsCount
                                }
                                surveyid={surveyid}
                                matchingtype={'developers'}
                                selectedCountCard="contacts"
                              />
                            </div>}
                          {selectedCountCard === 'developerCompany' &&
                            <div className="matching-count">
                              <div className='matching-close-button'>
                                <Button
                                  type="text"
                                  style={{ color: 'rgb(232,186,69)' }}
                                  icon={<LeftOutlined />}
                                  onClick={() => setSelectedCountCard(null)}
                                >
                                  Companies
                                </Button>
                              </div>
                              <div>
                                <CountUp
                                  end={developerCount?.companiesCount}
                                />
                              </div>
                              <Item
                                isRecalculating={isRecalculating}
                                count={
                                  developerCount?.companiesCount
                                }
                                surveyid={surveyid}
                                matchingtype={'developers'}
                                selectedCountCard="companies"
                              />
                            </div>}
                        </> :
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  }
                </Col>
              ) : null
            }

            {
              isRealEstateAgentCountFetching ||
                (!isRealEstateAgentCountFetching &&
                  (realEstateAgentCount?.contactsCount
                    || realEstateAgentCount?.companiesCount))
                || !surveyid ? (
                <Col className="gutter-row" span={8}>
                  <TooltipComponent
                    title='Real Estate Agents'
                    placement='top'
                    body={<div
                      className="matching-type"
                      style={{ backgroundColor: 'rgb(124,67,67)' }}
                    >
                      <Link
                        to="#"
                        onClick={() => {
                          setVisibleModel(true);
                          setMatchingtype('realEstateAgents');
                          setTitle(`Real Estate Agents (${numberWithCommas(isRealEstateAgentCount ? Number(realEstateAgentCount?.contactsCount) + Number(realEstateAgentCount?.companiesCount) : 0)})`);
                          setColor('rgb(124,67,67)');
                          setSelectedType({
                            isSurvey: isRealEstateAgentCount,
                            surveyResult: realEstateAgentCount,
                          });
                        }}
                        className="type-color"
                      >
                        Real Estate Agents
                        ({numberWithCommas(isRealEstateAgentCount ?
                          Number(realEstateAgentCount?.contactsCount) +
                          Number(realEstateAgentCount?.companiesCount) : 0)})
                      </Link>
                    </div>}
                  />
                  {isRealEstateAgentCountFetching ?
                    <div className="skeleton-div">
                      <Skeleton height={42} count={1} />
                      <Skeleton height={160} count={2} />
                    </div> : isRealEstateAgentCount && (selectedCountCard !== 'realEstateAgentContact' && selectedCountCard !== 'realEstateAgentCompany') ?
                      <>

                        {isRealEstateAgentCount &&
                          (realEstateAgentCount?.contactsCount
                            || realEstateAgentCount?.companiesCount) ?
                          <div
                            onClick={() => realEstateAgentCount?.contactsCount && setSelectedCountCard('realEstateAgentContact')}
                            className="matching-count-circle-div"
                            style={{ color: 'rgb(124,67,67)' }}
                          >
                            <div className='count-circle-one' style={{ border: '20px solid rgb(124,67,67)' }}>
                              <CountUp
                                end={realEstateAgentCount?.contactsCount}
                              />
                            </div>
                            <div className='count-text'>
                              {realEstateAgentCount?.
                                contactsCount} contacts matched
                            </div>
                          </div> : null}
                        {isRealEstateAgentCount &&
                          (realEstateAgentCount?.contactsCount
                            || realEstateAgentCount?.companiesCount) ?
                          <div
                            onClick={() => realEstateAgentCount?.companiesCount && setSelectedCountCard('realEstateAgentCompany')}
                            className="matching-count-circle-div"
                            style={{ color: 'rgb(124,67,67)' }}
                          >
                            <div className='count-circle-one' style={{ border: '20px solid rgb(124,67,67)' }}>
                              <CountUp
                                end={realEstateAgentCount?.companiesCount}
                              />
                            </div>
                            <div className='count-text'>
                              {realEstateAgentCount?.
                                companiesCount} companies matched
                            </div>
                          </div> : null}
                      </> : selectedCountCard ?
                        <>
                          {!isRealEstateAgentCountFetching && selectedCountCard === 'realEstateAgentContact' &&
                            <div className="matching-count">
                              <div className='matching-close-button'>
                                <Button
                                  type="text"
                                  style={{ color: 'rgb(124,67,67)' }}
                                  icon={<LeftOutlined />}
                                  onClick={() => setSelectedCountCard(null)}
                                >
                                  Contacts
                                </Button>
                              </div>
                              <div>
                                <CountUp
                                  end={realEstateAgentCount?.contactsCount}
                                />
                              </div>
                              <Item
                                isRecalculating={isRecalculating}
                                count={
                                  realEstateAgentCount?.contactsCount
                                }
                                surveyid={surveyid}
                                matchingtype={'realEstateAgents'}
                                selectedCountCard="contacts"
                              />
                            </div>}
                          {selectedCountCard === 'realEstateAgentCompany' &&
                            <div className="matching-count">
                              <div className='matching-close-button'>
                                <Button
                                  type="text"
                                  style={{ color: 'rgb(124,67,67)' }}
                                  icon={<LeftOutlined />}
                                  onClick={() => setSelectedCountCard(null)}
                                >
                                  Companies
                                </Button>
                              </div>
                              <div>
                                <CountUp
                                  end={realEstateAgentCount?.companiesCount}
                                />
                              </div>
                              <Item
                                isRecalculating={isRecalculating}
                                count={
                                  realEstateAgentCount?.companiesCount
                                }
                                surveyid={surveyid}
                                matchingtype={'realEstateAgents'}
                                selectedCountCard="companies"
                              />
                            </div>}
                        </> :
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  }
                </Col>
              ) : null
            }

            {
              isNewBuildHomebuyerCountFetching ||
                (!isNewBuildHomebuyerCountFetching &&
                  (newBuildHomebuyerCount?.contactsCount
                    || newBuildHomebuyerCount?.companiesCount))
                || !surveyid ? (
                <Col className="gutter-row" span={8}>
                  <TooltipComponent
                    title='Home Buyer'
                    placement='top'
                    body={<div
                      className="matching-type"
                      style={{ backgroundColor: 'rgb(28,122,146)' }}
                    >
                      <Link
                        to="#"
                        onClick={() => {
                          setVisibleModel(true);
                          setMatchingtype('newBuildHomebuyers');
                          setTitle(`Home Buyer (${numberWithCommas(isNewBuildHomebuyerCount ? Number(newBuildHomebuyerCount?.contactsCount) + Number(newBuildHomebuyerCount?.companiesCount) : 0)})`);
                          setColor('rgb(28,122,146)');
                          setSelectedType({
                            isSurvey: isNewBuildHomebuyerCount,
                            surveyResult: newBuildHomebuyerCount,
                          });
                        }}
                        className="type-color"
                      >
                        Home Buyer
                        ({numberWithCommas(isNewBuildHomebuyerCount ?
                          Number(newBuildHomebuyerCount?.contactsCount) +
                          Number(newBuildHomebuyerCount?.companiesCount) : 0)})
                      </Link>
                    </div>}
                  />
                  {isNewBuildHomebuyerCountFetching ?
                    <div className="skeleton-div">
                      <Skeleton height={42} count={1} />
                      <Skeleton height={160} count={2} />
                    </div> : isNewBuildHomebuyerCount && (selectedCountCard !== 'newBuildHomebuyerContact' && selectedCountCard !== 'newBuildHomebuyerCompany') ?
                      <>

                        {isNewBuildHomebuyerCount &&
                          (newBuildHomebuyerCount?.contactsCount
                            || newBuildHomebuyerCount?.companiesCount) ?
                          <div
                            onClick={() => newBuildHomebuyerCount?.contactsCount && setSelectedCountCard('newBuildHomebuyerContact')}
                            className="matching-count-circle-div"
                            style={{ color: 'rgb(28,122,146)' }}
                          >
                            <div className='count-circle-one' style={{ border: '20px solid rgb(28,122,146)' }}>
                              <CountUp
                                end={newBuildHomebuyerCount?.contactsCount}
                              />
                            </div>
                            <div className='count-text'>
                              {newBuildHomebuyerCount?.
                                contactsCount} contacts matched
                            </div>
                          </div> : null}
                        {isNewBuildHomebuyerCount &&
                          (newBuildHomebuyerCount?.contactsCount
                            || newBuildHomebuyerCount?.companiesCount) ?
                          <div
                            onClick={() => newBuildHomebuyerCount?.companiesCount && setSelectedCountCard('newBuildHomebuyerCompany')}
                            className="matching-count-circle-div"
                            style={{ color: 'rgb(28,122,146)' }}
                          >
                            <div className='count-circle-one' style={{ border: '20px solid rgb(28,122,146)' }}>
                              <CountUp
                                end={newBuildHomebuyerCount?.companiesCount}
                              />
                            </div>
                            <div className='count-text'>
                              {newBuildHomebuyerCount?.
                                companiesCount} companies matched
                            </div>
                          </div> : null}
                      </> : selectedCountCard ?
                        <>
                          {!isNewBuildHomebuyerCountFetching && selectedCountCard === 'newBuildHomebuyerContact' &&
                            <div className="matching-count">
                              <div className='matching-close-button'>
                                <Button
                                  type="text"
                                  style={{ color: 'rgb(28,122,146)' }}
                                  icon={<LeftOutlined />}
                                  onClick={() => setSelectedCountCard(null)}
                                >
                                  Contacts
                                </Button>
                              </div>
                              <div>
                                <CountUp
                                  end={newBuildHomebuyerCount?.contactsCount}
                                />
                              </div>
                              <Item
                                isRecalculating={isRecalculating}
                                count={
                                  newBuildHomebuyerCount?.contactsCount
                                }
                                surveyid={surveyid}
                                matchingtype={'newBuildHomebuyers'}
                                selectedCountCard="contacts"
                              />
                            </div>}
                          {selectedCountCard === 'newBuildHomebuyerCompany' &&
                            <div className="matching-count">
                              <div className='matching-close-button'>
                                <Button
                                  type="text"
                                  style={{ color: 'rgb(28,122,146)' }}
                                  icon={<LeftOutlined />}
                                  onClick={() => setSelectedCountCard(null)}
                                >
                                  Companies
                                </Button>
                              </div>
                              <div>
                                <CountUp
                                  end={newBuildHomebuyerCount?.companiesCount}
                                />
                              </div>
                              <Item
                                isRecalculating={isRecalculating}
                                count={
                                  newBuildHomebuyerCount?.companiesCount
                                }
                                surveyid={surveyid}
                                matchingtype={'newBuildHomebuyers'}
                                selectedCountCard="companies"
                              />
                            </div>}
                        </> :
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  }
                </Col>
              ) : null
            }

            {
              isWaterRightsCountFetching || (!isWaterRightsCountFetching &&
                waterRightsCount?.waterRightsCount)
                || !surveyid ? (
                <Col className="gutter-row" span={8}>
                  <TooltipComponent
                    placement='top'
                    title='Water Rights'
                    body={<div
                      className="matching-type"
                      style={{ backgroundColor: 'rgb(2, 71, 77)' }}
                    >
                      <Link
                        to="#"
                        onClick={() => {
                          setVisibleModel(true);
                          setMatchingtype('waterRights');
                          setTitle(`Water Rights (${numberWithCommas(isWaterRightsCount ? Number(waterRightsCount?.waterRightsCount) : 0)})`);
                          setColor('rgb(2, 71, 77)');
                          setSelectedType({
                            isSurvey: isWaterRightsCount,
                            surveyResult: waterRightsCount,
                          });
                        }}
                        className="type-color"
                      >
                        Water Rights
                        ({numberWithCommas(isWaterRightsCount ?
                          Number(waterRightsCount?.waterRightsCount) : 0)})
                      </Link>
                    </div>}
                  />
                  {isWaterRightsCountFetching ?
                    <div className="skeleton-div">
                      <Skeleton height={42} count={1} />
                      <Skeleton height={160} count={2} />
                    </div> : isWaterRightsCount && (selectedCountCard !== 'waterRightsContact') ?
                      <>

                        {isWaterRightsCount &&
                          waterRightsCount?.waterRightsCount &&
                          <div
                            onClick={() => setSelectedCountCard('waterRightsContact')}
                            className="matching-count-circle-div"
                            style={{ color: 'rgb(2, 71, 77)' }}
                          >
                            <div className='count-circle-one' style={{ border: '20px solid rgb(2, 71, 77)', fontSize: '20px' }}>
                              <CountUp
                                end={waterRightsCount?.waterRightsCount}
                              />
                            </div>
                            <div className='count-text'>
                              {waterRightsCount?.
                                waterRightsCount} water rights matched
                            </div>
                          </div>}
                      </> : selectedCountCard ?
                        <>
                          {!isWaterRightsCountFetching && selectedCountCard === 'waterRightsContact' &&
                            <div className="matching-count">
                              <div className='matching-close-button'>
                                <Button
                                  type="text"
                                  style={{ color: 'rgb(2, 71, 77)' }}
                                  icon={<LeftOutlined />}
                                  onClick={() => setSelectedCountCard(null)}
                                >
                                  Water Rights
                                </Button>
                              </div>
                              <div>
                                <CountUp
                                  end={waterRightsCount?.waterRightsCount}
                                />
                              </div>
                              <Item
                                isRecalculating={isRecalculating}
                                count={
                                  waterRightsCount?.waterRightsCount
                                }
                                surveyid={surveyid}
                                matchingtype={'waterRights'}
                                selectedCountCard="contacts"
                              />
                            </div>}
                        </> :
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  }
                </Col>
              ) : null
            }

            {/* {
              isProjectsCountFetching || (!isProjectsCountFetching &&
                projectsCount?.projectsCount)
                || !surveyid ? (
                <Col className="gutter-row" span={8}>
                  <div
                    className="matching-type"
                    style={{ backgroundColor: 'rgb(116,97,96)' }}
                  >
                    <Link
                      to="#"
                      onClick={() => {
                        setVisibleModel(true);
                        setMatchingtype('projects');
                        setTitle('Projects');
                        setColor('rgb(116,97,96)');
                        setSelectedType({
                          isSurvey: isProjectsCount,
                          surveyResult: projectsCount,
                        });
                      }}
                      className="type-color"
                    >
                      Projects ({ isProjectsCount ? 
                                  Number(projectsCount?.projectsCount)) : '' })
                    </Link>
                  </div>
                  {isProjectsCountFetching ?
                    <div className="skeleton-div">
                      <Skeleton height={42} count={1} />
                      <Skeleton height={160} count={2} />
                    </div> : isProjectsCount && (selectedCountCard !== 'projectsData') ?
                      <>

                        {isProjectsCount &&
                          projectsCount?.projectsCount &&
                          <div
                            onClick={() => setSelectedCountCard('projectsData')}
                            className="matching-count-circle-div"
                            style={{ color: 'rgb(116,97,96)' }}
                          >
                            <div className='count-circle-one' style={{ border: '20px solid rgb(116,97,96)', fontSize: '20px' }}>
                              <CountUp
                                end={projectsCount?.projectsCount}
                              />
                            </div>
                            <div className='count-text'>
                              {projectsCount?.
                                projectsCount} projects matched
                            </div>
                          </div>}
                      </> : selectedCountCard ?
                        <>
                          {!isProjectsCountFetching && selectedCountCard === 'projectsData' &&
                            <div
                              className="matching-count"
                              style={{ color: 'rgb(116,97,96)' }}
                            >
                              <div className='matching-close-button'>
                                <CloseCircleFilled
                                  className='close-button-icon'
                                  onClick={() => setSelectedCountCard(null)} />
                              </div>
                              <div>
                                <CountUp
                                  end={projectsCount?.projectsCount}
                                />
                              </div>
                              <Item
                                isRecalculating={isRecalculating}
                                count={
                                  projectsCount?.projectsCount
                                }
                                surveyid={surveyid}
                                matchingtype={'projects'}
                                selectedCountCard="contacts"
                              />
                            </div>}
                        </> :
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  }
                </Col>
              ) : null
            } */}
          </Row>
        </div>
        {!isWaterRightsCountFetching && !waterRightsCount?.waterRightsCount
          && !isBuilderCountFetching && (!builderCount?.contactsCount
            && !builderCount?.companiesCount)
          && !isInvestorCountFetching && (!investorCount?.contactsCount
            && !investorCount?.companiesCount)
          && !isDeveloperCountFetching && (!developerCount?.contactsCount
            && !developerCount?.companiesCount)
          && !isRealEstateAgentCountFetching &&
          (!realEstateAgentCount?.contactsCount
            && !realEstateAgentCount?.companiesCount)
          && !isNewBuildHomebuyerCountFetching &&
          (!newBuildHomebuyerCount?.contactsCount
            && !newBuildHomebuyerCount?.companiesCount)
          ?
          <Empty
            style={{ marginTop: 200 }}
            className="matched-item-popover-no-results"
            description="No Matched Results"
          />
          : null
        }
        <ItemListModal
          visible={visibleModel}
          setVisible={setVisibleModel}
          setMatchingtype={setMatchingtype}
          surveyid={surveyid}
          matchingtype={matchingtype}
          count={selectedType.surveyResult}
          data={[]}
          title={title}
          color={color}
          initPage={1}
        />
      </Spin>
    </SiteLayout >
  );
};

export default Network;
