import { Row, Col } from "antd";
import Skeleton from 'react-loading-skeleton';
import { selectShowingTotalValue, useGetAllParcelsQuery, useGetAllParentProjectsQuery } from "app/services";
import { ResultCard, Color } from "components";
import { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Waypoint } from "react-waypoint";
import { ParcelInterface } from "types/parcels";
import { createMetaData } from "utils/metaDataHelpers";
import { useLocation } from "react-router-dom";
import { ProjectInterface } from "types/projects";

export interface ParcelProjectGridViewProps {
  setParcelsTotal: (total: number) => void;
  setProjectsTotal: (total: number) => void;
  closeViewAll: () => void;
}

const ResultsGrid = ({
  dataSource,
  hasMore,
  onWaypointEnter,
  closeViewAll,
}: {
  dataSource:
  | { type: string; item: ParcelInterface | ProjectInterface }[]
  | undefined;
  hasMore: boolean;
  onWaypointEnter: () => void;
  closeViewAll: () => void;
}) => {

  return (
    <>
      <Row
        style={{ marginBottom: 15 }}
        className="result-content"
        gutter={[
          { xs: 6, sm: 12, md: 16, lg: 24 },
          { xs: 6, sm: 12, md: 16, lg: 24 },
        ]}
      >
        {dataSource &&
          dataSource.map((result, i) => (
            <Col key={i}>
              {/* <Skeleton className="grid-skeleton" height={160} count={1} /> */}
              <ResultCard
                title={result?.type}
                allData={result}
                from='viewAll'
                titleColor={
                  result?.type === 'Parcel' ? Color.navy : Color.green
                }
                data={createMetaData(result)}
                closeViewAll={closeViewAll}
                id={result.item?.id}
                parcelId={result.item?.parcelId}
                id_serial={result.item?.id_serial}
                formattedParcelId={result.item?.formattedParcelId}
              />
              {hasMore && i === dataSource?.length - 1 && (
                <Waypoint
                  onEnter={(args) => {
                    onWaypointEnter();
                  }}
                />
              )}
            </Col>
          ))}
      </Row>
    </>
  );
};

export const ParcelProjectGridView = ({ setParcelsTotal, setProjectsTotal, closeViewAll }: ParcelProjectGridViewProps) => {
  const showingTotal: number | undefined = useSelector(selectShowingTotalValue);
  const { search } = useLocation();
  const LIMIT = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const paginatedQuerySearch = useMemo(
    () => `?page=${currentPage}&take=${LIMIT}&${search.substring(1)}&surveyTaker=${localStorage.getItem('surveyEmail')}`,
    [LIMIT, currentPage, search],
  );

  const [showingItems, setShowingItems] = useState<
    { type: string; item: ParcelInterface | ProjectInterface }[]
  >([]);

  const {
    data: parcelsData,
    isFetching: isFetchingParcels,
    isSuccess: isSuccessParcels,
  } = useGetAllParcelsQuery(paginatedQuerySearch, {
    refetchOnReconnect: true
  });

  const {
    data: projectsData,
    isFetching: isFetchingProjects,
    isSuccess: isSuccessProjects,
  } = useGetAllParentProjectsQuery(paginatedQuerySearch, {
    refetchOnReconnect: true
  });

  const listData = useMemo(() => {
    return [
      ...(parcelsData?.data.map((item) => ({ item, type: 'Parcel' })) || []),
      ...(projectsData?.map((item: any) => ({ item, type: 'Project' })) || []),
    ];
  }, [parcelsData, projectsData]);


  const totalPages = useMemo(() => {
    const parcelsTotal = (showingTotal && showingTotal / LIMIT) || 0;
    const projectsTotal = (showingTotal && showingTotal / LIMIT) || 0;
    return parcelsTotal + projectsTotal;
  }, [showingTotal, LIMIT]);

  useEffect(() => {
    if ((!isFetchingParcels || !isFetchingProjects) &&
      (isSuccessParcels || isSuccessProjects)) {
      // setShowingItems((pv) => [...new Set([...pv, ...(listData || [])])]);
      if(currentPage === 1) {
        const allShowingItems = [...(listData || [])];
        setShowingItems(allShowingItems)
      } else {
        const allShowingItems = [...new Set([...showingItems,
        ...(listData || [])])];
        setShowingItems(allShowingItems)
      }
      setParcelsTotal(parcelsData?.pagination?.totalItems || 0)
      setProjectsTotal(projectsData?.pagination?.totalItems || 0)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ 
    isFetchingParcels,
    isFetchingProjects,
    isSuccessParcels,
    isSuccessProjects,
    listData
  ]);

  return (
    <div>
      {currentPage === 1 && isFetchingParcels ? (
        <>
          <Row
            style={{ marginBottom: 15 }}
            className="result-content"
            gutter={[
              { xs: 6, sm: 12, md: 16, lg: 24 },
              { xs: 6, sm: 12, md: 16, lg: 24 },
            ]}
          >
            {Array(7)
              .fill('')
              .map(() => {
                return (
                  <Col key={1} style={{ flex: 1 }}>
                    <Skeleton
                      className="grid-skeleton"
                      height={160}
                      count={1}
                    />
                  </Col>
                );
              })}
          </Row>
          <Row
            style={{ marginBottom: 15 }}
            className="result-content"
            gutter={[
              { xs: 6, sm: 12, md: 16, lg: 24 },
              { xs: 6, sm: 12, md: 16, lg: 24 },
            ]}
          >
            {Array(7)
              .fill('')
              .map(() => {
                return (
                  <Col key={1} style={{ flex: 1 }}>
                    <Skeleton
                      className="grid-skeleton"
                      height={160}
                      count={1}
                    />
                  </Col>
                );
              })}
          </Row>
        </>
      ) : (
        <ResultsGrid
          dataSource={showingItems}
          hasMore={currentPage < totalPages}
          onWaypointEnter={() => setCurrentPage((page) => page + 1)}
          closeViewAll={closeViewAll}
        />
      )}              
      {isFetchingParcels ? (
        <>
          <Row
            style={{ marginBottom: 15 }}
            className="result-content"
            gutter={[
              { xs: 6, sm: 12, md: 16, lg: 24 },
              { xs: 6, sm: 12, md: 16, lg: 24 },
            ]}
          >
            {Array(7)
              .fill('')
              .map(() => {
                return (
                  <Col key={1} style={{ flex: 1 }}>
                    <Skeleton
                      className="grid-skeleton"
                      height={160}
                      count={1}
                    />
                  </Col>
                );
              })}
          </Row>
          <Row
            style={{ marginBottom: 15 }}
            className="result-content"
            gutter={[
              { xs: 6, sm: 12, md: 16, lg: 24 },
              { xs: 6, sm: 12, md: 16, lg: 24 },
            ]}
          >
            {Array(7)
              .fill('')
              .map(() => {
                return (
                  <Col key={1} style={{ flex: 1 }}>
                    <Skeleton
                      className="grid-skeleton"
                      height={160}
                      count={1}
                    />
                  </Col>
                );
              })}
          </Row>
        </>
      ) : null}
    </div>
  )
}