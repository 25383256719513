import { Badge, Button, Col, Empty, Pagination, Row, Spin, Tabs } from 'antd';
import {
  selectCurrentItem,
  useGetAllSingleParentProjectMatchedDataQuery,
  useGetParentProjectInfoQuery,
  useGetAllParentProjectMatchingDataQuery,
  useGetAllParentProjectToCompanyMatchingDataQuery,
  setShowParcelsTypeValue,
  setProjectIdValue,
  setShowProjectsTypeValue,
  setCoordsPaginationValue,
  setZoomValue,
  setSelectedItemDetailsOnMap,
  selectSelectedProjectTypeValue,
  useGetAllParentProjectToChildProjectsMatchingDataQuery,
  selectSelectedItemDetailsOnMap,
  selectDrawerWidthValue,
  setDrawerWidthValue
} from 'app/services';
import { Color, ResultCard } from 'components';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import noImageAvailable from 'styles/images/noImageAvailable.png';
import { ContactInterface } from 'types/contacts';
import { ParcelInterface } from 'types/parcels';
import { createMetaData } from 'utils/metaDataHelpers';
import { ProjectInterface } from 'types/projects';
import { ParentProjectCard } from 'components/MetaData/ParentProjectCard';
import { ChildProjectCard } from 'components/MetaData/ChildProjectCard';
import TooltipComponent from 'components/Tooltip';
interface TabProps {
  property: string;
  total: any;
}

interface ViewAllMatchedProps {
  closeSelectedResultDrawer: () => void;
  isSelectedResultDrawerOpen: boolean;
  openViewMoreCompany: () => void;
}

const ResultsGrid = ({
  dataSource,
  currentPage,
  limit,
  totalItems,
  isLoading,
  setCurrentPage,
  setLimit,
  refetchParcels,
  closeDrawer,
}: {
  dataSource:
  | { type: string; item: ParcelInterface | ContactInterface }[]
  | undefined;
  currentPage: number;
  limit: number;
  totalItems: number;
  isLoading: any;
  setCurrentPage: (page: number) => void;
  setLimit: (page: number) => void;
  refetchParcels: () => void;
  closeDrawer?: () => void;
}) => {
  const drawerWidthValue: any = useSelector(selectDrawerWidthValue)
  const dispatch = useDispatch()
  useEffect(() => {
    setCurrentPage(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit])

  useEffect(() => {
    dispatch(setCoordsPaginationValue({ page: currentPage, limit }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  return (
    <div>
      <Row
        className="result-content"
        gutter={[
          { xs: 6, sm: 12, md: 16, lg: 24 },
          { xs: 6, sm: 12, md: 16, lg: 24 },
        ]}
      >
        {dataSource &&
          dataSource.map((result, i) => (
            <Col key={result.item.id}>
              <ResultCard
                showParcelsLink={true}
                refetchParcels={refetchParcels}
                from='matching'
                title={result?.type}
                titleColor={result?.type === 'Parcel' ? Color.navy : Color.green}
                data={createMetaData(result)}
                allData={result}
                id_serial={result?.item?.id_serial}
                closeDrawer={closeDrawer}
              />
            </Col>
          ))}
      </Row>
      <div className="pagination-container" style={{ width: drawerWidthValue }}>
        <Pagination
          current={currentPage}
          total={totalItems}
          pageSize={limit}
          showTotal={(total, range) => isLoading ? 'Loading...' : `${range[0]}-${range[1]} of ${total} items`}
          onShowSizeChange={(current, pageSize) => setLimit(pageSize)}
          onChange={(pg) => setCurrentPage(pg)} />
      </div>
    </div>
  );
};

export const SelectedProjectResultContent = ({
  isSelectedResultDrawerOpen,
  openViewMoreCompany,
  closeSelectedResultDrawer
}: ViewAllMatchedProps) => {
  const { TabPane } = Tabs;
  const selectedItem = useSelector(selectCurrentItem);
  const selectedDetailsOnMap: any = useSelector(selectSelectedItemDetailsOnMap)

  const [currentSelectedProperty, setCurrentSelectedProperty] = useState('');
  const [currentSelectedInnerTab, setCurrentSelectedInnerTab] = useState('parent projects')

  const [contactsTotal, setContactsTotal] = useState<any>(0);
  const [showingItems, setShowingItems] = useState<
    { type: string; item: ParcelInterface | ContactInterface }[]
  >([]);

  const { data: selectedProject } =
    useGetParentProjectInfoQuery(
      {
        id: selectedItem?.id || '',
        isChild: selectedItem?.parent_project_id ? true : false,
        surveyTaker: localStorage.getItem('surveyEmail'),
      },
      {
        refetchOnReconnect: true,
      },
    );

  const Tab = ({ property, total }: TabProps) => (
    <div className="tab-title">
      <div className="name">
        <span>{property}</span>
      </div>
      <div className="total" style={{ marginLeft: 6 }}>
        {/* <span>{total}</span> */}
        <Badge count={total} color='#1C7A92' overflowCount={100000} showZero />
      </div>
    </div>
  );

  const data = useMemo(() => {
    if (selectedItem?.type === 'Project' || selectedItem?.type === 'Projects') {
      return selectedProject
        ? {
          information: {
            title: selectedItem?.type || '',
            titleColor: Color.navy,
            image: noImageAvailable,
            allData: selectedProject.allData,
            id_serial: selectedProject.id_serial,
            data: createMetaData({
              item: {
                ...selectedProject,
              } as ProjectInterface,
              type: 'Project',
            }),
          },
          matches: {},
        }
        : null;
    }
  }, [selectedItem, selectedProject]);

  const [parcelsMatchingData, setParcelsMatchingData] = useState<any>()
  const [parcelsMatchesLoading, setParcelsMatchesLoading] = useState(true)

  const { data: parcelToParcelMatches, isSuccess: isParcelToParcelSuccess,
    isFetching: isFetchingParcelToParcel,
  } =
    useGetAllSingleParentProjectMatchedDataQuery(
      {
        id: selectedProject?.id || '',
        matchingType: 'parcels',
        isChild: selectedProject?.parent_project_id ? true : false
      },
      {
        refetchOnReconnect: true,
        skip: !selectedProject?.id
      },
    );

  const { data: parcelToBuilderMatches, isSuccess: isParcelToBuilderSuccess,
    isFetching: isFetchingParcelToBuilder,

  } =
    useGetAllSingleParentProjectMatchedDataQuery(
      {
        id: selectedProject?.id || '',
        matchingType: 'builders',
        isChild: selectedProject?.parent_project_id ? true : false
      },
      {
        refetchOnReconnect: true,
        skip: !selectedProject?.id
      },
    );

  const { data: parcelToInvestorMatches, isSuccess: isParcelToInvestorSuccess,
    isFetching: isFetchingParcelToInvestor,
  } =
    useGetAllSingleParentProjectMatchedDataQuery(
      {
        id: selectedProject?.id || '',
        matchingType: 'investors',
        isChild: selectedProject?.parent_project_id ? true : false
      },
      {
        refetchOnReconnect: true,
        skip: !selectedProject?.id
      },
    );

  const { data: parcelToDeveloperMatches, isSuccess: isParcelToDeveloperSuccess,
    isFetching: isFetchingParcelToDeveloper,
  } =
    useGetAllSingleParentProjectMatchedDataQuery(
      {
        id: selectedProject?.id || '',
        matchingType: 'developers',
        isChild: selectedProject?.parent_project_id ? true : false
      },
      {
        refetchOnReconnect: true,
        skip: !selectedProject?.id
      },
    );

  const { data: parcelToRealEstateAgentMatches,
    isSuccess: isParcelToRealEstateAgentSuccess,
    isFetching: isFetchingParcelToRealEstateAgent,
  } =
    useGetAllSingleParentProjectMatchedDataQuery(
      {
        id: selectedProject?.id || '',
        matchingType: 'realEstateAgents',
        isChild: selectedProject?.parent_project_id ? true : false
      },
      {
        refetchOnReconnect: true,
        skip: !selectedProject?.id
      },
    );

  const { data: parcelToNewBuildHomebuyerMatches,
    isSuccess: isParcelToNewBuildHomebuyerSuccess,
    isFetching: isFetchingParcelToNewBuildHomebuyer,
  } =
    useGetAllSingleParentProjectMatchedDataQuery(
      {
        id: selectedProject?.id || '',
        matchingType: 'newBuildHomebuyers',
        isChild: selectedProject?.parent_project_id ? true : false
      },
      {
        refetchOnReconnect: true,
        skip: !selectedProject?.id
      },
    );

  const { data: parcelToWaterRightsMatches,
    isSuccess: isParcelToWaterRightsSuccess,
    isFetching: isFetchingParcelToWaterRights,
  } =
    useGetAllSingleParentProjectMatchedDataQuery(
      {
        id: selectedProject?.id || '',
        matchingType: 'waterRights',
        isChild: selectedProject?.parent_project_id ? true : false
      },
      {
        refetchOnReconnect: true,
        skip: !selectedProject?.id
      },
    );

  const { data: parcelToProjectMatches,
    isSuccess: isParcelToProjectSuccess,
    isFetching: isFetchingParcelToProject,
  } =
    useGetAllSingleParentProjectMatchedDataQuery(
      {
        id: selectedProject?.id || '',
        matchingType: 'projects',
        isChild: selectedProject?.parent_project_id ? true : false
      },
      {
        refetchOnReconnect: true,
        skip: !selectedProject?.id
      },
    );



  useEffect(() => {
    const matches: any = {}
    if (parcelsMatchingData?.length) {
      for (const [key, value] of parcelsMatchingData) {
        matches[key] = value;
      }
    }
    if (!isFetchingParcelToParcel && parcelToParcelMatches?.Parcels) {
      matches.Parcels = parcelToParcelMatches?.Parcels
    }
    if (!isFetchingParcelToBuilder && parcelToBuilderMatches?.Builders) {
      matches.Builders = parcelToBuilderMatches?.Builders
    }
    if (!isFetchingParcelToInvestor && parcelToInvestorMatches?.Investors) {
      matches.Investors = parcelToInvestorMatches?.Investors
    }
    if (!isFetchingParcelToDeveloper && parcelToDeveloperMatches?.Developer) {
      matches.Developers = parcelToDeveloperMatches?.Developer
    }
    if (!isFetchingParcelToRealEstateAgent && parcelToRealEstateAgentMatches?.['Real Estate Agent']) {
      matches['Real Estate Agents'] = parcelToRealEstateAgentMatches['Real Estate Agent']
    }
    if (!isFetchingParcelToNewBuildHomebuyer && parcelToNewBuildHomebuyerMatches?.['New Build Homebuyer']) {
      matches['New Build Homebuyers'] = parcelToNewBuildHomebuyerMatches['New Build Homebuyer']
    }
    if (!isFetchingParcelToWaterRights && parcelToWaterRightsMatches?.['Water Rights']) {
      matches['Water Rights'] = parcelToWaterRightsMatches['Water Rights']
    }
    if (!isFetchingParcelToProject && parcelToProjectMatches?.Projects) {
      matches.Projects = parcelToProjectMatches.Projects
    }
    if (matches) {
      setParcelsMatchingData(Object.entries(matches))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingParcelToParcel, parcelToParcelMatches,
    isFetchingParcelToBuilder, parcelToBuilderMatches,
    isFetchingParcelToInvestor, parcelToInvestorMatches,
    isFetchingParcelToDeveloper, parcelToDeveloperMatches,
    isFetchingParcelToRealEstateAgent, parcelToRealEstateAgentMatches,
    isFetchingParcelToNewBuildHomebuyer, parcelToNewBuildHomebuyerMatches,
    isFetchingParcelToWaterRights, parcelToWaterRightsMatches,
    isFetchingParcelToProject, parcelToProjectMatches
  ])

  useEffect(() => {
    if (!isFetchingParcelToParcel && isParcelToParcelSuccess &&
      !isFetchingParcelToBuilder && isParcelToBuilderSuccess &&
      !isFetchingParcelToInvestor && isParcelToInvestorSuccess &&
      !isFetchingParcelToDeveloper && isParcelToDeveloperSuccess &&
      !isFetchingParcelToRealEstateAgent && isParcelToRealEstateAgentSuccess &&
      !isFetchingParcelToNewBuildHomebuyer && isParcelToNewBuildHomebuyerSuccess
      && !isFetchingParcelToWaterRights && isParcelToWaterRightsSuccess
      && !isFetchingParcelToProject && isParcelToProjectSuccess
    ) {
      setParcelsMatchesLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isParcelToParcelSuccess,
    isParcelToBuilderSuccess,
    isParcelToInvestorSuccess,
    isParcelToDeveloperSuccess,
    isParcelToRealEstateAgentSuccess,
    isParcelToNewBuildHomebuyerSuccess,
    isParcelToWaterRightsSuccess,
    isParcelToProjectSuccess
  ])

  // const entries: any[] = useMemo(() => {
  //   return (data && Object.entries(data.matches)) || [];
  // }, [data]);
  const selectedProjectType = useSelector(selectSelectedProjectTypeValue)

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20)

  const [currentChildProjectsPage, setCurrentChildProjectsPage] = useState<any>(1);
  const [childProjectsTotal, setChildProjectsTotal] = useState<any>(0)
  const [showingChildProjectsItems, setShowingChildProjectsItems] = useState<any>([])

  const parcelPaginatedQuerySearch = useMemo(
    () =>
      `?page=${currentPage}&take=${limit}&surveyTaker=${localStorage.getItem('surveyEmail')}&id=${selectedProject?.id
      }&matchingType=${currentSelectedProperty}&isChild=${selectedItem?.parent_project_id ? true : false}
      `,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [limit, currentPage, currentSelectedProperty, selectedProject],
  );

  const contactsPaginatedQuerySearch = useMemo(
    () =>
      `?page=${currentPage}&take=${limit}&id=${selectedItem?.id
      }&matchingType=${currentSelectedProperty}&isChild=${selectedItem?.parent_project_id ? true : false}
      `,
    [limit, currentPage, currentSelectedProperty, selectedItem],
  );

  const waterRightssPaginatedQuerySearch = useMemo(
    () =>
      `?page=${currentPage}&take=${limit}&id=${selectedItem?.id
      }&matchingType=${currentSelectedProperty}&isChild=${selectedItem?.parent_project_id ? true : false}
      `,
    [limit, currentPage, currentSelectedProperty, selectedItem],
  );

  const projectsPaginatedQuerySearch = useMemo(
    () =>
      `?page=${currentPage}&take=${limit}&surveyTaker=${localStorage.getItem('surveyEmail')}&id=${selectedItem?.id
      }&matchingType=${currentSelectedProperty}&isChild=${selectedItem?.parent_project_id ? true : false}&matchingToProjectType=parent
      `,
    [limit, currentPage, currentSelectedProperty, selectedItem],
  );

  const childPojectsPaginatedQuerySearch = useMemo(
    () =>
      `?page=${currentChildProjectsPage}&take=${limit}&surveyTaker=${localStorage.getItem('surveyEmail')}&id=${selectedItem?.id
      }&matchingType=${currentSelectedProperty}&isChild=${selectedItem?.parent_project_id ? true : false}&matchingToProjectType=child
      `,
    [limit, currentChildProjectsPage, currentSelectedProperty, selectedItem],
  );

  const getQuery = () => {
    return currentSelectedProperty === 'Builders' || currentSelectedProperty === 'Investors' || currentSelectedProperty === 'Developers'
      || currentSelectedProperty === 'Real Estate Agents' || currentSelectedProperty === 'New Build Homebuyers'
      ? contactsPaginatedQuerySearch
      : currentSelectedProperty === 'Water Rights'
        ? waterRightssPaginatedQuerySearch : currentSelectedProperty === 'Projects' ? projectsPaginatedQuerySearch
          : parcelPaginatedQuerySearch;
  };
  const getParams = () => {
    return currentSelectedProperty === 'Builders' ||
      currentSelectedProperty === 'Investors' ||
      currentSelectedProperty === 'Developers' ||
      currentSelectedProperty === 'Real Estate Agents'
      ? {
        refetchOnReconnect: true,
        skip:
          !selectedProject
      }
      : currentSelectedProperty === 'New Build Homebuyers' ||
        currentSelectedProperty === 'Water Rights' || currentSelectedProperty === 'Projects'
        ? {
          refetchOnReconnect: true,
          skip: !selectedProject
        }
        : {
          refetchOnReconnect: true,
          skip:
            !selectedProject ||
            currentSelectedProperty !== 'Parcels',
        };
  };

  // right side content
  const {
    data: parcelsData,
    isFetching: isFetchingParcels,
    isSuccess: isSuccessParcels,
    refetch: refetchParcels
  } = useGetAllParentProjectMatchingDataQuery(getQuery(), getParams());
  // right side content



  const listData = useMemo(() => {
    if (currentSelectedProperty === 'Builders') {
      return parcelsData?.data?.map((item: any) => ({ item, type: 'Builder' })) || [];
    } else if (currentSelectedProperty === 'Investors') {
      return (
        parcelsData?.data?.map((item: any) => ({ item, type: 'Investor' })) || []
      );
    } else if (currentSelectedProperty === 'Developers') {
      return (
        parcelsData?.data?.map((item: any) => ({ item, type: 'Developer' })) || []
      );
    } else if (currentSelectedProperty === 'Real Estate Agents') {
      return (
        parcelsData?.data?.map((item: any) => ({
          item,
          type: 'Real Estate Agent',
        })) || []
      );
    } else if (currentSelectedProperty === 'New Build Homebuyers') {
      return (
        parcelsData?.data?.map((item: any) => ({
          item,
          type: 'New Build Homebuyers',
        })) || []
      );
    } else if (currentSelectedProperty === 'Water Rights') {
      return (
        parcelsData?.data?.map((item: any) => ({
          item,
          type: 'Water Right',
        })) || []
      );
    } else if (currentSelectedProperty === 'Projects') {
      return (
        parcelsData?.data?.map((item: any) => ({
          item,
          type: 'Projects',
        })) || []
      );
    } else {
      return parcelsData?.data?.map((item: any) => ({ item, type: 'Parcel' })) || [];
    }
  }, [parcelsData, currentSelectedProperty]);

  useEffect(() => {
    if (!isFetchingParcels &&
      isSuccessParcels) {
      setContactsTotal(parcelsData?.pagination?.totalItems)
      // if (currentPage === 1) {
      setShowingItems(listData)
      // }
      // else {
      //   setShowingItems((pv) => [...new Set([...pv, ...(listData || [])])]);
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingParcels, isSuccessParcels, listData]);

  // right side content project to child

  const {
    data: childProjectsData,
    isFetching: isFetchingChildProjects,
    isSuccess: isSuccessChildProjects,
    refetch: refetchChildProjects
  } = useGetAllParentProjectToChildProjectsMatchingDataQuery(childPojectsPaginatedQuerySearch, {
    refetchOnReconnect: true,
    skip: currentSelectedProperty !== 'Projects'
  });

  const childProjectsListData = useMemo(() => {
    return (
      childProjectsData?.data?.map((item: any) => ({
        item,
        type: 'Projects',
      })) || []
    );
  }, [childProjectsData]);

  useEffect(() => {
    if (!isFetchingChildProjects &&
      isSuccessChildProjects) {
      setChildProjectsTotal(childProjectsData?.pagination?.totalItems)
      setShowingChildProjectsItems(childProjectsListData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingChildProjects, isSuccessChildProjects, childProjectsListData]);

  // right side content project to child


  // companies
  const [currentCompanyPage, setCurrentCompanyPage] = useState<any>(1);
  const [companiesTotal, setCompaniesTotal] = useState<any>(0)
  const [showingCompanyItems, setShowingCompanyItems] = useState<any>([])
  const dispatch = useDispatch()

  const {
    data: companyMatchingData,
    isFetching: isFetchingCompanyMatches,
    isSuccess: isSuccessCompanyMatches,
    refetch: refetchCompanyMatches
  } = useGetAllParentProjectToCompanyMatchingDataQuery(`?page=${currentCompanyPage}&take=${limit}&id=${selectedItem?.id
    }&matchingType=${currentSelectedProperty}&isChild=${selectedItem?.parent_project_id ? true : false}&matchingToProjectType=${selectedProjectType}
  `, {
    refetchOnReconnect: true,
    skip:
      !selectedProject || !currentSelectedProperty || currentSelectedProperty === 'Parcels'
      || currentSelectedProperty === 'Water Rights' || currentSelectedProperty === 'Projects'
  });

  const companyListData = useMemo(() => {
    if (currentSelectedProperty === 'Builders') {
      return companyMatchingData?.data.map((item: any) => ({ item, type: 'Builder' })) || [];
    } else if (currentSelectedProperty === 'Investors') {
      return (
        companyMatchingData?.data.map((item: any) => ({ item, type: 'Investor' })) || []
      );
    } else if (currentSelectedProperty === 'Developers') {
      return (
        companyMatchingData?.data.map((item: any) => ({ item, type: 'Developer' })) || []
      );
    } else if (currentSelectedProperty === 'Real Estate Agents') {
      return (
        companyMatchingData?.data.map((item: any) => ({
          item,
          type: 'Real Estate Agent',
        })) || []
      );
    } else if (currentSelectedProperty === 'New Build Homebuyers') {
      return (
        companyMatchingData?.data.map((item: any) => ({
          item,
          type: 'New Build Homebuyers',
        })) || []
      );
    }
  }, [companyMatchingData, currentSelectedProperty]);

  useEffect(() => {
    if (!isFetchingCompanyMatches &&
      isSuccessCompanyMatches) {
      setCompaniesTotal(companyMatchingData?.pagination?.totalItems)
      if (currentCompanyPage === 1) {
        setShowingCompanyItems(companyListData)
      } else {
        setShowingCompanyItems((pv: any) =>
          [...new Set([...pv, ...(companyListData || [])])]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingCompanyMatches, isSuccessCompanyMatches, companyListData]);
  // companies

  const propertyChangeHandler = (key: string) => {
    setShowingItems([]);
    setShowingCompanyItems([]);
    setCurrentPage(1);
    setLimit(20)
    setCurrentCompanyPage(1)
    setCurrentSelectedProperty(key);
  };

  const innerTabChangeHandler = (e: any) => {
    setCurrentSelectedInnerTab(e)
  }
  useEffect(() => {
    if (selectedDetailsOnMap && selectedDetailsOnMap?.currentSelectedProperty) {
      setCurrentSelectedProperty(selectedDetailsOnMap?.currentSelectedProperty)
    } else if (parcelsMatchingData?.length) {
      setCurrentSelectedProperty(parcelsMatchingData[0][0]);
    }
    if (selectedDetailsOnMap && selectedDetailsOnMap?.currentSelectedInnerTab) {
      setCurrentSelectedInnerTab(selectedDetailsOnMap?.currentSelectedInnerTab)
    }
    if (selectedDetailsOnMap?.currentPage) {
      setCurrentPage(selectedDetailsOnMap?.currentPage)
    }
    if (selectedDetailsOnMap?.limit) {
      setLimit(selectedDetailsOnMap?.limit)
    }
    if (selectedDetailsOnMap?.currentChildProjectsPage) {
      setCurrentChildProjectsPage(selectedDetailsOnMap?.currentChildProjectsPage)
    }
  }, [selectedDetailsOnMap, parcelsMatchingData])

  return (
    <>
      {/* {(isFetchingSelectedProject || parcelsMatchesLoading) && <div style={{ display: 'flex', justifyContent: 'center', marginTop: '26%' }}>
        <Spin tip='Loading' size="large"
          spinning={isFetchingSelectedProject || parcelsMatchesLoading}
        /> </div>} */}
      {!parcelsMatchesLoading && data?.information ? (
        <Tabs
          className={`selected-result-drawer-content`}
          activeKey={currentSelectedProperty}
          onChange={(key) => propertyChangeHandler(key)}
          tabPosition="left"
          tabBarExtraContent={{
            left: (
              <>
                <div className="result-summary">
                  {data && data.information.data.findIndex(item => item.name === 'View Child Projects') >= 0
                    ? <ParentProjectCard {...data.information} />
                    : <ChildProjectCard {...data.information} />}
                </div>
              </>
            ),
          }}
        >
          {!parcelsMatchesLoading && parcelsMatchingData?.length ? (
            parcelsMatchingData?.map(([property, { count: total }]: any,
              index: any) => (
              <TabPane
                tab={<Tab property={property}
                  total={parcelsMatchingData[index][1]} />}
                key={property}
              >
                <div className="title matches-title">
                  {/* {`${property}  (${parcelsMatchingData[index][1]})`} */}
                  <span>
                    {property}{' '}
                    <Badge count={parcelsMatchingData.find((elem: any) => elem[0] === currentSelectedProperty)?.[1]} color='#1C7A92' overflowCount={100000} showZero />
                  </span>
                  {property === 'Parcels' &&
                    <TooltipComponent
                      placement='top'
                      title='View all on map'
                      body={<Button className='title-btn' type="primary" onClick={() => {
                        if (selectedItem) {
                          dispatch(setDrawerWidthValue(850))
                          localStorage.setItem('showParcelsType', 'allMatchingParcels')
                          dispatch(setProjectIdValue(selectedItem.id))
                          dispatch(setShowParcelsTypeValue('allMatchingParcels'))
                          // dispatch(setIsSelectedResultDrawerOpenValue(false))
                          dispatch(setCoordsPaginationValue({ page: currentPage, limit }))
                          dispatch(setZoomValue(6))
                          dispatch(setSelectedItemDetailsOnMap({ selectedType: `${selectedProject?.parent_project_id ? 'child' : 'parent'} project`, id: selectedItem?.id, matchingType: `${selectedProject?.parent_project_id ? 'Child' : 'Parent'} project to parcels`, name: selectedItem?.project_name_processed, totalCount: parcelsData?.pagination?.totalItems, currentSelectedProperty, currentSelectedInnerTab, currentPage, limit }))
                        }
                      }}>View all on map</Button>}
                    />
                  }
                </div>

                {/* {currentSelectedProperty === 'Projects' &&
                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 21, marginBottom: 21 }}>
                    <TooltipComponent
                      placement='top'
                      title='View all on map'
                      body={<Button className='title-btn' type="primary" onClick={() => {
                        if (selectedItem) {
                          dispatch(setProjectIdValue(selectedItem.id))
                          dispatch(setShowProjectsTypeValue('allMatchingProjects'))
                          dispatch(setIsSelectedResultDrawerOpenValue(false))
                          dispatch(setCoordsPaginationValue({ page: currentPage, limit }))
                          dispatch(setSelectedItemDetailsOnMap({ selectedType: `${selectedProject?.parent_project_id ? 'Child' : 'Parent'} project`, id: selectedItem?.id, matchingType: `${selectedProject?.parent_project_id ? 'Child' : 'Parent'} project to projects`, name: selectedItem?.project_name_processed }))
                        }
                      }}>View all on map</Button>}
                    />
                  </div>} */}

                {currentSelectedProperty === 'Projects' ?
                  <div className='matching-inner-tab'>
                    <Tabs type='card' defaultActiveKey={currentSelectedInnerTab} onChange={(e) => innerTabChangeHandler(e)}>
                      <TabPane
                        key="parentProject"
                        tab={
                          <Tab
                            property="Parent Projects "
                            total={isFetchingParcels ? `(Loading..)` : contactsTotal ? `(${contactsTotal})` : `(0)`}
                          />
                        }
                      >

                        <Spin tip='Loading' size="large" style={{ margin: -35, marginTop: 30 }}
                          spinning={isFetchingParcels}
                        >
                          <div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '18px' }}>
                              <TooltipComponent
                                placement='top'
                                title='View all on map'
                                body={<Button disabled={!parcelsData?.pagination?.totalItems} className='title-btn' type="primary" onClick={() => {
                                  if (selectedItem) {
                                    dispatch(setDrawerWidthValue(850))
                                    dispatch(setProjectIdValue(selectedItem.id))
                                    // dispatch(setIsSelectedResultDrawerOpenValue(false))
                                    dispatch(setCoordsPaginationValue({ page: currentPage, limit }))
                                    dispatch(setSelectedItemDetailsOnMap({ selectedType: `${selectedProject?.parent_project_id ? 'child' : 'parent'} project`, id: selectedItem?.id, matchingType: `${selectedProject?.parent_project_id ? 'Child' : 'Parent'} project to parent projects`, name: selectedItem?.project_name_processed, totalCount: parcelsData?.pagination?.totalItems, currentSelectedProperty, currentSelectedInnerTab, currentPage, limit }))
                                    dispatch(setZoomValue(4))
                                    dispatch(setShowProjectsTypeValue('parent'))
                                  }
                                }}>View all on map</Button>}
                              />
                            </div>
                            <ResultsGrid
                              isLoading={isFetchingParcels}
                              limit={limit}
                              dataSource={showingItems}
                              currentPage={currentPage}
                              totalItems={parcelsData?.pagination?.totalItems || 0}
                              setCurrentPage={setCurrentPage}
                              setLimit={setLimit}
                              refetchParcels={refetchParcels}
                            />
                          </div>
                        </Spin>
                      </TabPane>
                      <TabPane
                        key="childProject"
                        tab={
                          <Tab
                            property="Child Projects "
                            total={isFetchingChildProjects ? `(Loading..)` : childProjectsTotal ? `(${childProjectsTotal})` : `(0)`}
                          />
                        }
                      >
                        <Spin tip='Loading' size="large" style={{ margin: -35, marginTop: 30 }}
                          spinning={isFetchingChildProjects}
                        >
                          <div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '18px' }}>
                              <TooltipComponent
                                placement='top'
                                title='View all on map'
                                body={<Button disabled={!childProjectsData?.pagination?.totalItems} className='title-btn' type="primary" onClick={() => {
                                  if (selectedItem) {
                                    dispatch(setDrawerWidthValue(850))
                                    dispatch(setProjectIdValue(selectedItem.id))
                                    // dispatch(setIsSelectedResultDrawerOpenValue(false))
                                    dispatch(setCoordsPaginationValue({ page: currentChildProjectsPage, limit }))
                                    dispatch(setSelectedItemDetailsOnMap({ selectedType: `${selectedProject?.parent_project_id ? 'child' : 'parent'} project`, id: selectedItem?.id, matchingType: `${selectedProject?.parent_project_id ? 'Child' : 'Parent'} project to child projects`, name: selectedItem?.project_name_processed, totalCount: childProjectsData?.pagination?.totalItems, currentSelectedProperty, currentSelectedInnerTab, currentChildProjectsPage, limit }))
                                    dispatch(setZoomValue(4))
                                    dispatch(setShowProjectsTypeValue('child'))
                                  }
                                }}>View all on map</Button>}
                              />
                            </div>
                            <ResultsGrid
                              isLoading={isFetchingChildProjects}
                              limit={limit}
                              dataSource={showingChildProjectsItems}
                              currentPage={currentChildProjectsPage}
                              totalItems={childProjectsData?.pagination?.totalItems || 0}
                              setLimit={setLimit}
                              setCurrentPage={setCurrentChildProjectsPage}
                              refetchParcels={refetchChildProjects}
                            />
                          </div>
                        </Spin>
                      </TabPane>

                    </Tabs>
                  </div> : currentSelectedProperty === 'Parcels' || currentSelectedProperty === 'Water Rights' ?
                    <Spin tip='Loading' size="large" style={{ margin: -35, marginTop: 30 }}
                      spinning={isFetchingParcels}
                    >

                      <ResultsGrid
                        isLoading={isFetchingParcels}
                        limit={limit}
                        dataSource={showingItems}
                        currentPage={currentPage}
                        totalItems={parcelsData?.pagination?.totalItems || 0}
                        setCurrentPage={setCurrentPage}
                        setLimit={setLimit}
                        refetchParcels={refetchParcels}
                      />
                    </Spin> : null
                }


                {/* === */}
                {currentSelectedProperty === 'Builders' || currentSelectedProperty === 'Investors' || currentSelectedProperty === 'Developers'
                  || currentSelectedProperty === 'Real Estate Agents' || currentSelectedProperty === 'New Build Homebuyers' ?
                  <div className='matching-inner-tab'>
                    <Tabs type='card'>
                      <TabPane
                        key="contacts"
                        tab={
                          <Tab
                            property="Contacts "
                            total={isFetchingParcels ? `(Loading..)` : contactsTotal ? `(${contactsTotal})` : `(0)`}
                          />
                        }
                      >
                        <Spin tip='Loading' size="large" style={{ margin: -35, marginTop: 30 }}
                          spinning={isFetchingParcels}
                        >
                          <ResultsGrid
                            isLoading={isFetchingParcels}
                            limit={limit}
                            dataSource={showingItems}
                            currentPage={currentPage}
                            totalItems={parcelsData?.pagination?.totalItems || 0}
                            setLimit={setLimit}
                            setCurrentPage={setCurrentPage}
                            refetchParcels={refetchParcels}
                          />
                        </Spin>
                      </TabPane>
                      <TabPane
                        key="companies"
                        tab={
                          <Tab
                            property="Companies "
                            total={isFetchingCompanyMatches ? `(Loading..)` : companiesTotal ? `(${companiesTotal})` : `(0)`}
                          />
                        }
                      >
                        <Spin tip='Loading' size="large" style={{ margin: -35, marginTop: 30 }}
                          spinning={isFetchingCompanyMatches}
                        >
                          <ResultsGrid
                            isLoading={isFetchingCompanyMatches}
                            limit={limit}
                            dataSource={showingCompanyItems}
                            currentPage={currentCompanyPage}
                            totalItems={companyMatchingData?.pagination?.totalItems || 0}
                            setLimit={setLimit}
                            setCurrentPage={setCurrentCompanyPage}
                            refetchParcels={refetchCompanyMatches}
                          />
                        </Spin>
                      </TabPane>
                    </Tabs>
                  </div>
                  :
                  null
                }
              </TabPane>
            ))
          ) : (
            <Empty className="selected-result-drawer-content-empty" />
          )}
        </Tabs>
      ) : null}
    </>
  );
};