import { List, Popover, Spin, notification } from "antd";
import noImageAvailable from 'styles/images/noImageAvailable.png';
import {
  selectShowingTotalValue,
  selectCurrentItem,
  useGetParentProjectInfoQuery,
  setProjectIdValue,
  setItem,
  setSelectedProjectTypeValue,
  selectOpenParentProjectInfoDrawerValue,
  selectOpenChildProjectInfoDrawerValue,
  setOpenParentProjectInfoDrawerValue,
  setOpenChildProjectInfoDrawerValue,
  useGetAllProjectsQuery,
  setShowParcelsTypeValue,
  setCoordsPaginationValue,
  setParcelIdValue,
  setShowProjectsTypeValue,
  setSelectedItemDetailsOnMap,
  setParentProjectListDataValue,
  setShowLoaderOnMap,
  setMapCenterPointValue,
  setZoomValue,
  selectShowParcelsTypeValue
} from "app/services";
import { Color, MatchedItemPopover } from "components";
import { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
// import { Waypoint } from "react-waypoint";
import { ProjectInterface } from "types/projects";
import { createMetaData } from "utils/metaDataHelpers";
import { ViewParentProjectsPopup } from "components/ViewProjectsPopup/ViewParentProjectsPopup";
import { ViewChildProjectsPopup } from "components/ViewProjectsPopup/ViewChildProjectsPopup";
import { ChildProjectCard } from "components/MetaData/ChildProjectCard";
import { useResetFilters } from "utils/resetFilters";

export interface ChildProjectResultBoxProps {
  onOpenSelectedFilters: () => void;
  handleOnViewAllClick: (item: { id: string; type: string }) => void;
  closeInfoWindowOpen: () => void;
  isSelectedResultDrawerOpen?: boolean;
  paginatedQuery: string,
  childProjectsPagination: any;
  setChildActivePage: (pageNumber: number) => void;
  setIsLoadingData: (selectedType: string) => void;
}

const ResultsList = ({
  dataSource,
  handleOnViewAllClick,
  closeInfoWindowOpen,
  onWaypointEnter,
  hasMore,
  isSelectedResultDrawerOpen
}: {
  dataSource:
  | { type: string; item: ProjectInterface }[]
  | undefined;
  handleOnViewAllClick: (item: any) => void;
  closeInfoWindowOpen: () => void;
  onWaypointEnter: () => void;
  hasMore: boolean;
  isSelectedResultDrawerOpen?: boolean
}) => {
  const selectedItem = useSelector(selectCurrentItem);
  const dispatch = useDispatch();
  const [isProjectParcelLoading, setIsProjectParcelLoading] = useState(false)
  const { resetFiltersForViewOnMap } = useResetFilters()

  const skipSingleProjectQuery = useMemo(() => {
    return !(selectedItem && selectedItem.type === 'Project');
  }, [selectedItem]);

  const {
    data: selectedProject,
    isFetching: isFetchingProject,
    // isSuccess: isSuccessProject,
  } = useGetParentProjectInfoQuery(
    {
      id: (selectedItem && selectedItem.id) || '',
      isChild: selectedItem?.parent_project_id ? true : false,
      surveyTaker: localStorage.getItem('surveyEmail'),
    },
    {
      refetchOnReconnect: true,
      skip: skipSingleProjectQuery,
    },
  );
  const data: any = useMemo(() => {
    if (selectedItem?.type === 'Project')
      return selectedProject
        ? {
          information: {
            title: selectedItem?.type || '',
            titleColor: Color.green,
            image: noImageAvailable,
            alldata: selectedProject.allData || selectedProject,
            id: selectedItem?.id || '',
            existsInBookmarks: selectedProject?.existsInBookmarks || '',
            parcels: selectedProject?.parcels || '',
            data: createMetaData({
              item: {
                ...selectedProject,
              } as unknown as ProjectInterface,
              type: 'Project',
            }),
          },
          // matches: Object.entries(selectedProject?.matches).map(
          //   ([property, { count }]) => ({
          //     property,
          //     total: count,
          //   }),
          // ),
        }
        : {};
  }, [selectedItem, selectedProject]);

  const showParcelsType = useSelector(selectShowParcelsTypeValue)

  const onListItemClick = (thisItem: any) => {
    if (document.getElementsByClassName('drawer-menu-1') && document.getElementsByClassName('drawer-menu-1').length) {
      let element: HTMLElement = document.getElementsByClassName('drawer-menu-1')[0] as HTMLElement;
      element.click();
    }
    localStorage.removeItem('showingChildProjectOnMap')
    window.dispatchEvent(new Event("showingChildProjectOnMap"));
    localStorage.removeItem('parentProjectsData')
    window.dispatchEvent(new Event("viewChildProject"));
    dispatch(setProjectIdValue(''));
    dispatch(
      setItem({
        id: thisItem.item.id,
        type: thisItem.type,
        parcelId: thisItem.item.parcelId,
        formattedParcelId: thisItem.item.formattedParcelId,
        id_serial: thisItem.item.id_serial,
        parent_project_id: thisItem.item.parent_project_id,
        project_name_processed: thisItem.item.project_name_processed
      }),
    );
    if (thisItem?.item?.geomCenter?.coordinates?.length) {
      let lat = thisItem?.item?.geomCenter?.coordinates[1]
      let lng = thisItem?.item?.geomCenter?.coordinates[0]
      dispatch(setMapCenterPointValue({ lat, lng }))
      dispatch(setZoomValue(18))
    }
    if (thisItem?.item?.parent_project_id && thisItem?.type === 'Project') {
      dispatch(setSelectedProjectTypeValue('child'))
    } else if (thisItem?.type === 'Project') {
      dispatch(setSelectedProjectTypeValue('parent'))
    }

    dispatch(setShowProjectsTypeValue(null))
    dispatch(setShowParcelsTypeValue(null))
    dispatch(setCoordsPaginationValue({ page: 1, limit: 400 }))

    dispatch(setSelectedItemDetailsOnMap(null))

    dispatch(setParcelIdValue(''))
    closeInfoWindowOpen();
  }

  const onOpenChange = (e: any) => {
    if (e === false && !isSelectedResultDrawerOpen && !showParcelsType) {
      dispatch(
        setItem(null)
      );
    }
  }

  useEffect(() => {
    window.addEventListener('isProjectParcelLoadingTrue', () => {
      setIsProjectParcelLoading(true)
    })
    window.addEventListener('isProjectParcelLoadingFalse', () => {
      setIsProjectParcelLoading(false)
    });
    localStorage.removeItem("parentProjectMountCount");
  }, [])

  const openParentProjectInfoDrawer = useSelector(selectOpenParentProjectInfoDrawerValue)
  const openChildProjectInfoDrawer = useSelector(selectOpenChildProjectInfoDrawerValue)
  const [selectedParentChildItem,
    setSelectedParentChildItem] = useState<any>(null)
  const onParenChildBadgeClick = (item: any, type: any) => {
    setSelectedParentChildItem(item)
    if (type === 'parent') {
      dispatch(setOpenParentProjectInfoDrawerValue(true))
    }
    if (type === 'child') {
      dispatch(setOpenChildProjectInfoDrawerValue(true))
    }
  }

  return (
    <>
      {openParentProjectInfoDrawer &&
        <ViewParentProjectsPopup
          data={selectedParentChildItem}
          isOpen={openParentProjectInfoDrawer}
          closeDrawer={(e: any) => {
            if (e) {
              e.stopPropagation()
            }
            resetFiltersForViewOnMap()
            dispatch(setOpenParentProjectInfoDrawerValue(false))
          }
          } />}
      {openChildProjectInfoDrawer &&
        <ViewChildProjectsPopup
          data={selectedParentChildItem}
          isOpen={openChildProjectInfoDrawer}
          closeDrawer={(e: any) => {
            if (e) {
              e.stopPropagation()
            }
            resetFiltersForViewOnMap()
            dispatch(setOpenChildProjectInfoDrawerValue(false))
          }
          } />}
      <List
        size="large"
        dataSource={dataSource}
        renderItem={(thisItem, i) => (
          <Popover
            key={thisItem.item.id}
            overlayClassName={isSelectedResultDrawerOpen ? "selected-item-popover zindex-background" : "selected-item-popover"}
            destroyTooltipOnHide
            onOpenChange={(e) => {onOpenChange(e); if (!showParcelsType) onListItemClick(thisItem)}}
            content={
              <Spin size='large'
                spinning={isFetchingProject
                  || isProjectParcelLoading}
              // indicator={
              //   <Loading3QuartersOutlined style={{ fontSize: 50 }} spin />
              // }
              >
                <MatchedItemPopover
                  onViewAllClick={() =>
                    handleOnViewAllClick({
                      id: thisItem.item.id,
                      type: thisItem.type,
                      parcelId: thisItem.item.parcelId,
                      formattedParcelId: thisItem.item.formattedParcelId,
                      parent_project_id: thisItem.item?.parent_project_id,
                      id_serial: thisItem.item.id_serial,
                      ownershipName: thisItem.item.ownershipName,
                      project_name_processed: thisItem.item.project_name_processed,
                    })
                  }
                  information={data && data.information}
                  allData={data?.information?.alldata || {}}
                />
              </Spin>
            }
            trigger="click"
            placement="right"
          >
            <List.Item key={thisItem.item.id} className="font-small">
              <div
                onClick={() => {
                  onListItemClick(thisItem)
                }}
                style={{ width: '100%' }}
              >
                <div style={{ display: 'flex' }}>

                  <ChildProjectCard
                    onParenChildBadgeClick={onParenChildBadgeClick}
                    title={thisItem?.type}
                    id_serial={thisItem?.item?.id_serial || ''}
                    item={thisItem}
                    titleColor={Color.green}
                    data={createMetaData(thisItem)}
                    allData={data && data.information?.alldata}
                    showChildProjectsLink={true}
                  />
                  {selectedItem && selectedItem?.id === thisItem?.item?.id ?
                    <img className="current-img" title='Selected' src='green-circle-icon.svg' alt='Selected' /> : null}
                </div>
                {/* {dataSource && hasMore && i === dataSource?.length - 1 && (
                  <Waypoint
                    onEnter={(args) => {
                      onWaypointEnter();
                    }}
                  />
                )} */}
              </div>
            </List.Item>
          </Popover>
        )}
      />
    </>
  );
};

export const ChildProjectResultBox = ({
  onOpenSelectedFilters,
  handleOnViewAllClick,
  closeInfoWindowOpen,
  isSelectedResultDrawerOpen,
  paginatedQuery,
  childProjectsPagination,
  setChildActivePage,
  setIsLoadingData
}: ChildProjectResultBoxProps) => {
  const showingTotal: number | undefined = useSelector(selectShowingTotalValue);
  const { search } = useLocation();
  const [activePage, setActivePage] = useState(1);
  const [showingChildProjectItems, setshowingChildProjectItems] = useState<
    { type: string; item: ProjectInterface }[]
  >([]);

  useEffect(() => {
    setChildActivePage(activePage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage])

  const {
    data: childProjectsData,
    isFetching: isFetchingChildProjects,
    isSuccess: isSuccessChildProjects,
  } = useGetAllProjectsQuery(paginatedQuery, {
    refetchOnReconnect: true
  });
  const dispatch = useDispatch()
  useEffect(() => {
    if(childProjectsData?.data) {
      dispatch(setParentProjectListDataValue(childProjectsData?.data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childProjectsData])

  const childProjectListData = useMemo(() => {
    return childProjectsData?.data?.map((item: any) => ({ item, type: 'Project' }));
  }, [childProjectsData]);

  useEffect(() => {
    dispatch(setShowLoaderOnMap(isFetchingChildProjects))

    // Set loading state based on fetching status
    setIsLoadingData(isFetchingChildProjects ? 'childProjects' : '');

    // Determine notification message and type
    let notificationMessage = '';
    let notificationType = 'warn';

    if (!isFetchingChildProjects && !childProjectListData?.length) {
      notificationMessage = search ? 'No project matches available for the applied filters' : 'No projects available';
    } else if (!isFetchingChildProjects && childProjectsData) {
      notificationType = 'success';
      notificationMessage = 'Child Projects retrieved successfully!';
    }

    // Display notification if message exists
    if (notificationMessage) {
      const notificationFunc = notificationType === 'success' ? notification.success : notification.warning;

      notificationFunc({
        placement: 'topRight',
        message: notificationMessage,
      });

      if (search) {
        onOpenSelectedFilters();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingChildProjects])

  useEffect(() => {
    !isFetchingChildProjects &&
      isSuccessChildProjects &&

      setshowingChildProjectItems((pv) =>
        activePage === 1
          ? [...new Set([...[], ...(childProjectListData || [])])]
          : [...new Set([...pv, ...(childProjectListData || [])])],
      );

  }, [
    isFetchingChildProjects,
    isSuccessChildProjects,
    childProjectListData,
    activePage
  ]);

  return (
    <div>
      <ResultsList
        isSelectedResultDrawerOpen={isSelectedResultDrawerOpen}
        dataSource={showingChildProjectItems}
        handleOnViewAllClick={handleOnViewAllClick}
        closeInfoWindowOpen={closeInfoWindowOpen}
        onWaypointEnter={() =>
          (!isFetchingChildProjects &&
            showingChildProjectItems?.length <= 990)
            || showingTotal ?
            setActivePage((page) => page + 1) : null
        }
        hasMore={activePage < childProjectsPagination?.totalPages}
      />
      {isFetchingChildProjects && activePage !== 1 && (
        <Spin
          spinning={true}
          // indicator={
          //   <Loading3QuartersOutlined style={{ fontSize: 20 }} spin />
          // }
          tip="Loading..."
          className="cust-spin"
        />
      )}
    </div>
  );
}