import { Row, Col, Pagination, Button } from "antd";
import Skeleton from 'react-loading-skeleton';
import { selectCoordsPaginationValue, selectDrawerWidthValue, selectIsCountLoadingValue, selectShowingTotalValue, setCoordsPaginationValue, setDrawerWidthValue, setParcelPageCoordsValue, setSelectedItemDetailsOnMap, setShowParcelsTypeValue, setShowProjectsTypeValue, useGetAllParcelsQuery } from "app/services";
import { ResultCard, Color } from "components";
import { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ParcelInterface } from "types/parcels";
import { createMetaData } from "utils/metaDataHelpers";
import { useLocation } from "react-router-dom";

export interface ParcelGridViewProps {
  setParcelsTotal: (total: number) => void;
  closeViewAll: () => void;
}

const ResultsGrid = ({
  dataSource,
  closeViewAll,
}: {
  dataSource:
  | { type: string; item: ParcelInterface }[]
  | undefined;
  closeViewAll: () => void;
}) => {

  return (
    <>
      <Row
        style={{ marginBottom: 30 }}
        className="result-content"
        gutter={[
          { xs: 6, sm: 12, md: 16, lg: 24 },
          { xs: 6, sm: 12, md: 16, lg: 24 },
        ]}
      >
        {dataSource &&
          dataSource.map((result, i) => (
            <Col key={i}>
              <ResultCard
                title={result?.type}
                allData={result}
                from='viewAll'
                titleColor={Color.navy}
                data={createMetaData(result)}
                closeViewAll={closeViewAll}
                id={result.item?.id}
                parcelId={result.item?.parcelId}
                id_serial={result.item?.id_serial}
                formattedParcelId={result.item?.formattedParcelId}
              />
            </Col>
          ))}
      </Row>
    </>
  );
};

export const ParcelGridView = ({ setParcelsTotal, closeViewAll }: ParcelGridViewProps) => {
  const showingTotal: number | undefined = useSelector(selectShowingTotalValue);
  const isCountLoading: any = useSelector(selectIsCountLoadingValue);
  const drawerWidthValue: any = useSelector(selectDrawerWidthValue)

  const { search } = useLocation();
  const dispatch = useDispatch()

  const [limit, setLimit] = useState(20)
  const [currentPage, setCurrentPage] = useState(1);

  const coordsPagination:any = useSelector(selectCoordsPaginationValue)
  useEffect(() => {
    setCurrentPage(coordsPagination?.page)
  }, [coordsPagination])

  useEffect(() => {
    setCurrentPage(1)
  }, [limit])

  useEffect(() => {
    dispatch(setCoordsPaginationValue({ page: currentPage, limit }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  const paginatedQuerySearch = useMemo(
    () => `?page=${currentPage}&take=${limit}&${search.substring(1)}&surveyTaker=${localStorage.getItem('surveyEmail')}`,
    [limit, currentPage, search],
  );

  const [showingItems, setShowingItems] = useState<{ type: string; item: ParcelInterface }[]>([]);

  const {
    data: parcelsData,
    isFetching: isFetchingParcels,
    isSuccess: isSuccessParcels,
  } = useGetAllParcelsQuery(paginatedQuerySearch, {
    refetchOnReconnect: true
  });

  const listData = useMemo(() => {
    return parcelsData?.data.map((item) => ({ item, type: 'Parcel' })) || [];
  }, [parcelsData]);

  useEffect(() => {
    if (!isFetchingParcels && isSuccessParcels) {
      const allShowingItems = [...(listData || [])];
      setShowingItems(allShowingItems)
      setParcelsTotal(showingTotal || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFetchingParcels,
    isSuccessParcels,
    listData
  ]);

  const viewAllOnMapHandler = () => {
    dispatch(setDrawerWidthValue(680))
    dispatch(setShowParcelsTypeValue(null))
    dispatch(setShowProjectsTypeValue(null))
    dispatch(setCoordsPaginationValue({ page: currentPage, limit }))
    dispatch(setParcelPageCoordsValue(parcelsData?.data))

    // closeViewAll()
    dispatch(setSelectedItemDetailsOnMap({ viewType: 'Grid view', selectedType: 'parcels', totalCount: showingTotal }))
  }

  return (
    <div>
      {isFetchingParcels ? (
        <>
          <Row
            style={{ marginBottom: 30 }}
            className="result-content"
            gutter={[
              { xs: 6, sm: 12, md: 16, lg: 24 },
              { xs: 6, sm: 12, md: 16, lg: 24 },
            ]}
          >
            {Array(7)
              .fill('')
              .map(() => {
                return (
                  <Col key={1} style={{ flex: 1 }}>
                    <Skeleton
                      className="grid-skeleton"
                      height={160}
                      count={1}
                    />
                  </Col>
                );
              })}
          </Row>
          <Row
            style={{ marginBottom: 30 }}
            className="result-content"
            gutter={[
              { xs: 6, sm: 12, md: 16, lg: 24 },
              { xs: 6, sm: 12, md: 16, lg: 24 },
            ]}
          >
            {Array(7)
              .fill('')
              .map(() => {
                return (
                  <Col key={1} style={{ flex: 1 }}>
                    <Skeleton
                      className="grid-skeleton"
                      height={160}
                      count={1}
                    />
                  </Col>
                );
              })}
          </Row>
        </>
      ) : (
        <div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 18 }}>
            <Button className='title-btn' type="primary" onClick={viewAllOnMapHandler}>
              View all on map
            </Button>
          </div>
          <ResultsGrid
            dataSource={showingItems}
            closeViewAll={closeViewAll}
          />
        </div>
      )}
      <div className="pagination-container" style={{ width: drawerWidthValue }}>
        <Pagination
          current={currentPage}
          total={showingTotal}
          pageSize={limit}
          showTotal={(total, range) => isCountLoading ? 'Loading...' : `${range[0]}-${range[1]} of ${total} items`}
          onShowSizeChange={(current, pageSize) => setLimit(pageSize)}
          onChange={(page) => setCurrentPage(page)} />
      </div>
    </div>
  )
}