import { createApi } from '@reduxjs/toolkit/query/react';
import {
  BOOKMARKROUTES,
  CONTACTSROUTES,
  PARCELROUTES,
  PROJECTROUTES,
  WATERRIGHTSROUTES,
} from 'app/apiConstants';
import { baseQuery } from 'app/baseQuery';
import {
  Pagination,
  ParcelsDataResponse,
  ParcelsGeoDataResponse,
  ParcelSingleMatchedData,
} from 'types/parcels';
import {
  CreateParcelBookmarkArgs,
  DeleteParcelBookmarkArgs,
  ParcelsBookmarkInterface,
} from 'types/bookmark';
import { ContactsDataResponse } from 'types/contacts';
import { parentProjectApi } from './parentprojects.api';

export const parcelApi = createApi({
  baseQuery,
  tagTypes: ['ParcelsBookmark'],
  reducerPath: 'parcelApi',
  /**
   * API slice for interacting with parcel-related endpoints.
   * This slice includes queries to retrieve various parcel data such as geo data, matching data,
   * bookmarks, and other related operations.
   */
  endpoints: (builder) => ({
    /**
     * Retrieves geo data for parcels based on search criteria.
     * @param search - Optional search criteria to filter parcels.
     * Usage:
     * ```typescript
     * const { data } = useGetParcelGeoDataQuery(search);
     * ```
     */
    getParcelGeoData: builder.query<ParcelsGeoDataResponse, string>({
      query: (search) => ({
        url: `${PARCELROUTES.geoData}${search}`,
      }),
      keepUnusedDataFor: 1,
    }),
    /**
     * Retrieves center point data for parcels based on search criteria.
     * @param search - Optional search criteria to filter parcels.
     * Usage:
     * ```typescript
     * const { data } = useGetParcelCenterPointQuery(search);
     * ```
     */
    getParcelCenterPoint: builder.query<ParcelsGeoDataResponse, string>({
      query: (search) => ({
        url: `${PARCELROUTES.centerPoint}${search}`,
      }),
    }),
    /**
     * Retrieves all parcels based on search criteria.
     * @param search - Optional search criteria to filter parcels.
     * Usage:
     * ```typescript
     * const { data } = useGetAllParcelsQuery(search);
     * ```
     */
    getAllParcels: builder.query<ParcelsDataResponse, string>({
      query: (search) => ({
        url: `${PARCELROUTES.base}${search}`,
      }),
      providesTags: ['ParcelsBookmark'],
    }),
    /**
     * Retrieves the count of parcels based on search criteria.
     * @param search - Optional search criteria to filter parcels.
     * Usage:
     * ```typescript
     * const { data } = useGetParcelsCountQuery(search);
     * ```
     */
    getParcelsCount: builder.query<Pagination, string>({
      query: (search) => ({
        url: `${PARCELROUTES.count}${search}`,
      }),
    }),
    getParcelInfo: builder.query<
      ParcelSingleMatchedData,
      { id: string; hypothetical?: any; surveyTaker: any }
    >({
      query: ({ id, hypothetical = null, surveyTaker }) => ({
        url: `${PARCELROUTES.info}/${id}?surveyTaker=${surveyTaker}&hypothetical=${hypothetical}`,
      }),
      providesTags: ['ParcelsBookmark'],
    }),
    getSingleParcelMatchedData: builder.query<
      any,
      { id: string; hypothetical?: any; matchingType?: any }
    >({
      query: ({ id, hypothetical, matchingType = null }) => ({
        url: `${
          PARCELROUTES.matchesCount
        }/${id}${`?matchingType=${matchingType}&hypothetical=${hypothetical}`}`,
      }),
    }),
    getAllSingleParcelMatchedData: builder.query<
      any,
      { id: string; hypothetical?: any; matchingType?: any }
    >({
      query: ({ id, hypothetical, matchingType = null }) => ({
        url: `${
          PARCELROUTES.allMatchesCount
        }/${id}${`?matchingType=${matchingType}&hypothetical=${hypothetical}`}`,
      }),
    }),
    /**
     * Retrieves all data for a specific parcel by ID.
     * @param id - The ID of the parcel to fetch detailed information for.
     * Usage:
     * ```typescript
     * const { data } = useGetParcelInfoAllDataQuery({ id });
     * ```
     */
    getParcelInfoAllData: builder.query<any, { id: any, surveyTaker: any }>({
      query: ({ id, surveyTaker }) => ({
        url: `${PARCELROUTES.allData}/${id}?surveyTaker=${surveyTaker}`,
      }),
      providesTags: ['ParcelsBookmark'],
    }),
    /**
     * Retrieves all parcel statuses.
     * Usage:
     * ```typescript
     * const { data } = useGetAllParcelStatusQuery();
     * ```
     */
    getAllParcelStatus: builder.query<string[], string>({
      query: () => ({
        url: `${PARCELROUTES.base}/status`,
      }),
    }),
    deleteParcelBookmarkByParcelId: builder.mutation<
      unknown,
      DeleteParcelBookmarkArgs
    >({
      query: ({ id }) => ({
        url: `${BOOKMARKROUTES.parcels}/parcel/${id}`,
        method: 'DELETE',
      }),
      /**
       * Async callback function executed when the delete parcel bookmark query starts.
       * This function handles the response from the query, updates the parent project matching data,
       * and invalidates relevant tags.
       * @param id - The ID of the bookmarked parcel being deleted.
       * @param dataArgs - Additional data arguments used for updating parent project matching data.
       * @param queryFulfilled - The fulfilled query response containing the deleted bookmark record.
       * @param dispatch - Redux dispatch function to dispatch actions.
       */
      async onQueryStarted({ id, dataArgs }, { queryFulfilled, dispatch }) {
        try {
          const { data: deletedBookmarkRecord } = await queryFulfilled;
          console.log('deletedBookmarkRecord =>>', deletedBookmarkRecord, id);
          dispatch(
            parentProjectApi.util.invalidateTags(['AllParentProjectsMatching']),
          );
          if (dataArgs) {
            dispatch(
              parentProjectApi.util.updateQueryData(
                'getAllParentProjectMatchingData',
                dataArgs,
                (draft) => {
                  if (draft?.data) {
                    const dataIndex = draft.data.findIndex(
                      (x: any) => x.id_serial === id,
                    );
                    if (dataIndex >= 0) {
                      draft.data[dataIndex] = {
                        ...draft.data[dataIndex],
                        existsInBookmarks: false,
                      };
                    }
                  }
                },
              ),
            );
          }
        } catch (error) {
          console.log('delete bookmark api error', error);
        }
      },
      invalidatesTags: ['ParcelsBookmark'],
    }),

    createParcelBookmark: builder.mutation<
      ParcelsBookmarkInterface,
      CreateParcelBookmarkArgs
    >({
      query: (body) => ({
        url: BOOKMARKROUTES.parcels,
        method: 'POST',
        body,
      }),
      /**
       * Async callback function executed when the create parcel bookmark query starts.
       * This function handles the response from the query, updates the parent project matching data
       * to reflect the bookmarked status of the parcel, and logs any errors encountered.
       * @param parcel_id - The ID of the parcel that was bookmarked.
       * @param dataArgs - Additional data arguments used for updating parent project matching data.
       * @param queryFulfilled - The fulfilled query response containing the created bookmark record.
       * @param dispatch - Redux dispatch function to dispatch actions.
       */
      async onQueryStarted(
        { parcel_id, dataArgs },
        { queryFulfilled, dispatch },
      ) {
        try {
          const { data: createdBookmarkRecord } = await queryFulfilled;
          console.log(
            'createdBookmarkRecord =>>',
            createdBookmarkRecord,
            parcel_id,
          );
          if (dataArgs) {
            dispatch(
              parentProjectApi.util.updateQueryData(
                'getAllParentProjectMatchingData',
                dataArgs,
                (draft) => {
                  if (draft?.data) {
                    const dataIndex = draft.data.findIndex(
                      (x: any) => x.id_serial === parcel_id,
                    );
                    if (dataIndex >= 0) {
                      draft.data[dataIndex] = {
                        ...draft.data[dataIndex],
                        existsInBookmarks: true,
                      };
                    }
                  }
                },
              ),
            );
          }
        } catch (error) {
          console.log('create bookmark api error', error);
        }
      },
      invalidatesTags: ['ParcelsBookmark'],
    }),

    /**
     * Retrieves all parcel bookmarks based on search parameters.
     * This query fetches data for all parcel bookmarks and provides caching tags for bookmarks.
     * @param search - Additional search parameters to filter the bookmarks.
     */
    getAllParcelBookmarks: builder.query<any, any>({
      query: ({ page, take, surveyTaker }) => ({
        url: `${BOOKMARKROUTES.parcels}?page=${page}&take=${take}&surveyTaker=${surveyTaker}`,
      }),
      providesTags: ['ParcelsBookmark'],
    }),
    /**
     * Retrieves coordinates for all parcel bookmarks with pagination.
     * This query fetches coordinates data for all parcel bookmarks based on pagination parameters.
     * @param page - The page number of results to fetch.
     * @param limit - The maximum number of results per page.
     */
    getAllParcelBookmarksCoordinates: builder.query<any, any>({
      query: ({ page, limit, surveyTaker }) => ({
        url: `${BOOKMARKROUTES.parcelsBookmarks}?page=${page}&take=${limit}&surveyTaker=${surveyTaker}`,
      }),
    }),
    /**
     * Deletes a parcel bookmark by its ID.
     * This mutation deletes a parcel bookmark identified by the provided ID and invalidates
     * relevant caching tags to trigger updates.
     * @param id - The ID of the parcel bookmark to delete.
     */
    deleteParcelBookmark: builder.mutation<unknown, string>({
      query: (id) => ({
        url: `${BOOKMARKROUTES.parcels}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ParcelsBookmark'],
    }),
    /**
     * Retrieves geographical data for projects matching a parcel.
     * This query fetches geographical data for projects that match a specific parcel,
     * including optional parameters for type, child status, pagination, and limits.
     * @param projectId - The ID of the project for which to fetch matching geographical data.
     * @param type - Optional parameter for filtering by project type.
     */
    getProjectsParcelGeoData: builder.query<any, any>({
      query: ({
        projectId,
        type,
        isChild,
        page,
        limit,
        isProjectNameSearch,
      }) => ({
        url: `${PARCELROUTES.geoData}/${projectId}?type=${type}&isChild=${isChild}&page=${page}&limit=${limit}&isProjectNameSearch=${isProjectNameSearch}`,
      }),
      keepUnusedDataFor: 1,
    }),
    getProjectsParcelsQuery: builder.query<any, any>({
      query: ({ projectId, isChild, isProjectNameSearch }) => ({
        url: `${PARCELROUTES.geoDataQuery}/${projectId}?isChild=${isChild}&isProjectNameSearch=${isProjectNameSearch}`,
      }),
      keepUnusedDataFor: 1,
    }),
    /**
     * Retrieves geographical data for parcels matching a project.
     * This query fetches geographical data for parcels that match a specific project,
     * including optional parameters for parcel ID, child status, pagination, and limits.
     * @param parcelId - The ID of the parcel for which to fetch matching geographical data.
     * @param isChild - Optional parameter to specify if parcels are child parcels.
     */
    getParcelsProjectsGeoData: builder.query<any, any>({
      query: ({ parcelId, isChild, page, limit }) => ({
        url: `${PARCELROUTES.base}/${parcelId}/projects-matching-coordinates?isChild=${isChild}&page=${page}&limit=${limit}`,
      }),
      keepUnusedDataFor: 1,
    }),
    /**
     * Retrieves matching geographical data for a parcel.
     * This query fetches geographical data for a specific parcel based on its ID,
     * including optional parameters for pagination and limits.
     * @param parcelId - The ID of the parcel for which to fetch matching geographical data.
     * @param page - The page number of results to fetch.\
     */
    getParcelsParcelGeoData: builder.query<any, any>({
      query: ({ parcelId, page, limit }) => ({
        url: `${PARCELROUTES.matchingGeoData}/${parcelId}?page=${page}&limit=${limit}`,
      }),
      keepUnusedDataFor: 1,
    }),
    /**
     * Retrieves matching data based on the specified search criteria.
     *
     * The function dynamically constructs API URLs based on the 'matchingType'
     * parameter in the search query. It supports various types such as Builders,
     * Investors, Developers, Real Estate Agents, New Build Homebuyers, Water Rights,
     * and Projects. If no specific matching type is found, it defaults to fetching
     * general parcel matching data.
     */
    getMatchingData: builder.query<ContactsDataResponse, any>({
      query: (search) => ({
        url: search.includes('matchingType=Builders')
          ? `${CONTACTSROUTES.base}/builders${search}`
          : search.includes('matchingType=Investors')
          ? `${CONTACTSROUTES.base}/investors${search}`
          : search.includes('matchingType=Developers')
          ? `${CONTACTSROUTES.base}/developers${search}`
          : search.includes('matchingType=Real Estate Agents')
          ? `${CONTACTSROUTES.base}/realestateagents${search}`
          : search.includes('matchingType=New Build Homebuyers')
          ? `${CONTACTSROUTES.base}/newbuildhomebuyers${search}`
          : search.includes('matchingType=Water Rights')
          ? `${WATERRIGHTSROUTES.base}/parcel-matching${search}`
          : search.includes('matchingType=Projects')
          ? `${PROJECTROUTES.parcelsMatchingData}${search}`
          : `${PARCELROUTES.matchingData}${search}`,
      }),
      transformResponse: (response: any, meta, args) => {
        response.data = response.data.map((data: any) => {
          data.dataArgs = args;
          return data;
        });
        return response;
      },
      providesTags: ['ParcelsBookmark'],
    }),
    /**
     * Retrieves company-specific matching data based on the provided search criteria.
     *
     * This function fetches data from an API endpoint specified by PARCELROUTES.companyMatchedData
     * and applies transformations to the response data before returning it.
     *
     * @param {string} search - The search query string containing parameters for filtering data.
     * @returns {Promise<ContactsDataResponse>} - A Promise resolving to the response data.
     */
    getCompanyMatchingData: builder.query<ContactsDataResponse, any>({
      query: (search) => ({
        url: `${PARCELROUTES.companyMatchedData}${search}`,
      }),
      transformResponse: (response: any, meta, args) => {
        response.data = response.data.map((data: any) => {
          data.dataArgs = args;
          return data;
        });
        return response;
      },
      providesTags: ['ParcelsBookmark'],
    }),
    /**
     * Retrieves parcel matching queries based on the specified serial ID and matching type.
     *
     * Fetches data from an API endpoint specified by PARCELROUTES.matchingQueries, appending
     * the serial ID and matching type parameters to the URL.
     *
     * @param {string} serialId - The serial ID of the parcel for which matching queries are requested.
     * @param {string} matchingType - The type of matching queries to retrieve.
     * @returns {Promise<any>} - A Promise resolving to the matching queries data.
     */
    getParcelMatchingQueries: builder.query<any, any>({
      query: ({ serialId, matchingType }) => ({
        url: `${PARCELROUTES.matchingQueries}/${serialId}?matchingType=${matchingType}`,
        method: 'GET',
      }),
    }),
    /**
     * Retrieves parcel data based on the provided latitude and longitude coordinates.
     *
     * Fetches parcel data from an API endpoint specified by PARCELROUTES.coords, using
     * the provided latitude and longitude as query parameters.
     *
     * @param {number} lat - The latitude coordinate of the parcel.
     * @param {number} lng - The longitude coordinate of the parcel.
     * @returns {Promise<any>} - A Promise resolving to the parcel data.
     */
    getParcelWithCoords: builder.query<any, any>({
      query: ({ lat, lng }) => ({
        url: `${PARCELROUTES.coords}?lat=${lat}&lng=${lng}`,
        method: 'GET',
      }),
    }),
    /**
     * Retrieves parcels based on the provided search criteria.
     *
     * Fetches parcel data from an API endpoint specified by PARCELROUTES.getQuery,
     * using the provided search parameters as part of the URL.
     *
     * @param {string} search - The search query string containing parameters for filtering parcels.
     * @returns {Promise<any>} - A Promise resolving to the parcel data.
     */
    getParcelsQuery: builder.query<any, any>({
      query: (search) => ({
        url: `${PARCELROUTES.getQuery}${search}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetParcelGeoDataQuery,
  useGetParcelCenterPointQuery,
  useGetProjectsParcelGeoDataQuery,
  useGetProjectsParcelsQueryQuery,
  useGetParcelsProjectsGeoDataQuery,
  useGetParcelsParcelGeoDataQuery,
  useGetAllParcelsQuery,
  useGetParcelInfoQuery,
  useGetSingleParcelMatchedDataQuery,
  useGetAllSingleParcelMatchedDataQuery,
  useGetParcelInfoAllDataQuery,
  useGetParcelsCountQuery,
  useGetAllParcelStatusQuery,
  useDeleteParcelBookmarkByParcelIdMutation,
  useCreateParcelBookmarkMutation,
  useGetAllParcelBookmarksQuery,
  useGetAllParcelBookmarksCoordinatesQuery,
  useDeleteParcelBookmarkMutation,
  useGetMatchingDataQuery,
  useGetCompanyMatchingDataQuery,
  useGetParcelMatchingQueriesQuery,
  useGetParcelWithCoordsQuery,
  useGetParcelsQueryQuery,
} = parcelApi;
