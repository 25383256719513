import { DownloadOutlined } from "@ant-design/icons";
import { Spin, Form, Input, Row, Col, Button, Drawer, Switch } from "antd";
import { useGetParcelExtraInfoByIdQuery, useUpdateParcelExtraInfoMutation } from "app/services";
import { useEffect, useState } from "react";
import { CSVLink } from 'react-csv'


export const ParcelQualificationSheet = ({ parcel_id, setIsModalVisible }: any) => {
  const [form] = Form.useForm();
  const [updateParcelExtraInfo] = useUpdateParcelExtraInfoMutation();
  const [parcelInfoDetails, setParcelInfoDetails] = useState<any>(null)
  const [showEditForm, setShowEditForm] = useState<any>(false)
  const [isLoading, setIsLoading] = useState<any>(false)
  const [csvData, setCsvData] = useState<any>([]);
  const {
    data: selectedParcel,
    isFetching: isFetchingParcel
  } = useGetParcelExtraInfoByIdQuery(parcel_id,
    {
      refetchOnReconnect: true,
      skip: !parcel_id
    },
  );
  /**
   * Handles the 'Cancel' action in the modal by hiding it.
   */
  const handleCancel = () => {
    if (showEditForm) {
      setShowEditForm(false)
    } else {
      setIsModalVisible(false);
    }
  };

  useEffect(() => {
    setParcelInfoDetails(selectedParcel)
    if (selectedParcel) {
      const csvDataObj: any = {}
      Object.keys(selectedParcel).forEach((elem) => {
        csvDataObj[elem] = selectedParcel[elem]
      })
      setCsvData([csvDataObj])
    }
  }, [selectedParcel])

  useEffect(() => {
    form.setFieldsValue({
      ...selectedParcel
    });
    // eslint-disable-next-line
  }, [selectedParcel]);

  const onEditClick = async () => {
    if (showEditForm) {
      console.log('for', form.getFieldsValue())
      const updateData = {
        ...form.getFieldsValue(),
        parcelId: parcel_id
      }
      setIsLoading(true)
      await updateParcelExtraInfo(updateData).unwrap()
      setIsLoading(false)
      setShowEditForm(false)
    } else {
      setShowEditForm(true)
    }
  }

  return (
    <Drawer
      title={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row' }}>
        <span>Qualification Sheet - {parcel_id}</span>
        <div>
          {csvData?.length &&
            <CSVLink filename={`QS-${parcel_id}`} title="Download" data={csvData}><DownloadOutlined /></CSVLink>
          }
          <Button style={{ marginLeft: 10 }} size="middle" onClick={onEditClick}>{showEditForm ? 'Save' : 'Edit'}</Button>
          {/* <DownloadOutlined onClick={onDownloadClick} /> */}
        </div>
      </div>}
      placement="right"
      size="large"
      width={'40%'}
      onClose={handleCancel}
      destroyOnClose
      style={{ zIndex: 2000 }}
      open
    >
      {isFetchingParcel || isLoading ? <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 25 }}>
        <Spin
          spinning={isFetchingParcel || isLoading}
          tip='Loading' size="large"
        ></Spin>
      </div> : !showEditForm ?
        <Row>
          <Col span={24} style={{ marginBottom: 10 }}>
            <div className="meta-detail">
              <span className="meta-detail-name">Development status: </span>
              <span className="meta-detail-value">
                {parcelInfoDetails?.development_status}
              </span>
            </div>
          </Col>
          <Col span={24} style={{ marginBottom: 10 }}>
            <div className="meta-detail">
              <span className="meta-detail-name">Electrical lines: </span>
              <span className="meta-detail-value">
                {parcelInfoDetails?.electrical_lines}
              </span>
            </div>
          </Col>
          <Col span={24} style={{ marginBottom: 10 }}>
            <div className="meta-detail">
              <span className="meta-detail-name">Railroads: </span>
              <span className="meta-detail-value">
                {parcelInfoDetails?.railroads}
              </span>
            </div>
          </Col>
          <Col span={24} style={{ marginBottom: 10 }}>
            <div className="meta-detail">
              <span className="meta-detail-name">Temple distance miles: </span>
              <span className="meta-detail-value">
                {parcelInfoDetails?.temple_distance_miles}
              </span>
            </div>
          </Col>
          <Col span={24} style={{ marginBottom: 10 }}>
            <div className="meta-detail">
              <span className="meta-detail-name">Amount of builders matched: </span>
              <span className="meta-detail-value">
                {parcelInfoDetails?.amount_builders_match}
              </span>
            </div>
          </Col>
          <Col span={24} style={{ marginBottom: 10 }}>
            <div className="meta-detail">
              <span className="meta-detail-name">Amount of developers matched: </span>
              <span className="meta-detail-value">
                {parcelInfoDetails?.amount_developers_match}
              </span>
            </div>

          </Col>
          <Col span={24} style={{ marginBottom: 10 }}>
            <div className="meta-detail">
              <span className="meta-detail-name">Sides touching developed: </span>
              <span className="meta-detail-value">
                {parcelInfoDetails?.sides_touching_developed}
              </span>
            </div>
          </Col>
          <Col span={24} style={{ marginBottom: 10 }}>
            <div className="meta-detail">
              <span className="meta-detail-name">Basements next door: </span>
              <span className="meta-detail-value">
                {parcelInfoDetails?.basements_next_door?.toString()}
              </span>
            </div>
          </Col>
          <Col span={24} style={{ marginBottom: 10 }}>
            <div className="meta-detail">
              <span className="meta-detail-name">Bordering properties same owner: </span>
              <span className="meta-detail-value">
                {parcelInfoDetails?.bordering_properties_same_owner?.toString()}
              </span>
            </div>
          </Col>
          <Col span={24} style={{ marginBottom: 10 }}>
            <div className="meta-detail">
              <span className="meta-detail-name">Boulders overlap: </span>
              <span className="meta-detail-value">
                {parcelInfoDetails?.boulders_overlap?.toString()}
              </span>
            </div>
          </Col>
          <Col span={24} style={{ marginBottom: 10 }}>
            <div className="meta-detail">
              <span className="meta-detail-name">Close to water: </span>
              <span className="meta-detail-value">
                {parcelInfoDetails?.close_to_water?.toString()}
              </span>
            </div>
          </Col>
          <Col span={24} style={{ marginBottom: 10 }}>
            <div className="meta-detail">
              <span className="meta-detail-name">Owner is company: </span>
              <span className="meta-detail-value">
                {parcelInfoDetails?.owner_is_company?.toString()}
              </span>
            </div>
          </Col>
          <Col span={24} style={{ marginBottom: 10 }}>
            <div className="meta-detail">
              <span className="meta-detail-name">Owner out state: </span>
              <span className="meta-detail-value">
                {parcelInfoDetails?.owner_out_state?.toString()}
              </span>
            </div>
          </Col>
          <Col span={24} style={{ marginBottom: 10 }}>
            <div className="meta-detail">
              <span className="meta-detail-name">Slopes overlap: </span>
              <span className="meta-detail-value">
                {parcelInfoDetails?.slopes_overlap?.toString()}
              </span>
            </div>
          </Col>

        </Row>
        : showEditForm ?
          <>
            <Form
              name="basic"
              form={form}
              className="form"
              initialValues={{ remember: true }}
              layout="vertical"
            >
              <Form.Item label="Development status" name="development_status">
                <Input />
              </Form.Item>
              <Form.Item label="Electrical lines" name="electrical_lines">
                <Input />
              </Form.Item>
              <Form.Item label="Railroads" name="railroads">
                <Input />
              </Form.Item>
              <Form.Item label="Temple distance miles" name="temple_distance_miles">
                <Input />
              </Form.Item>
              <Form.Item label="Amount of buiders matched" name="amount_builders_match">
                <Input type="number" />
              </Form.Item>
              <Form.Item label="Amount of developers matched" name="amount_developers_match">
                <Input type="number" />
              </Form.Item>
              <Form.Item label="Sides touching developed" name="sides_touching_developed">
                <Input type="number" />
              </Form.Item>
              <Form.Item label="Basements next door" name="basements_next_door" valuePropName="checked">
                <Switch checkedChildren="True" unCheckedChildren="False" />
              </Form.Item>
              <Form.Item label="Bordering properties same owner" name="bordering_properties_same_owner" valuePropName="checked">
                <Switch checkedChildren="True" unCheckedChildren="False" />
              </Form.Item>
              <Form.Item label="Boulders overlap" name="boulders_overlap" valuePropName="checked">
                <Switch checkedChildren="True" unCheckedChildren="False" />
              </Form.Item>
              <Form.Item label="Close to water" name="close_to_water" valuePropName="checked">
                <Switch checkedChildren="True" unCheckedChildren="False" />
              </Form.Item>
              <Form.Item label="Owner is company" name="owner_is_company" valuePropName="checked">
                <Switch checkedChildren="True" unCheckedChildren="False" />
              </Form.Item>
              <Form.Item label="Owner out state" name="owner_out_state" valuePropName="checked">
                <Switch checkedChildren="True" unCheckedChildren="False" />
              </Form.Item>
              <Form.Item label="Slopes overlap" name="slopes_overlap" valuePropName="checked">
                <Switch checkedChildren="True" unCheckedChildren="False" />
              </Form.Item>

            </Form>
          </> : null
      }
    </Drawer>
  )
}