export const BASEURL = process.env.REACT_APP_API_URL;
/**
 * Object containing routes related to authentication operations.
 * Use these routes to perform various authentication actions such as login, registration,
 * password management (forgot, reset, change), etc.
 */ 
export const AUTHROUTES = {
  login: '/auth/login',
  register: '/auth/register',
  forgot: '/auth/forgot-password',
  reset: '/auth/reset-password',
  chnagePassword: '/auth/change-password',
};

export const USERROUTES = {
  base: '/users',
};

/**
 * Object containing routes related to parcel operations.
 * These routes facilitate fetching various data related to parcels,
 * such as coordinates, matching data, counts, and queries.
 */ 
export const PARCELROUTES = {
  base: '/parcels',
  count: '/parcels/count',
  geoData: '/parcels/all-coordinates',
  geoDataQuery: '/parcels/all-coordinates-query',
  matchingGeoData: '/parcels/matching-coordinates',
  coords: '/parcels/coords',
  centerPoint: '/parcels/center',
  info: '/parcels/info',
  allData: '/parcels/allData',
  matchesCount: '/parcels/matches',
  allMatchesCount: '/parcels/matches-all',
  matchingData: '/parcels/all-matching',
  matchingQueries: '/parcels/matching-queries',
  getQuery: '/parcels/getQuery',
  companyMatchedData: '/parcels/all-company-matching',
};

export const COMPANYROUTES = {
  base: 'companies',
  manyCompaniesByNames: 'companies/names',
  companyByName: 'companies/name',
  singleCompanyMatchedProperties: 'companies/matched-properties/',
};
/**
 * Object containing routes related to bookmarks.
 * These routes facilitate accessing bookmarked parcels and projects.
 */ 
export const BOOKMARKROUTES = {
  base: 'bookmarks',
  parcels: 'parcels-bookmarks',
  parcelsBookmarks: 'parcels-bookmarks/all-coordinates',
  projects: 'projects-bookmarks',
  projectsBookmarks: 'projects-bookmarks/all-coordinates',
};
/**
 * Object containing routes related to project operations.
 * These routes facilitate fetching various data related to projects,
 * such as info, counts, coordinates, matching data, and queries.
 */ 
export const PROJECTROUTES = {
  base: '/projects',
  info: '/projects/info',
  count: '/projects/count',
  allData: '/projects/allData',
  centerPoint: '/projects/center',
  matchesCount: '/projects/matches',
  names: '/projects/names',
  allMatchesCount: '/projects/matches-all',
  allMatchingData: '/projects/all-contacts-matching',
  geoData: '/projects/all-coordinates',
  childGeoData: '/projects/child-coordinates',
  parcelsMatchingData: '/projects/parcels-matching-data',
  companyMatchedData: '/projects/all-company-matching',
  matchingQueries: '/projects/matching-queries',
  childNames: '/child-projects/names',
  parentGeoData: '/projects/parent-coordinates',
  parentCenter: '/projects/parent-center',
  getQuery: '/projects/getQuery'
};
/**
 * Object containing routes related to parent project operations.
 * These routes facilitate fetching various data related to parent projects,
 * such as coordinates, matching data, counts, queries, and general information.
 */ 
export const PARENTPROJECTROUTES = {
  base: '/parent-projects',
  count: '/parent-projects/count',
  parentGeoData: '/parent-projects/parent-coordinates',
  geoData: '/parent-projects/all-coordinates',
  matchingGeoData: '/parent-projects/matching-coordinates',
  centerPoint: '/parent-projects/center',
  info: '/parent-projects/info',
  allData: '/parent-projects/allData',
  allMatchesCount: '/parent-projects/matches-all',
  allMatchingData: '/parent-projects/all-contacts-matching',
  companyMatchedData: '/parent-projects/all-company-matching',
  matchingQueries: '/parent-projects/matching-queries',
  names: '/parent-projects/names',
  getQuery: '/parent-projects/getQuery'
};

export const CITIESROUTES = {
  base: '/cities',
};

export const COUNTIESROUTES = {
  base: '/counties',
};

export const SURVEYSROUTES = {
  base: '/surveys',
  getUrlById: '/surveys/url',
  surveyCriteria: '/survey-criteria',
};

export const CONTACTSROUTES = {
  base: '/contacts',
};

export const USERSROUTES = {
  base: '/users',
};

export const ZONINGROUTES = {
  base: '/zoning',
};

export const WATERRIGHTSROUTES = {
  base: '/water-rights',
};

export const ROLESROUTES = {
  base: '/roles',
};

export const CHILDPROJECTROUTES = {
  base: '/child-projects',
};

export const PARCELSEXTRAINFOROUTES = {
  base: '/parcels-extra-info',
};
