/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Pagination, Spin, Tabs, TabsProps } from 'antd';
import {
  TabbedContent,
  MatchingResultsTotalText,
} from 'components';
import { useEffect, useMemo, useState } from 'react';
import {
  setItem,
  useGetParentProjectsCountQuery,
  useGetProjectsCountQuery,
  setCoordsValue,
  setZoomValue,
  selectActiveTabValue,
  setActiveTabValue,
  useGetParcelsCountQuery,
  setShowProjectsTypeValue,
  setProjectIdValue,
  setShowParcelsTypeValue,
  setParcelIdValue,
  selectProjectsInnerActiveTabValue,
  setProjectsInnerActiveTabValue,
  setCoordsPaginationValue,
  setIsCountLoadingValue,
  selectIsCountLoadingValue,
  setShowingTotalValue,
  selectShowingTotalValue,
  setSelectedItemDetailsOnMap,
  setInfoWindowOpenValue,
  setResultsPaginationValue,
  setSelectedProjectTypeValue,
  setDrawerWidthValue
} from 'app/services';
import { useLocation } from 'react-router-dom';
import { TAB } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { ActiveTabType } from 'types/common';
import { defaultMapProps } from 'utils/mapHelper';
import { ChildProjectResultBox } from './ChildProjectResultBox';
import { ParentProjectResultBox } from './ParentProjectResultBox';
import { ParcelResultBox } from './ParcelResultBox';
import TooltipComponent from 'components/Tooltip';
export interface MatchedResultBoxProps {
  handleOnViewAllClick: (item: { id: string; type: string }) => void;
  openViewAll: () => void;
  closeInfoWindowOpen: () => void;
  onOpenSelectedFilters: () => void;
  openBookmarksView?: () => void;
  isSelectedResultDrawerOpen?: boolean;
}
interface TabProps {
  property: string;
  total: any;
}

// const ITEMSPERPAGE = 10;
export const MatchedResultBox = ({
  openViewAll,
  handleOnViewAllClick,
  openBookmarksView,
  onOpenSelectedFilters,
  closeInfoWindowOpen,
  isSelectedResultDrawerOpen
}: MatchedResultBoxProps) => {
  const { search } = useLocation();
  const showingTotal: number | undefined = useSelector(selectShowingTotalValue);

  const dispatch = useDispatch();
  const activeTab: ActiveTabType = useSelector(selectActiveTabValue);
  const innerActiveTab: any = useSelector(selectProjectsInnerActiveTabValue);
  const isCountLoading: any = useSelector(selectIsCountLoadingValue);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(50)
  const [localTotal, setLocalTotal] = useState()
  const [activePage, setActivePage] = useState(1);


  useEffect(() => {
    setCurrentPage(1)
    setActivePage(1)
  }, [limit])

  useEffect(() => {
    dispatch(setResultsPaginationValue({ page: currentPage, limit }))
  }, [currentPage, limit])

  const paginatedQuerySearch = useMemo(
    () => `?page=${currentPage}&take=${limit}&${search.substring(1)}&surveyTaker=${localStorage.getItem('surveyEmail')}`,
    [limit, currentPage, search],
  );
  const paginatedQuery = useMemo(
    () => {
      let searchParam = search.substring(1)
      if (search.substring(1) && search.substring(1).includes('childName=')) {
        let searchStringArr = search.substring(1).split('&')
        let modifiedSearchArr = []
        const indexOfChildNameQuery = searchStringArr.findIndex(x => x.includes('childName='))
        if (indexOfChildNameQuery >= 0) {
          modifiedSearchArr.push(searchStringArr[indexOfChildNameQuery].replace('childName', 'name'))
        }
        searchParam = modifiedSearchArr.join('&')
      }
      return `?page=${activePage}&take=${limit}&${searchParam}`
    },
    [limit, activePage, search],
  );
  const { TabPane } = Tabs;
  const [isLoadingData, setIsLoadingData] = useState('');

  useEffect(() => {
    setLimit(50)
    if (innerActiveTab) {
      localStorage.setItem('selectedProjectType', innerActiveTab)
    } else {
      localStorage.removeItem('selectedProjectType')
    }
  }, [innerActiveTab])

  const { data: parcelsPagination, isFetching: isParcelsCountFetching } = useGetParcelsCountQuery<any>(
    paginatedQuerySearch,
    {
      refetchOnReconnect: true,
      skip: activeTab === 'Projects', // skip when we are looking for Projects-
    },
  );

  const { data: parentProjectsPagination, isFetching: isParentProjectsCountFetching } = useGetParentProjectsCountQuery<any>(
    paginatedQuerySearch,
    {
      refetchOnReconnect: true,
      skip: activeTab === 'Parcels', // skip when we are looking for Parcels-
    },
  );

  const { data: childProjectsPagination, isFetching: isChildProjectsCountFetching } = useGetProjectsCountQuery<any>(
    paginatedQuery,
    {
      refetchOnReconnect: true,
      skip: activeTab === 'Parcels', // skip when we are looking for Parcels-
    },
  );

  const setShowingTotalFn = (val: any) => {
    dispatch(setShowingTotalValue(val))
  }

  useEffect(() => {
    setLimit(50)
    if (
      activeTab === 'Parcels'
    ) {
      dispatch(setProjectsInnerActiveTabValue('parentProject'))
      return;
    }
  },
    [activeTab
    ]);

  const Tab = ({ property, total }: TabProps) => (
    <div className="tab-title">
      <div className="name">
        <span>{property} {total}</span>
      </div>

    </div>
  );

  const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
    <>
      <DefaultTabBar {...props} />
      {/* <p>Custom div</p> */}
      {activeTab === 'Projects' ?
        <div>
          <Tabs activeKey={innerActiveTab} type='card' onTabClick={(e) => {
            dispatch(setZoomValue(defaultMapProps.zoom))

            dispatch(setProjectsInnerActiveTabValue(e))
            dispatch(setCoordsPaginationValue({ page: 1, limit: 400 }))

            // dispatch(setSelectedProjectTypeValue(e))
            dispatch(setShowProjectsTypeValue(null))
            dispatch(setProjectIdValue(''))

            dispatch(setShowParcelsTypeValue(null))
            dispatch(setParcelIdValue(''))

            dispatch(setSelectedItemDetailsOnMap(null))
          }
          } >
            <TabPane
              key="parentProject"
              tab={
                <Tab
                  property="Parents"
                  total={isLoadingData === 'parentProjects' ? `(Loading..)` : parentProjectsPagination?.totalItems ? `(${parentProjectsPagination?.totalItems})` : ``}
                />
              }
            >
            </TabPane>
            <TabPane
              key="childProject"
              tab={
                <Tab
                  property="Children"
                  total={isLoadingData === 'childProjects' ? `(Loading..)` : childProjectsPagination?.totalItems ? `(${childProjectsPagination?.totalItems})` : ``}
                />
              }
            >
            </TabPane>
          </Tabs>
        </div> : null
      }
    </>
  );

  useEffect(() => {
    if (activeTab === 'Parcels') {
      // const localTotalParcels = localStorage.getItem('totalParcels')
      if (isParcelsCountFetching) {
        dispatch(setIsCountLoadingValue(true))
      } else {
        dispatch(setIsCountLoadingValue(false))
      }
    } else if (activeTab === 'Projects') {
      // const localTotalProjects = localStorage.getItem('totalProjects')
      if (isParentProjectsCountFetching || isChildProjectsCountFetching) {
        dispatch(setIsCountLoadingValue(true))
      } else {
        dispatch(setIsCountLoadingValue(false))
      }
    }
  }, [activeTab, isParcelsCountFetching, isParentProjectsCountFetching, isChildProjectsCountFetching])


  useEffect(() => {
    if (activeTab === 'Parcels') {
      // const localTotalParcels = localStorage.getItem('totalParcels')
      if (!isParcelsCountFetching) {
        setLocalTotal(parcelsPagination?.totalItems || 0)
        setShowingTotalFn(parcelsPagination?.totalItems || 0)
        // if (!search) {
        //   localStorage.setItem('totalParcels', parcelsPagination?.totalItems)
        // }
      }
      // else if (!search && localTotalParcels) {
      //   setShowingTotalFn(localTotalParcels)
      // }
    } else if (activeTab === 'Projects') {
      // const localTotalProjects = localStorage.getItem('totalProjects')
      if (!isParentProjectsCountFetching && !isChildProjectsCountFetching) {
        if (innerActiveTab === 'parentProject') {
          setLocalTotal(parentProjectsPagination?.totalItems)
        } else {
          setLocalTotal(childProjectsPagination?.totalItems)
        }
        setShowingTotalFn(parentProjectsPagination?.totalItems + childProjectsPagination?.totalItems)
        // if (!search) {
        //   localStorage.setItem('totalProjects', parentProjectsPagination?.totalItems + childProjectsPagination?.totalItems)
        // }
      }
      // else if (!search && localTotalProjects) {
      //   setShowingTotalFn(localTotalProjects)
      // }
    }
  }, [activeTab, parcelsPagination, parentProjectsPagination, childProjectsPagination, innerActiveTab])

  return (
    <div className="matched-result-box">
      <TabbedContent
        renderTabBar={renderTabBar}
        centered={false}
        maxedLayout={false}
        activeKey={activeTab}
        tabPosition="top"
        onTabClick={(activeKey) => {
          dispatch(setActiveTabValue(activeKey as ActiveTabType))
          dispatch(setCoordsValue(defaultMapProps.center));
          dispatch(setZoomValue(defaultMapProps.zoom))
          dispatch(setItem({}));
          dispatch(setInfoWindowOpenValue(false))
          dispatch(setCoordsPaginationValue({ page: 1, limit: 400 }))
          dispatch(setSelectedItemDetailsOnMap(null))
          dispatch(setSelectedProjectTypeValue(null))
          dispatch(setShowParcelsTypeValue(null))
        }}
        tabBarExtraContent={
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <MatchingResultsTotalText
              projectsTotal={parentProjectsPagination?.totalItems}
              parcelsTotal={parcelsPagination?.totalItems}
              isCountLoading={isCountLoading}
              total={showingTotal}
              tabTitle={activeTab} />
            <img src="bookmark_filled.svg" alt='bookmark-filled' style={{ marginLeft: 6, cursor: 'pointer', height: 15, width: 15 }}
              onClick={() => {
                if (openBookmarksView) {
                  dispatch(setDrawerWidthValue(1050))
                  openBookmarksView()
                  if (document.getElementsByClassName('drawer-menu-1') && document.getElementsByClassName('drawer-menu-1').length) {
                    let element: HTMLElement = document.getElementsByClassName('drawer-menu-1')[0] as HTMLElement;
                    element.click();
                  }
                }
              }
              } />
            <div style={{ marginLeft: 100 }}>
              <TooltipComponent
                placement='top'
                title='Grid View'
                body={<Button
                  onClick={openViewAll}
                  type="primary"
                >
                  Grid View
                </Button>}
              />
            </div>
          </div>
        }
        tabData={TAB.map((title) => ({
          title,
          content: (
            <>
              <Spin size='large'
                spinning={!!isLoadingData}
              >
                {/* <div className='tab-border'> */}

                {/* {activeTab === 'Projects' ?
                  <div>
                    <Tabs type='card' onTabClick={(e) =>
                      setSelectedProjectType(e)
                    } >
                      <TabPane
                        key="parentProject"
                        tab={
                          <Tab
                            property="Parents"
                            total={isFetchingProjects ? `(Loading..)` : projectsPagination?.totalItems ? `(${projectsPagination?.totalItems})` : `(0)`}
                          />
                        }
                      >
                      </TabPane>
                      <TabPane
                        key="childProject"
                        tab={
                          <Tab
                            property="Childs"
                            total={isFetchingChildProjects ? `(Loading..)` : childProjectsPagination?.totalItems ? `(${childProjectsPagination?.totalItems})` : `(0)`}

                          />
                        }
                      >
                      </TabPane>
                    </Tabs>
                  </div> : null
                } */}
                {/* </div> */}
                {activeTab === 'Parcels' &&
                  <ParcelResultBox
                    onOpenSelectedFilters={onOpenSelectedFilters}
                    handleOnViewAllClick={handleOnViewAllClick}
                    closeInfoWindowOpen={closeInfoWindowOpen}
                    parcelsPagination={parcelsPagination}
                    isSelectedResultDrawerOpen={isSelectedResultDrawerOpen}
                    paginatedQuerySearch={paginatedQuerySearch}
                    setParcelActivePage={setCurrentPage}
                    setIsLoadingData={setIsLoadingData}
                  />}
                {activeTab === 'Projects' && innerActiveTab === 'parentProject' &&
                  <ParentProjectResultBox
                    onOpenSelectedFilters={onOpenSelectedFilters}
                    handleOnViewAllClick={handleOnViewAllClick}
                    closeInfoWindowOpen={closeInfoWindowOpen}
                    parentProjectsPagination={parentProjectsPagination}
                    isSelectedResultDrawerOpen={isSelectedResultDrawerOpen}
                    paginatedQuerySearch={paginatedQuerySearch}
                    setParentActivePage={setCurrentPage}
                    setIsLoadingData={setIsLoadingData}
                  />}
                {activeTab === 'Projects' && innerActiveTab === 'childProject' &&
                  <ChildProjectResultBox
                    onOpenSelectedFilters={onOpenSelectedFilters}
                    handleOnViewAllClick={handleOnViewAllClick}
                    closeInfoWindowOpen={closeInfoWindowOpen}
                    childProjectsPagination={childProjectsPagination}
                    isSelectedResultDrawerOpen={isSelectedResultDrawerOpen}
                    paginatedQuery={paginatedQuery}
                    setChildActivePage={setActivePage}
                    setIsLoadingData={setIsLoadingData}
                  />
                }
              </Spin>
              {/* } */}
            </>
          ),
        }))}
      />
      {(!isLoadingData || currentPage !== 1 || (activeTab === 'Parcels' && parcelsPagination) || (innerActiveTab === 'parentProject' && parentProjectsPagination)) && innerActiveTab !== 'childProject' ?
        <div className="footer">
          <div className="results-pagination">
            <Pagination
              size="small"
              current={currentPage}
              total={localTotal}
              pageSize={limit}
              pageSizeOptions={[50, 100, 200, 400]}
              onChange={(page) => setCurrentPage(page)}
              onShowSizeChange={(current, pageSize) => setLimit(pageSize)
              }
            />
          </div>


        </div> : null
      }

      {(!isLoadingData || activePage !== 1 || childProjectsPagination) && innerActiveTab === 'childProject' ?
        <div className="footer">
          <div className="results-pagination">
            <Pagination
              size="small"
              current={activePage}
              total={localTotal}
              pageSize={limit}
              pageSizeOptions={[50, 100, 200, 400]}
              onChange={(page) => setActivePage(page)}
              onShowSizeChange={(current, pageSize) => setLimit(pageSize)
              }
            />
          </div>


        </div> : null
      }
    </div>
  );
};